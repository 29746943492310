import { css } from "@emotion/css";

const styles = {
  muiDrawer: css`
    z-index: 3 !important;
  `,

  muiDrawerContainer: (isMobile) =>
    isMobile
      ? css`
          top: 50px !important;
          height: calc(100vh - 50px) !important;
          margin-top: 50px;
        `
      : css`
          top: 0;
          height: 100vh !important;
        `,
};

export default styles;
