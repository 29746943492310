import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import ScrollLayout from "library/Layouts/ScrollLayout";
import PropTypes from "prop-types";
import styles from "./styles";

const FastQuoteFormLayout = ({
  descriptionSection,
  detailsSection,
  pickUpFormSection,
  dropFormsSection,
  addDropControlSection,
  controlSection,
  warningSection,
}) => {
  const { isSmallMobile, isMobile } = useDeviceType();

  return (
    <>
      {!isSmallMobile && <p className="mb-4">{descriptionSection}</p>}

      <div className="d-flex flex-row">
        <ScrollLayout className={cx(styles.form(isSmallMobile), "h-100 col-md-7 col-12 pl-md-0 pr-md-3 px-0")}>
          {isSmallMobile && <p className="mb-4">{descriptionSection}</p>}

          {isMobile && detailsSection}

          <div className="mb-4">{pickUpFormSection}</div>

          {dropFormsSection}

          {addDropControlSection}

          {warningSection && <p className="mt-2 mb-5">{warningSection}</p>}
        </ScrollLayout>

        {!isMobile && detailsSection}
      </div>

      <div className="d-flex flex-column align-items-center mt-4 px-0 w-100">{controlSection}</div>
    </>
  );
};

FastQuoteFormLayout.defaultProps = {
  warningSection: null,
};

FastQuoteFormLayout.propTypes = {
  descriptionSection: PropTypes.node.isRequired,
  detailsSection: PropTypes.node.isRequired,
  pickUpFormSection: PropTypes.node.isRequired,
  dropFormsSection: PropTypes.node.isRequired,
  addDropControlSection: PropTypes.node.isRequired,
  warningSection: PropTypes.node,
  controlSection: PropTypes.node.isRequired,
};

export default FastQuoteFormLayout;
