import { createSlice } from "@reduxjs/toolkit";
import { fetchLocalizationsRequest } from "../requests/localization.request";

export const localizationSlice = createSlice({
  name: "localization",
  initialState: {
    localizations: [],
    status: "idle",
  },
  extraReducers: {
    [fetchLocalizationsRequest.pending]: (state) => {
      state.status = "loading";
    },
    [fetchLocalizationsRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.localizations = action.payload;
    },
    [fetchLocalizationsRequest.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export const retrieveLocalizations = (state) => state.localization.localizations;

export const retrieveContactLocalization = (state) => {
  const { city } = state.contact.profile || {};
  return state.localization.localizations.find(({ city_name }) => city === city_name) || {};
};

export const retrieveLocalizationStatus = (state) => {
  const { status } = state.localization;
  return { idle: status === "idle", loading: status === "loading", failed: status === "failed" };
};

export default localizationSlice.reducer;
