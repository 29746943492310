import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";

const ChooseAddressFormLayout = ({
  autocompleteSection,
  buildingOrFlatNumberSection,
  secondLineAddressSection,
  postcodeSection,
  mapSection,
  nameSection,
  emailSection,
  phoneSection,
  notesSection,
  minimal,
  ...restProps
}) => (
  <div {...restProps}>
    <div className="d-flex flex-row flex-wrap">
      <div className={cx(minimal ? "col-12" : "col-12 col-md-7 col-lg-7 pr-md-2", "px-0")}>{autocompleteSection}</div>

      {!minimal && (
        <>
          <div className="col-12 col-md-5 col-lg-5 px-0 pl-md-2 mt-md-0 mt-3">{buildingOrFlatNumberSection}</div>

          <div className="col-12 col-md-7 col-lg-7 px-0 pr-md-2">{secondLineAddressSection}</div>
          <div className="col-12 col-md-5 col-lg-5 px-0 pl-md-2">{postcodeSection}</div>

          <div className="col-12 px-0">{mapSection}</div>

          <div className="col-12 col-lg-4 mt-4 px-0 pr-lg-2">{nameSection}</div>
          <div className="col-12 col-lg-3 mt-4 px-0 px-lg-2">{emailSection}</div>
          <div className="col-12 col-lg-5 mt-4 px-0 pl-lg-2 pr-md-0">{phoneSection}</div>

          <div className="col-12 mt-4 px-0">{notesSection}</div>
        </>
      )}
    </div>
  </div>
);

ChooseAddressFormLayout.defaultProps = {
  mapSection: null,
};

ChooseAddressFormLayout.propTypes = {
  autocompleteSection: PropTypes.node.isRequired,
  buildingOrFlatNumberSection: PropTypes.node.isRequired,
  secondLineAddressSection: PropTypes.node.isRequired,
  postcodeSection: PropTypes.node.isRequired,
  mapSection: PropTypes.node,
  nameSection: PropTypes.node.isRequired,
  phoneSection: PropTypes.node.isRequired,
  emailSection: PropTypes.node.isRequired,
  notesSection: PropTypes.node.isRequired,
  minimal: PropTypes.bool.isRequired,
};

export default ChooseAddressFormLayout;
