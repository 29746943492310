import { FormikProvider, useFormik } from "formik";
import Select from "library/Atoms/Select";
import FilterLayout from "library/Layouts/InputLayout/FilterLayout";
import { values } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveLibraryListType, setAddressListType, setOrderListType } from "store/features/library.store";
import commonStyles from "utility/ui/common";
import { LIBRARY_ITEM_TYPES } from "../../../../../constants";

const LibraryFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listType = useSelector(retrieveLibraryListType);

  const filtersForm = useFormik({
    initialValues: { type: listType },
    onSubmit: ({ type }) => {
      type === LIBRARY_ITEM_TYPES.addresses ? dispatch(setAddressListType()) : dispatch(setOrderListType());
    },
  });
  const {
    values: { type },
    setFieldValue,
    submitForm,
  } = filtersForm;

  const typeFilterOptions = useMemo(() => {
    return [...values(LIBRARY_ITEM_TYPES).map((value) => ({ label: t(`utils.libraryItemTypes.${value}`), value }))];
  }, [t]);

  const handleChangeType = useCallback(
    (name, value) => type !== value && setFieldValue(name, value).then(() => submitForm()),
    [setFieldValue, submitForm, type]
  );

  return (
    <FormikProvider value={filtersForm}>
      <FilterLayout label={`${t("common.forms.filterLabel")}:`}>
        <Select
          id="library-type-filter"
          name="type"
          selectClassName={commonStyles.filterSelect}
          value={type}
          options={typeFilterOptions}
          onChange={handleChangeType}
          variant="standard"
        />
      </FilterLayout>
    </FormikProvider>
  );
};

export default LibraryFilters;
