import { injectGlobal } from "@emotion/css";
import palette from "./palette";

injectGlobal`
  html {
    font-size: 10px;
  }

  html,
  body,
  body > div {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    margin: 0;

    background-color: ${palette.white};

    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${palette.secondary.main};
  }

  a,
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }

  .flex-1 {
    flex: 1;
  }

  .gap {
    &-2 {
      gap: 0.25rem;
    }

    &-3 {
      gap: 1rem;
    }

    &-4 {
      gap: 1.5rem;
    }

    &-5 {
      gap: 3rem;
    }
  }

  .text {
    color: ${palette.secondary.main};
    font-weight: 400;

    &-fs-28 {
      font-size: 2.8rem;
      line-height: 2.8rem;
    }

    &-fs-24 {
      font-size: 2.4rem;
      line-height: 2.6rem;
    }

    &-fs-20 {
      font-size: 2rem;
      line-height: 2.6rem;
    }

    &-fs-18 {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }

    &-fs-16 {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &-fs-14 {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    &-fs-12 {
      font-size: 1.2rem;
      line-height: 1.9rem;
    }

    &-fs-10 {
      font-size: 1rem;
      line-height: 1.9rem;
    }

    &-fw-normal {
      font-weight: 400;
    }

    &-fw-semibold {
      font-weight: 600;
    }

    &-fw-bold {
      font-weight: 700;
    }

    &-color {
      &-white {
        color: ${palette.white};
      }

      &-grey300 {
        color: ${palette.grey300};
      }

      &-grey400 {
        color: ${palette.grey400};
      }

      &-grey900 {
        color: ${palette.grey900};
      }

      &-primary {
        color: ${palette.primary.main};
      }

      &-secondary {
        color: ${palette.secondary.main};
      }

      &-warning {
        color: ${palette.warning.main};
      }

      &-error {
        color: ${palette.error.main};
      }

      &-yellow {
        color: ${palette.yellow.main};
      }
    }

    &-underlined {
      text-decoration: underline;
    }

    &-capitalized {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .bg {
    &-color {
      &-grey900 {
        background-color: ${palette.grey900};
      }

      &-grey100 {
        background-color: ${palette.grey100};
      }

      &-primary {
        background-color: ${palette.primary.main};
      }

      &-secondary {
        background-color: ${palette.secondary.main};
      }

      &-warning {
        background-color: ${palette.warning.main};
      }

      &-error {
        background-color: ${palette.error.main};
      }

      &-white {
        background-color: ${palette.white};
      }

      &-info {
        background-color: ${palette.info.main};
      }

      &-yellow {
        background-color: ${palette.yellow.main};
      }
    }
  }

  .border {
    &-rounded {
      border-radius: 12px;
    }
  }

  .cursor {
    &-pointer {
      cursor: pointer;
    }
  }

  .h {
    &-fit {
      height: fit-content;
    }
  }

  .top-0 {
    top: 0 !important;
  }
`;
