import { css } from "@emotion/css";

const modalTypes = { small: "458px", big: "70%" };

const styles = {
  modal: (isMobile, modalSize) => css`
    width: ${isMobile ? "92%" : modalTypes[modalSize]};
    max-width: 1100px;
    height: fit-content;
    padding-top: 20px;

    outline: none;
  `,
  modalContent: css`
    max-height: 70vh;
  `,
  closeIcon: css`
    top: 0;
    right: 0;
  `,
};

export default styles;
