import { cx } from "@emotion/css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useDeviceType from "hooks/useDeviceType";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import Chip from "library/Atoms/Chip";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import { isEmpty } from "lodash";
import LibraryDetailsLayout from "pages/Library/layouts/LibraryDetailsLayout/LibraryDetailsLayout/LibraryDetailsLayout";
import LibraryConfirmDeleteModal from "pages/Library/modals/LibraryConfirmDeleteModal/LibraryConfirmDeleteModal";
import OrderSettingsModal from "pages/Library/modals/LibraryItemSettingsModal/OrderSettingsModal/OrderSettingsModal";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSelectedSavedOrder } from "store/features/library.store";
import { format } from "utility/date-fns";
import { calculatePackagesAmount } from "utility/helpers/orders";
import OrderBriefInfo from "../LibraryList/OrderItem/OrderBriefInfo/OrderBriefInfo";
import OrderSettingsForm from "../OrderSettingsForm/OrderSettingsForm";
import DropDetails from "./DropDetails/DropDetails";
import styles from "./OrderDetails.styles";

const OrderDetails = ({ onClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const selectedOrder = useSelector(retrieveSelectedSavedOrder);
  const { id, label, startDate, endDate, pick_up, drops = [], isExpress, created_at, updated_at } = selectedOrder;

  const [isConfirmDeleteModalOpen, openConfirmDeleteModal, closeConfirmDeleteModal] = useOpenClose(false);
  const [isEditOrderModalOpen, openEditOrderModal, closeEditOrderModal] = useOpenClose(false);

  if (isEmpty(selectedOrder)) return null;
  return (
    <LibraryDetailsLayout
      labelSection={label}
      infoSection={
        <>
          <OrderBriefInfo startDate={startDate} endDate={endDate} packages={calculatePackagesAmount(drops)} />

          {isExpress && <Chip label={t("common.entities.order.express")} icon={<AccessTimeIcon />} />}
        </>
      }
      timestampsSection={
        <>
          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.createdAt")}
            contentSection={format(new Date(created_at?._seconds * 1000), "MMM dd, yyyy HH:mm")}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.updatedAt")}
            contentSection={format(new Date(updated_at?._seconds * 1000), "MMM dd, yyyy HH:mm")}
          />
        </>
      }
      listSection={drops.map(({ reference: dropRef, ...drop }) => (
        <DropDetails key={dropRef} pick_up={pick_up} drop={{ ...drop, reference: dropRef }} />
      ))}
      deleteControlSection={
        <SecondaryButton
          id={`delete-order-${id}-btn`}
          className={cx(styles.controlBtn(isMobile), "w-100 mr-auto")}
          contentClassName="text-truncate text-fs-12"
          label={t("library.order.deleteOrderBtn")}
          onClick={openConfirmDeleteModal}
        />
      }
      editControlSection={
        <SecondaryButton
          id={`edit-order-${id}-btn`}
          className={cx(styles.controlBtn(isMobile), "w-100")}
          contentClassName="text-truncate text-fs-12"
          label={t("library.order.editOrderBtn")}
          onClick={openEditOrderModal}
        />
      }
      backControlSection={
        <PrimaryButton
          id="back-to-orders-btn"
          className={cx(styles.controlBtn(isMobile), "w-100")}
          contentClassName="text-truncate text-fs-12"
          label={t("common.buttons.backToOrdersBtn")}
          onClick={onClose}
        />
      }
      modals={
        <>
          {isConfirmDeleteModalOpen && <LibraryConfirmDeleteModal onClose={closeConfirmDeleteModal} />}

          {isEditOrderModalOpen && (
            <OrderSettingsModal onClose={closeEditOrderModal}>
              <OrderSettingsForm onClose={closeEditOrderModal} />
            </OrderSettingsModal>
          )}
        </>
      }
    />
  );
};

OrderDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default OrderDetails;
