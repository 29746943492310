import { cx } from "@emotion/css";
import DownloadInvoiceIcon from "@mui/icons-material/FileDownloadOutlined";
import { unwrapResult } from "@reduxjs/toolkit";
import useDeviceType from "hooks/useDeviceType";
import IconButton from "library/Atoms/Button/IconButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import { PropTypes } from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveDownloadLinkStatus } from "store/features/invoice.store";
import { fetchInvoiceDownloadLinkRequest } from "store/requests/invoice.requests";

const InvoiceDownloadButton = ({ className }) => {
  const dispatch = useDispatch();
  const { isMobile } = useDeviceType();
  const { t } = useTranslation();

  const [{ selectedInvoice }] = useInvoices();
  const { loading: isDownloadLinkLoading } = useSelector(retrieveDownloadLinkStatus);

  const onDownloadInvoice = useCallback(() => {
    dispatch(fetchInvoiceDownloadLinkRequest({ invoiceId: selectedInvoice?.docId }))
      .then(unwrapResult)
      .then((res) => {
        if (!res.data) return;
        window.open(res.data, "_blank");
      });
  }, [dispatch, selectedInvoice?.docId]);

  if (isMobile) {
    return (
      <IconButton onClick={onDownloadInvoice} loading={isDownloadLinkLoading} isRounded>
        <DownloadInvoiceIcon />
      </IconButton>
    );
  }
  return (
    <SecondaryButton
      className={cx(className, "align-self-end w-100 mt-4")}
      contentClassName="text-fs-12"
      label={t("invoices.invoice.downloadInvoiceBtn")}
      onClick={onDownloadInvoice}
      loading={isDownloadLinkLoading}
    />
  );
};

InvoiceDownloadButton.defaultProps = {
  className: "",
};

InvoiceDownloadButton.propTypes = {
  className: PropTypes.string,
};

export default InvoiceDownloadButton;
