import { css } from "@emotion/css";

const styles = {
  title: css`
    height: 35px;
  `,
  inputLabel: css`
    height: 20px;
  `,
  input: css`
    height: 40px;
  `,
};

export default styles;
