import { cx } from "@emotion/css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FormHelperText, IconButton } from "@mui/material";
import { useField } from "formik";
import useOpenClose from "hooks/useOpenClose";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { first, flattenDeep, isEmpty, keys, values } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { formatBytes } from "utility/helpers/general";
import styles from "./styles";

const validator = (file, { accept, maxSize }) => {
  if (file.size > maxSize) return { code: "file-too-large", message: "utils.ui.fileInputSizeError" };
  if (!keys(accept).includes(file.type)) return { code: "file-invalid-type", message: "utils.ui.fileInputFormatError" };
  return null;
};

const FileItem = ({ file, onRemove }) => (
  <div className="d-flex justify-content-between p-3 bg-color-grey100 border-rounded cursor-pointer">
    <div className="d-flex align-items-center gap-3 overflow-hidden">
      <FileIcon className={cx(styles.fileIcon)} color="primary" />

      <div className="d-flex flex-column text-fs-10 overflow-hidden">
        <span className="text-fs-14 text-truncate">{file.name}</span>

        <span className="text-color-grey300">{formatBytes(file.size)}</span>
      </div>
    </div>

    <IconButton onClick={onRemove}>
      <CloseRoundedIcon className="cursor-pointer" color="primary" />
    </IconButton>
  </div>
);

FileItem.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
};

const FilePicker = ({ className, name, label, placeholder, onUpload, accept, maxSize }) => {
  const { t } = useTranslation();

  const [{ value: file = "" } = {}] = useField({ name });

  const [error, setError] = useState(null);

  const [isInputActive, setInputActive, setInputInactive] = useOpenClose(false);

  const onDropFile = useCallback(
    (files) => {
      setInputInactive();
      if (!isEmpty(files)) onUpload(name, first(files));
    },
    [setInputInactive, onUpload, name]
  );
  const onRemoveFile = useCallback(() => onUpload(name, null), [name, onUpload]);

  const handleFileError = useCallback((errors) => {
    const currError = first(errors).errors;
    if (!currError) setError(null);
    else setError(first(first(errors).errors).message);
  }, []);

  const { isFileDialogActive, getRootProps, getInputProps } = useDropzone({
    onDragEnter: setInputActive,
    onDrop: onDropFile,
    onDropRejected: handleFileError,
    onDropAccepted: handleFileError,
    validator: (currFile) => validator(currFile, { accept, maxSize }),
    multiple: false,
    autoFocus: true,
  });

  return (
    <InputLayout className={className} label={label}>
      <div className="d-flex justify-content-between mb-3 text-fs-10 text-fw-normal text-color-grey400">
        <span>
          {t("utils.ui.fileInputFormats", {
            formats: flattenDeep(values(accept))
              .map((type) => type.toUpperCase())
              .join(", "),
          })}
        </span>
        <span>{t("utils.ui.fileInputMaxSize", { size: formatBytes(maxSize) })}</span>
      </div>

      <div
        {...getRootProps({
          className: cx(
            styles.dropzone,
            error && styles.error,
            (isInputActive || isFileDialogActive) && styles.activeDropzone,
            "d-flex flex-column align-items-center gap-2 justify-content-center mb-4 p-5 border-rounded text-fw-normal cursor-pointer"
          ),
        })}
      >
        <input {...getInputProps()} />

        <UploadFileIcon color="info" />
        <span className="text-center text-color-grey400">{placeholder}</span>
      </div>

      {error && (
        <FormHelperText error>
          <span className="text-fs-10">{t(error)}</span>
        </FormHelperText>
      )}

      {!isEmpty(file) && <FileItem file={file} onRemove={onRemoveFile} />}
    </InputLayout>
  );
};

FilePicker.defaultProps = {
  className: null,
  accept: { "text/csv": [".csv"] },
  maxSize: 1048576,
};

FilePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  accept: PropTypes.any,
  maxSize: PropTypes.number,
};

export default FilePicker;
