import { css, cx } from "@emotion/css";
import Alert from "library/Atoms/Alert";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

const toastStyles = css`
  min-height: fit-content !important;
`;

const toastSettings = {
  className: cx("p-0", toastStyles),
  bodyClassName: "m-0 p-0",
  style: { boxShadow: "none" },
  closeButton: <></>,
  hideProgressBar: true,
  pauseOnHover: true,
};

const useToast = () => {
  if (typeof window !== "undefined") injectStyle();

  const onShowToast = useCallback((message, { id = null, type = "error", onClose = () => {}, timeout = 5000 } = {}) => {
    toast(<Alert type={type} message={message} />, { toastId: id, onClose, autoClose: timeout, ...toastSettings });
  }, []);

  return onShowToast;
};

export default useToast;
