import { css } from "@emotion/css";

const styles = {
  menuPart: css`
    height: calc(100vh - 50px);

    transition: all 500ms ease-in-out;
  `,

  drawerOpened: css`
    width: 250px;
  `,
  drawerClosed: (isMobile) => css`
    width: ${isMobile ? 0 : "63px"};
  `,

  settings: css`
    box-shadow: 0px -1px 4px #00000029;
  `,
};

export default styles;
