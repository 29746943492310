import RiderMarker from "assets/svg/markers/rider-marker.svg";
import useDeviceType from "hooks/useDeviceType";
import Skeleton from "library/Atoms/Skeleton";
import LoadGoogleMap from "library/GoogleMapComponents/LoadGoogleMap";
import Map from "library/GoogleMapComponents/Map";
import MapMarker from "library/GoogleMapComponents/MapMarker";
import { keys, size, uniqBy } from "lodash";
import useOrders from "pages/Dashboard/hooks/useOrders";
import { getOrderStatusMarkerIcon } from "pages/Dashboard/utils/helper";
import { useCallback, useMemo } from "react";
import { createMarkerPosition } from "utility/helpers/markers";

const OrdersMap = ({ ...restProps }) => {
  const { isDesktop, isTablet } = useDeviceType();

  const [
    {
      orders,
      selectedOrder,
      pagination: { active_page },
      filters: orderFilters,
    },
    { onSelectOrder },
    { isOrderSelected },
  ] = useOrders();

  const selectedOrderPickUpMarker = useMemo(() => {
    const { reference, pick_up: { geolocation, location } = {} } = selectedOrder;
    const position = createMarkerPosition(geolocation);
    return position ? [{ id: reference, position: createMarkerPosition(geolocation), location }] : [];
  }, [selectedOrder]);

  const allPickUpMarkers = useMemo(() => {
    return uniqBy(
      orders.reduce((prev, { pick_up, reference }) => {
        const position = createMarkerPosition(pick_up?.geolocation);
        if (!position) return prev;
        return [...prev, { id: reference, position: createMarkerPosition(pick_up?.geolocation) }];
      }, []),
      ({ position }) => `${position.lat},${position.lng}`
    );
  }, [orders]);

  const selectedOrderDropsMarkers = useMemo(() => {
    return selectedOrder.drops?.reduce((prev, { reference, geolocation, location, status }) => {
      const position = createMarkerPosition(geolocation);
      if (!position) return prev;
      return [...prev, { id: reference, position, status: status || selectedOrder.status, location }];
    }, []);
  }, [selectedOrder]);

  const allDropsMarkers = useMemo(() => {
    return uniqBy(
      orders.reduce((prev, { status, drops, reference }) => {
        return [
          ...prev,
          ...keys(drops).reduce((prevDrops, dropKey) => {
            const position = createMarkerPosition(drops[dropKey]?.geolocation);
            if (!position) return prevDrops;
            return [...prevDrops, { id: reference, position, status: drops[dropKey].status || status }];
          }, []),
        ];
      }, []),
      ({ position }) => `${position.lat},${position.lng}`
    );
  }, [orders]);

  const pickUpMarkers = useMemo(() => {
    return isOrderSelected ? selectedOrderPickUpMarker : allPickUpMarkers;
  }, [isOrderSelected, selectedOrderPickUpMarker, allPickUpMarkers]);

  const statusMarkers = useMemo(() => {
    return isOrderSelected ? selectedOrderDropsMarkers : allDropsMarkers;
  }, [isOrderSelected, selectedOrderDropsMarkers, allDropsMarkers]);

  const riderMarker = useMemo(() => {
    if (!isOrderSelected) return null;
    return selectedOrder.rider?.geolocation ? createMarkerPosition(selectedOrder.rider.geolocation) : null;
  }, [isOrderSelected, selectedOrder]);

  const mapBounds = useMemo(() => {
    let bounds = [...pickUpMarkers, ...statusMarkers].map(({ position }) => position);
    if (riderMarker) bounds = [...bounds, riderMarker];
    return bounds;
  }, [pickUpMarkers, statusMarkers, riderMarker]);

  const onMarkerClick = useCallback((id) => !size(selectedOrder) && onSelectOrder(id), [selectedOrder, onSelectOrder]);

  return (
    <LoadGoogleMap loadingComponent={<Skeleton className="h-100" rounded={false} />}>
      <Map
        className="h-100"
        bounds={mapBounds}
        mapBoundsDependencies={[orderFilters, active_page, isOrderSelected]}
        clusteredMap={!isOrderSelected}
        showZoomControls={isOrderSelected && [isDesktop, isTablet].some(Boolean)}
        {...restProps}
      >
        {(clusterer) => (
          <>
            {riderMarker && <MapMarker key={riderMarker} position={riderMarker} icon={RiderMarker} variant="custom" />}

            {pickUpMarkers.map(({ id, position, location }) => (
              <MapMarker
                key={id}
                id={id}
                position={position}
                tooltip={location}
                clusterer={clusterer}
                onClick={onMarkerClick}
                variant="pick-up"
              />
            ))}

            {statusMarkers.map(({ id, position, location, status }, index) => (
              <MapMarker
                key={`${id}-${index}`}
                id={id}
                position={position}
                tooltip={location}
                clusterer={clusterer}
                onClick={onMarkerClick}
                icon={getOrderStatusMarkerIcon(status)}
                variant="custom"
              />
            ))}
          </>
        )}
      </Map>
    </LoadGoogleMap>
  );
};

export default OrdersMap;
