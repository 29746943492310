import useDeviceType from "hooks/useDeviceType";
import ItemDetailsLayout from "library/Layouts/DetailsLayout/ItemDetailsLayout";
import { PropTypes } from "prop-types";

const LibraryDetailsLayout = ({
  labelSection,
  infoSection,
  timestampsSection,
  listSection,
  deleteControlSection,
  editControlSection,
  backControlSection,
  modals,
}) => {
  const { isDesktop } = useDeviceType();

  return (
    <>
      <ItemDetailsLayout
        statusBarSection={
          <div className="d-flex flex-1 justify-content-md-center text-fs-14 text-fw-semibold overflow-hidden">
            <span className="mx-md-5 mx-0 text-truncate">{labelSection}</span>
          </div>
        }
        briefInfoSection={infoSection}
        extraInfoSection={timestampsSection}
        listSection={listSection}
        controlsSection={
          <div className="d-flex flex-1 gap-4 mt-4">
            {isDesktop && deleteControlSection}

            {isDesktop && editControlSection}

            {backControlSection}
          </div>
        }
      />

      {modals}
    </>
  );
};

LibraryDetailsLayout.defaultProps = {
  infoSection: null,
  modals: null,
};

LibraryDetailsLayout.propTypes = {
  labelSection: PropTypes.node.isRequired,
  infoSection: PropTypes.node,
  timestampsSection: PropTypes.node.isRequired,
  listSection: PropTypes.node.isRequired,
  deleteControlSection: PropTypes.node.isRequired,
  editControlSection: PropTypes.node.isRequired,
  backControlSection: PropTypes.node.isRequired,
  modals: PropTypes.node,
};

export default LibraryDetailsLayout;
