import { keys, values } from "lodash";
import "utility/validation";
import * as yup from "yup";
import { INVOICE_PERIODS } from "../../../../constants";

export default yup.object().shape({
  firstName: yup.string().required("common.forms.firstNameRequiredError").min(4, "common.forms.firstNameLengthError"),
  lastName: yup.string().required("common.forms.lastNameRequiredError"),
  phone: yup.string().required("common.forms.phoneRequiredError").phone("common.forms.phoneInvalidError"),
  email: yup.string(),
  invoiceEmail: yup.string(),
  company: yup.string().required("common.forms.companyNameRequiredError"),
  city: yup.string().required("common.forms.cityRequiredError"),
  companyAddress: yup.string().required("common.forms.companyAddressRequiredError"),
  companyVat: yup.string().nullable(),
  companyBillingAddress: yup.string(),
  invoice: yup.object().shape({
    period: yup.string().oneOf(keys(INVOICE_PERIODS)).required("profile.forms.invoiceForm.invoicePeriodRequiredError"),
    frequency: yup.number().oneOf(values(INVOICE_PERIODS)),
  }),
});
