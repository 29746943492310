import { css } from "@emotion/css";

const styles = {
  input: css`
    min-width: 100px;

    font-weight: 600 !important;

    cursor: pointer;

    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  `,
  closeIcon: css`
    font-size: 16px !important;
  `,
};

export default styles;
