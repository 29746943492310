import "utility/validation";
import * as yup from "yup";
import { REPEAT_ORDER_TYPES } from "../../../../constants";

export default yup.object().shape({
  startDate: yup.date().nullable().actual("common.forms.pickUpTimeError"),
  repeat_settings: yup.object().shape({
    type: yup.string(),
    dates: yup.array().when("type", {
      is: REPEAT_ORDER_TYPES.EXACT_DATES,
      then: yup.array().min(1),
    }),
  }),
  pick_up: yup.object().shape({
    location: yup.string().required("common.forms.locationRequiredError"),
    recipient: yup.object().shape({
      email: yup.string().email("common.forms.emailFormatError"),
      phone: yup.string().phone("common.forms.phoneInvalidError"),
    }),
  }),
  drops: yup.array().of(
    yup.object().shape({
      location: yup.string().required("common.forms.locationRequiredError"),
      recipient: yup.object().shape({
        email: yup.string().email("common.forms.emailFormatError"),
        phone: yup.string().phone("common.forms.phoneInvalidError"),
      }),
    })
  ),
});
