import { cx } from "@emotion/css";
import { AddRounded as AddRoundedIcon, RemoveRounded as RemoveRoundedIcon } from "@mui/icons-material";
import { GoogleMap, MarkerClusterer } from "@react-google-maps/api";
import useMapSettings from "hooks/useMapSettings";
import IconButton from "library/Atoms/Button/IconButton";
import { PropTypes } from "prop-types";
import { useCallback, useState } from "react";
import { PositionType } from "types/helperTypes/MapMarker";
import { CUSTOM_MAP_STYLES } from "../../../constants";
import styles from "./styles";

const ClusterIcon = (color) =>
  window.btoa(`
  <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".6" r="70" />
  <circle cx="120" cy="120" opacity=".4" r="95" />
  <circle cx="120" cy="120" opacity=".2" r="120" />
</svg>
`);

const options = {
  styles: [`#1eafd1`, `#66c29d`, `#ff544c`, `#ffdd00`, `#929292`].map((color) => ({
    url: `data:image/svg+xml;base64,${ClusterIcon(color)}`,
    height: 50,
    width: 50,
    textColor: "white",
    textSize: 14,
  })),
};

const Map = ({
  id,
  className,
  bounds,
  children,
  mapBoundsDependencies,
  clusteredMap,
  showZoomControls,
  ...restProps
}) => {
  const [mapRef, setMapRef] = useState(null);

  useMapSettings(mapRef, bounds, mapBoundsDependencies);

  const onZoomIn = useCallback(() => {
    if (!mapRef) return;
    mapRef.setZoom(mapRef.getZoom() + 1);
  }, [mapRef]);

  const onZoomOut = useCallback(() => {
    if (!mapRef) return;
    mapRef.setZoom(mapRef.getZoom() - 1);
  }, [mapRef]);

  return (
    <div className={cx(className, "w-100")} {...restProps}>
      <GoogleMap
        id={id}
        mapContainerClassName="h-100 w-100"
        options={{
          mapId: "42d9409505c86ef8",
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: CUSTOM_MAP_STYLES,
        }}
        zoom={11}
        onLoad={setMapRef}
      >
        {clusteredMap ? (
          <MarkerClusterer options={options} minimumClusterSize={3}>
            {(clusterer) => <>{children(clusterer)}</>}
          </MarkerClusterer>
        ) : (
          <>{children(null)}</>
        )}

        {showZoomControls && (
          <div className={cx(styles.zoomControls, "position-absolute d-flex flex-column")}>
            <IconButton onClick={onZoomIn} isRounded>
              <AddRoundedIcon />
            </IconButton>

            <IconButton onClick={onZoomOut} isRounded>
              <RemoveRoundedIcon />
            </IconButton>
          </div>
        )}
      </GoogleMap>
    </div>
  );
};

Map.defaultProps = {
  id: null,
  className: "",
  mapBoundsDependencies: [],
  clusteredMap: false,
  showZoomControls: false,
};

Map.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  bounds: PropTypes.arrayOf(PositionType).isRequired,
  children: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props, react/forbid-prop-types
  mapBoundsDependencies: PropTypes.arrayOf(PropTypes.any),
  clusteredMap: PropTypes.bool,
  showZoomControls: PropTypes.bool,
};

export default Map;
