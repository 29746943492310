import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  baseIcon: css`
    font-size: 11px !important;
  `,
  icon: css`
    font-size: 16px !important;
  `,
  disabledIcon: css`
    color: ${palette.grey200};
  `,
};

export default styles;
