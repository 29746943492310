import { css } from "@emotion/css";

const styles = {
  menuTopPart: css`
    min-height: 50px;
    left: 0;
    top: 0;

    transition: all 500ms ease-in-out;
    box-shadow: 0px 1px 4px #00000029;

    z-index: 4;
  `,

  drawerOpened: css`
    width: 250px;
  `,
  drawerClosed: (isMobile) => css`
    width: ${isMobile ? 0 : "63px"};
  `,
};

export default styles;
