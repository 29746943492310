import { cx } from "@emotion/css";
import Skeleton from "library/Atoms/Skeleton";
import PropTypes from "prop-types";
import styles from "./styles";

const NewOrderDetailsSkeleton = ({ className }) => <Skeleton className={cx(styles.details, className, "w-100")} />;

NewOrderDetailsSkeleton.defaultProps = {
  className: null,
};

NewOrderDetailsSkeleton.propTypes = {
  className: PropTypes.string,
};

export default NewOrderDetailsSkeleton;
