import { cx } from "@emotion/css";
import { Checkbox as MuiCheckbox } from "@mui/material";
import { useField } from "formik";
import { PropTypes } from "prop-types";
import { useCallback } from "react";

const Checkbox = ({ className, name, value, label, onChange, ...restProps }) => {
  const [{ value: switchValue, ...field } = {}] = useField({ name });

  const handleChange = useCallback((_, checked) => onChange(name, checked), [name, onChange]);

  return (
    <div className={cx(className, "d-flex flex-nowrap align-items-center")}>
      <MuiCheckbox {...field} {...restProps} checked={value ?? switchValue} size="small" onChange={handleChange} />

      <span className="pl-4 text-fs-12 text-color-secondary">{label}</span>
    </div>
  );
};

Checkbox.defaultProps = {
  className: "",
  label: null,
  value: undefined,
};

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
