import useDebounce from "hooks/useDebounce";
import { useCallback, useEffect, useMemo } from "react";
import useOpenClose from "./useOpenClose";

const useMapSettings = (mapRef, bounds = [], mapBoundsDependencies = []) => {
  const [shouldRebound, startRebound, endRebound] = useOpenClose(false);

  const extendedBounds = useMemo(() => {
    // TODO: add check for one marker

    if (!bounds.length) return null;

    const googleMapBounds = new window.google.maps.LatLngBounds();

    bounds.forEach((position) => position && googleMapBounds.extend(position));

    const northEast = googleMapBounds.getNorthEast();
    const southWest = googleMapBounds.getSouthWest();

    if (northEast && southWest && northEast.equals(southWest)) {
      const extendPoint1 = new window.google.maps.LatLng(northEast.lat() + 0.001, northEast.lng() + 0.001);
      const extendPoint2 = new window.google.maps.LatLng(northEast.lat() - 0.001, northEast.lng() - 0.001);
      googleMapBounds.extend(extendPoint1);
      googleMapBounds.extend(extendPoint2);
    }

    return googleMapBounds;
  }, [bounds]);

  const handleFitBounds = useCallback(() => {
    if (!mapRef) return;
    if (!extendedBounds) return;
    mapRef.fitBounds(extendedBounds);
    endRebound();
  }, [mapRef, extendedBounds, endRebound]);
  const debouncedFitBounds = useDebounce(handleFitBounds, 1000);

  useEffect(() => {
    if (!window.google) return;
    debouncedFitBounds("mapRef");
  }, [mapRef]);

  useEffect(() => {
    startRebound();
  }, mapBoundsDependencies);

  useEffect(() => {
    if (!window.google) return;
    if (!shouldRebound) return;
    debouncedFitBounds("mapRef");
  }, [shouldRebound, debouncedFitBounds]);
};

export default useMapSettings;
