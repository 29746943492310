import useOpenClose from "hooks/useOpenClose";
import DefaultModal from "library/Molecules/Modal/DefaultModal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSelectedSavedOrder, retrieveSelectedSavedOrderStatus } from "store/features/library.store";
import LibraryConfirmResetModal from "../../LibraryConfirmResetModal/LibraryConfirmResetModal";

const OrderSettingsModal = ({ onClose, children }) => {
  const { t } = useTranslation();

  const { id } = useSelector(retrieveSelectedSavedOrder);
  const { editing } = useSelector(retrieveSelectedSavedOrderStatus);

  const [isResetModalOpen, openResetModal, closeResetModal] = useOpenClose(false);

  return (
    <DefaultModal
      id={`edit-order-${id}-modal`}
      title={t("library.modals.orderSettingsModal.title")}
      modalClassName="d-flex flex-column"
      onClose={editing ? openResetModal : onClose}
      size="big"
    >
      {children}

      {isResetModalOpen && <LibraryConfirmResetModal onConfirm={onClose} onClose={closeResetModal} />}
    </DefaultModal>
  );
};

OrderSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default OrderSettingsModal;
