import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import ModalLayout from "library/Layouts/ModalLayout";
import PropTypes from "prop-types";
import DefaultModal from "../DefaultModal";

const AlertModal = ({ id, title, description, onClose, onConfirm, closeBtnText, ...restProps }) => (
  <DefaultModal id={id} title={title} onClose={onClose} {...restProps}>
    <ModalLayout
      contentSection={<>{description}</>}
      modalControls={<PrimaryButton id="alert-close-btn" className="w-100" label={closeBtnText} onClick={onConfirm} />}
    />
  </DefaultModal>
);

AlertModal.defaultProps = {
  id: null,
  title: null,
  description: null,
  closeBtnText: null,
  onClose: () => {},
};

AlertModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  closeBtnText: PropTypes.string,
};

export default AlertModal;
