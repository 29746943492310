import { cx } from "@emotion/css";
import { Button } from "@mui/material";
import Spinner from "library/Atoms/Spinner";
import { PropTypes } from "prop-types";

const SecondaryButton = ({ contentClassName, label, loading, ...restProps }) => (
  <Button variant="outlined" {...restProps}>
    <div className={cx(contentClassName, "d-flex align-items-center")}>
      {loading ? <Spinner /> : <span className="text-capitalized text-truncate">{label}</span>}
    </div>
  </Button>
);

SecondaryButton.defaultProps = {
  contentClassName: "",
  label: "",
  loading: false,
};

SecondaryButton.propTypes = {
  contentClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
};

export default SecondaryButton;
