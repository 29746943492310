import { css } from "@emotion/css";
import palette from "utility/ui/palette";
import { transitions } from "utility/ui/theme";

const styles = {
  drawer: (isTablet) => css`
    ${isTablet
      ? `
        right: 0;
        height: calc(100vh - 50px) !important;
      `
      : `
        top: 0;
        height: 100vh;

        z-index: 4 !important;
      `}
  `,
  drawerOpen: css`
    transition: ${transitions.create(["width", "transform"], {
      duration: 900,
    })} !important;
    opacity: 1;
  `,
  drawerClose: css`
    width: 0 !important;
    opacity: 0;

    transition: ${transitions.create(["width", "transform"], {
      easing: transitions.easing.easeInOut,
      duration: 900,
    })} !important;
  `,
  drawerContent: css`
    background-color: ${palette.grey100} !important;

    box-shadow: 0px 0px 4px #00000029 !important;
  `,

  closeIconBtn: css`
    left: 15px;
    top: 15px;
  `,
  controlsSection: css`
    right: 15px;
    top: 15px;
  `,
};

const stylesInner = {
  drawer: (isMobile) =>
    css`
      height: calc(100vh - ${isMobile ? "140px" : "30vh"});
    `,
  drawerOpen: css`
    transition: ${transitions.create(["margin-bottom", "transform"], {
      duration: transitions.duration.standard,
    })};
  `,
  drawerClose: css`
    margin-bottom: calc((100vh + 50px) * -1);

    transition: ${transitions.create(["margin-bottom", "transform"], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    })};
  `,
  drawerContent: css`
    background-color: ${palette.white} !important;

    overflow: visible !important;
  `,
};

export { styles, stylesInner };
