import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";
import styles from "./styles";

const BoxLayout = ({ className, componentClassName, children, selected, Component, ...restProps }) => (
  <div
    className={cx(className, styles.layout, selected && styles.selected, "bg-color-white border-rounded")}
    {...restProps}
  >
    {Component ? (
      <Component className={cx(componentClassName, "col px-0 border-rounded", styles.rounded)}>{children}</Component>
    ) : (
      children
    )}
  </div>
);

BoxLayout.defaultProps = {
  className: "",
  componentClassName: "",
  selected: false,
  Component: null,
};

BoxLayout.propTypes = {
  className: PropTypes.string,
  componentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  Component: PropTypes.func,
};

export default BoxLayout;
