import { cx } from "@emotion/css";
import AddDropIcon from "@mui/icons-material/AddCircleOutline";
import RemoveDropIcon from "@mui/icons-material/HighlightOff";
import ChooseAddressForm from "features/ChooseAddressForm";
import { useFormikContext } from "formik";
import Accordion from "library/Atoms/Accordion";
import TextButton from "library/Atoms/Button/TextButton";
import { keys, pick, size } from "lodash";
import TaskFormLayout from "pages/NewOrder/layouts/FormLayout/TaskFormLayout/TaskFormLayout";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generateNewDrop } from "utility/helpers/orders";
import { DEFAULT_LOCATION_DATA, MAX_DROP_AMOUNT } from "../../../../../constants";
import styles from "./DropOffForm.styles";
import PackageForm from "./PackageForm/PackageForm";

const DropOffForm = ({ reference }) => {
  const { t } = useTranslation();

  const {
    values: { pick_up, drops },
    setFieldValue,
  } = useFormikContext();

  const dropIndex = useMemo(() => {
    return drops.findIndex(({ reference: dropReference }) => reference === dropReference);
  }, [drops, reference]);
  const drop = useMemo(() => drops[dropIndex], [dropIndex, drops]);
  const isLastDrop = useMemo(() => dropIndex === size(drops) - 1, [dropIndex, drops]);

  const isDropAddingEnabled = useMemo(() => {
    return pick_up.location && drops.every(({ location }) => location) && size(drops) < MAX_DROP_AMOUNT;
  }, [pick_up, drops]);

  const onAddNewDrop = useCallback(() => setFieldValue("drops", [...drops, generateNewDrop()]), [setFieldValue, drops]);
  const onRemoveDrop = useCallback(() => {
    setFieldValue(
      "drops",
      drops.filter((_, index) => index !== dropIndex)
    );
  }, [drops, dropIndex, setFieldValue]);

  const onChangeDropAddress = useCallback(
    ({ notes, ...address }) => {
      setFieldValue(`drops.[${dropIndex}]`, { ...drop, ...address });
      setFieldValue(`drops[${dropIndex}].notes`, notes);
    },
    [setFieldValue, drop, dropIndex]
  );

  return (
    <TaskFormLayout
      titleSection={
        <>
          {t("newOrder.forms.dropOffForm.formTitle")} {size(drops) > 1 && dropIndex + 1}
        </>
      }
      removeControlSection={
        size(drops) > 1 && (
          <TextButton
            id={`remove-${dropIndex}-drop-button`}
            label={t("common.buttons.removeDropBtn")}
            onClick={onRemoveDrop}
            startIcon={<RemoveDropIcon fontSize="small" />}
          />
        )
      }
      addressFormSection={
        <ChooseAddressForm
          className="col-12 px-0 mb-2"
          label={t("newOrder.forms.dropOffForm.formTitle").toLocaleLowerCase()}
          name={`drops[${dropIndex}]`}
          address={pick(drop, keys(DEFAULT_LOCATION_DATA))}
          onAddressChanged={onChangeDropAddress}
          required
        />
      }
      additionalFormSection={
        drops[dropIndex]?.location && (
          <Accordion
            id={`package-${dropIndex}-form-accordion`}
            className={cx(styles.accordion, "col-12 px-0")}
            innerClassName="py-0 px-5"
            label={t("newOrder.forms.dropOffForm.showAllPackagesBtn", { count: size(drops[dropIndex]?.packages) })}
          >
            {drop.packages.map(({ reference: pacReference }) => (
              <PackageForm key={pacReference} reference={reference} pacReference={pacReference} />
            ))}
          </Accordion>
        )
      }
      addControlSection={
        isLastDrop && (
          <TextButton
            id="add-drop-button"
            className="pl-1"
            label={t("common.buttons.addDropBtn")}
            onClick={onAddNewDrop}
            startIcon={<AddDropIcon fontSize="small" />}
            disabled={!isDropAddingEnabled}
          />
        )
      }
      warningSection={
        size(drops) >= MAX_DROP_AMOUNT && (
          <Trans
            i18nKey="common.entities.order.maximumDropMessage"
            tOptions={{ email: "delivery@pedivan.co.uk", maxDrop: MAX_DROP_AMOUNT }}
            components={{ lnk: <a href="mailto:delivery@pedivan.co.uk" className="text-color-primary" /> }}
          />
        )
      }
      data-cy={`drops[${dropIndex}]-form`}
    />
  );
};

DropOffForm.propTypes = {
  reference: PropTypes.string.isRequired,
};

export default DropOffForm;
