import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  iconWrapper: css`
    width: 20px;
    height: 20px;

    border-radius: 50%;
  `,
  icon: css`
    color: ${palette.white} !important;
  `,
};

export default styles;
