import { css } from "@emotion/css";

const styles = {
  form: (isMobile) =>
    isMobile
      ? css`
          min-height: 70vh;
          max-height: 70vh;
        `
      : css`
          min-height: 55vh;
          max-height: 55vh;
        `,
};

export default styles;
