import { Chip as MuiChip } from "@mui/material";
import { PropTypes } from "prop-types";

const Chip = ({ label, color, icon, variant, ...restProps }) => (
  <MuiChip
    label={label}
    sx={
      variant === "filled"
        ? { backgroundColor: `${color}.main` }
        : { borderColor: `${color}.main`, color: `${color}.main` }
    }
    icon={icon}
    variant={variant}
    {...restProps}
  />
);

Chip.defaultProps = {
  label: null,
  color: "warning",
  icon: null,
  variant: "outlined",
};

Chip.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "error", "info", "yellow", "warning"]),
  icon: PropTypes.node,
  variant: PropTypes.oneOf(["filled", "outlined"]),
};

export default Chip;
