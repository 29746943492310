import { NewOrderContextProvider } from "contexts/NewOrderContext";

const withNewOrderForm = (Component) => (props) => {
  return (
    <NewOrderContextProvider>
      <Component {...props} />
    </NewOrderContextProvider>
  );
};

export default withNewOrderForm;
