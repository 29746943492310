import BriefItemDetailsLayout from "library/Layouts/DetailsLayout/BriefItemDetailsLayout";
import { PropTypes } from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { InvoicePriceType } from "types/models/Invoice";
import { format } from "utility/date-fns";
import { convertTimestampToDate } from "utility/helpers/general";

const InvoiceBriefInfo = ({ id, createdAt, dueAt, lines, price }) => {
  const { t } = useTranslation();

  return (
    <BriefItemDetailsLayout
      titleSection={t("invoices.invoice.id", { id })}
      descriptionSection={
        <>
          <span className="text-truncate text-center">
            {format(convertTimestampToDate(createdAt), "MMM dd, yyyy")}

            <span className="px-1">-</span>

            {format(convertTimestampToDate(dueAt), "MMM dd, yyyy")}
          </span>

          <span className="px-1">|</span>

          <span className="text-truncate text-center">{t("invoices.invoice.linesCount", { count: lines })}</span>

          <span className="px-1">|</span>

          <span className="text-truncate text-center">
            <Trans
              i18nKey="utils.pricing.includingVAT"
              components={{
                price: (
                  <NumberFormat value={price.total} displayType="text" thousandSeparator prefix={price.currency} />
                ),
              }}
            />
          </span>
        </>
      }
    />
  );
};

InvoiceBriefInfo.defaultProps = {
  lines: 0,
};

InvoiceBriefInfo.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  createdAt: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dueAt: PropTypes.any.isRequired,
  lines: PropTypes.number,
  price: InvoicePriceType.isRequired,
};

export default InvoiceBriefInfo;
