import useAuthState from "hooks/useAuthState";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { retrieveContact } from "store/features/contact.store";

const UnauthenticatedRoute = () => {
  const { isAuthenticated } = useAuthState();
  const contact = useSelector(retrieveContact);

  if (isAuthenticated && !isEmpty(contact)) return <Navigate to="/" />;
  return <Outlet />;
};

export default UnauthenticatedRoute;
