import { css } from "@emotion/css";

const styles = {
  flag: css`
    max-width: 16px;
    max-height: 11px;
    min-height: 11px;
  `,
};

export default styles;
