import { useEffect, useRef } from "react";

const usePrevious = (props) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = props;
  });

  return ref.current;
};

const useDidUpdate = (callback, depends) => {
  const mounted = useRef();

  const prevDepends = usePrevious(depends);

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else {
      callback(prevDepends, depends);
    }
  }, [callback, depends]);
};

export default useDidUpdate;
