import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  badge: css`
    top: 22%;
    left: 0;
    width: 20px;
    height: 20px;
    padding: 2px;

    border-radius: 50%;

    font-size: 12px !important;
    font-weight: 600;
    color: ${palette.white} !important;
  `,
};

export default styles;
