import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  fab: css`
    bottom: 25px;
    right: 15px;
  `,
  icon: css`
    color: ${palette.white};
  `,
};

export default styles;
