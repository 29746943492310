import { useTranslation } from "react-i18next";

const AuthFormFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <a href="https://www.pedivan.com/privacy-policy" className="text-center text-color-primary">
        {t("common.links.privacyPolicyLink")}
      </a>

      <a href="https://www.pedivan.com/terms-and-conditions" className="text-center text-color-primary">
        {t("common.links.termsAndConditionsLink")}
      </a>

      <a href="https://www.pedivan.com/contact" className="text-center text-color-primary">
        {t("common.links.contactUsLink")}
      </a>
    </>
  );
};

export default AuthFormFooter;
