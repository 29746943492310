import { useFormikContext } from "formik";
import Select from "library/Atoms/Select";
import FilterLayout from "library/Layouts/InputLayout/FilterLayout";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { prettifyStatusLabel } from "utility/helpers/general";
import commonStyles from "utility/ui/common";
import { DELETED_STATUS, INACTIVE_STATUS, INVOICE_STATUSES_LIST, ORDER_STATUSES_LIST } from "../../../../constants";

const StatusFilter = ({ isOrder }) => {
  const { t } = useTranslation();

  const contact = useSelector(retrieveContact);

  const {
    values: { status = "all" },
    setFieldValue,
  } = useFormikContext();

  const statusFilterOptions = useMemo(() => {
    const options = [{ label: t("features.filtersForm.filterPlaceholder"), value: "all" }];

    if (isOrder) {
      return [
        ...options,
        ...ORDER_STATUSES_LIST.map((value) => {
          const label = t([`utils.deliveryStatuses.${value}`, prettifyStatusLabel(value)]);
          return { label, value };
        }),
      ];
    }

    const isAdmin = contact.roles?.includes("pedivanAdmin");

    return [
      ...options,
      ...INVOICE_STATUSES_LIST.filter((s) => (isAdmin ? true : ![INACTIVE_STATUS, DELETED_STATUS].includes(s))).map(
        (value) => {
          const label = t([`utils.invoiceStatuses.${value}`, prettifyStatusLabel(value)]);
          return { label, value };
        }
      ),
    ];
  }, [t, isOrder, contact]);

  const handleChangeStatus = useCallback(
    (name, value) => {
      if (status === value) return;
      setFieldValue(name, value);
    },
    [status, setFieldValue]
  );

  return (
    <FilterLayout label={`${t("common.forms.filterLabel")}:`}>
      <Select
        id="list-status-filter"
        name="status"
        selectClassName={commonStyles.filterSelect}
        value={status}
        options={statusFilterOptions}
        onChange={handleChangeStatus}
        variant="standard"
      />
    </FilterLayout>
  );
};

StatusFilter.propTypes = {
  isOrder: PropTypes.bool.isRequired,
};

export default StatusFilter;
