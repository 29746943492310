import Map from "library/GoogleMapComponents/Map";
import MapMarker from "library/GoogleMapComponents/MapMarker";
import { size } from "lodash";
import PropTypes from "prop-types";
import { MapMarkerType, PositionType } from "types/helperTypes/MapMarker";

const NewOrderDirectionMap = ({ bounds, pickUpMarker, dropMarkers, ...restProps }) => (
  <Map bounds={bounds} {...restProps}>
    {() => (
      <>
        {pickUpMarker && (
          <MapMarker position={pickUpMarker.position} tooltip={pickUpMarker.location} variant="pick-up" />
        )}

        {dropMarkers.map(({ position, location }, index) => (
          <MapMarker
            key={`${location}-${index}`}
            position={position}
            label={size(dropMarkers) > 1 ? `${index + 1}` : ""}
            tooltip={location}
            variant="drop"
          />
        ))}
      </>
    )}
  </Map>
);

NewOrderDirectionMap.propTypes = {
  bounds: PropTypes.arrayOf(PositionType).isRequired,
  pickUpMarker: MapMarkerType.isRequired,
  dropMarkers: PropTypes.arrayOf(MapMarkerType).isRequired,
};

export default NewOrderDirectionMap;
