import { cx } from "@emotion/css";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Stepper from "library/Molecules/Stepper";
import { PropTypes } from "prop-types";
import StepType from "types/helperTypes/Stepper";
import StatusIcon from "../StatusIcon";
import styles from "./styles";

const StatusStepper = ({ activeStep, steps, renderColor, renderIcon }) => (
  <Stepper
    activeStep={activeStep}
    steps={steps}
    StepIcon={({ status, active, completed }) => {
      if (active) {
        if (!status) return <CircleIcon className={styles.baseIcon} color="primary" />;
        return (
          <StatusIcon
            className={styles.icon}
            status={status}
            renderColor={renderColor}
            renderIcon={renderIcon}
            variant="component-rounded"
          />
        );
      }
      if (completed) return <CircleOutlinedIcon className={styles.baseIcon} color="primary" />;
      return <CircleIcon className={cx(styles.disabledIcon, styles.baseIcon)} />;
    }}
  />
);

StatusStepper.defaultProps = {
  activeStep: 0,
  steps: [],
};

StatusStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(StepType),
  renderColor: PropTypes.func.isRequired,
  renderIcon: PropTypes.func.isRequired,
};

export default StatusStepper;
