import { css } from "@emotion/css";

const styles = {
  label: css`
    :first-letter {
      text-transform: uppercase;
    }
  `,
  helperText: css`
    top: 100%;
    right: 0;
    margin-bottom: -20px !important;
  `,
};

export default styles;
