import { arrayOf, number, shape, string } from "prop-types";

const ProofOfDeliveryType = shape({
  photoUrl: string,
  signatureUrl: string,
  geolocation: arrayOf(number),
  delivery_time: string,
  additional_note: string,
});

export default ProofOfDeliveryType;
