import { toPairs } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_LOCATION_DATA, LARGE_PACKAGE_TYPE, MEDIUM_PACKAGE_TYPE, SMALL_PACKAGE_TYPE } from "../../constants";
import i18n from "../i18next";

export const generateNewPackage = () => ({
  reference: uuidv4(),
  parcelCustomerId: "",
  type: SMALL_PACKAGE_TYPE,
  quantity: 1,
});

export const generateNewDrop = () => ({
  reference: uuidv4(),
  ...DEFAULT_LOCATION_DATA,
  packages: [generateNewPackage()],
});

export const prettifyDropReference = (fullRef) => fullRef.split("-")[0].toUpperCase();

export const calculatePackagesAmount = (drops = []) => {
  return drops.reduce((prevTotal, { packages }) => {
    return prevTotal + packages.reduce((prevAmount, { quantity = 1 }) => prevAmount + quantity, 0);
  }, 0);
};

export const calculatePackagesAmountByType = (packages = []) => {
  return packages.reduce(
    (prev, { type, quantity }) => {
      if (type === SMALL_PACKAGE_TYPE) return { ...prev, [SMALL_PACKAGE_TYPE]: prev[SMALL_PACKAGE_TYPE] + quantity };
      if (type === MEDIUM_PACKAGE_TYPE) return { ...prev, [MEDIUM_PACKAGE_TYPE]: prev[MEDIUM_PACKAGE_TYPE] + quantity };
      if (type === LARGE_PACKAGE_TYPE) return { ...prev, [LARGE_PACKAGE_TYPE]: prev[LARGE_PACKAGE_TYPE] + quantity };
      return { ...prev, [type]: quantity };
    },
    { [SMALL_PACKAGE_TYPE]: 0, [MEDIUM_PACKAGE_TYPE]: 0, [LARGE_PACKAGE_TYPE]: 0 }
  );
};

export const prettifyPackagesAmount = (packages) => {
  return toPairs(calculatePackagesAmountByType(packages))
    .reduce((prev, [type, quantity]) => {
      return quantity === 0
        ? prev
        : [...prev, `${quantity} ${i18n.t([`utils.packageSizes.${type}`, type]).toLocaleLowerCase()}`];
    }, [])
    .join(", ");
};
