import { isEmpty } from "lodash";
import AddressControls from "pages/Library/components/AddressDetails/AddressControls/AddressControls";
import AddressDetails from "pages/Library/components/AddressDetails/AddressDetails";
import AddressMap from "pages/Library/components/AddressDetails/AddressMap/AddressMap";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedAddress, retrieveSelectedAddress } from "store/features/library.store";
import LibraryItemDetailsDrawer from "../LibraryDetailsDrawer/LibraryItemDetailsDrawer";

const AddressDetailsDrawer = () => {
  const dispatch = useDispatch();

  const selectedAddress = useSelector(retrieveSelectedAddress);

  const isOpen = useMemo(() => !isEmpty(selectedAddress), [selectedAddress]);

  const handleDeselectAddress = useCallback(() => dispatch(resetSelectedAddress()), [dispatch]);

  return (
    <LibraryItemDetailsDrawer
      id={`address-details-drawer-${selectedAddress?.id || ""}`}
      mapSection={<AddressMap />}
      controlsSection={<AddressControls />}
      onClose={handleDeselectAddress}
      open={isOpen}
    >
      <AddressDetails onClose={handleDeselectAddress} />
    </LibraryItemDetailsDrawer>
  );
};

export default AddressDetailsDrawer;
