import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSelectedOrder,
  retrieveGroppedOrders,
  retrieveOrderFilters,
  retrieveOrders,
  retrieveOrdersPagination,
  retrieveOrdersStatus,
  retrieveSelectedOrder,
  setSelectedOrder,
} from "store/features/order.store";
import { fetchFilteredOrdersRequest, fetchOrdersRequest } from "store/requests/order.requests";

const useOrders = () => {
  const dispatch = useDispatch();

  const orders = useSelector(retrieveOrders);
  const ordersByMonth = useSelector(retrieveGroppedOrders);

  const selectedOrder = useSelector(retrieveSelectedOrder);

  const orderFilters = useSelector(retrieveOrderFilters);
  const pagination = useSelector(retrieveOrdersPagination);

  const { idle, loading: ordersLoading, refetching: ordersRefetching } = useSelector(retrieveOrdersStatus);

  const isListLoading = useMemo(() => ordersLoading || ordersRefetching, [ordersLoading, ordersRefetching]);
  const isFiltersApplied = useMemo(() => !isEmpty(orderFilters), [orderFilters]);
  const isOrderListEmpty = useMemo(() => !isListLoading && isEmpty(orders), [isListLoading, orders]);
  const isOrderSelected = useMemo(() => !isEmpty(selectedOrder), [selectedOrder]);

  const onSelectOrder = useCallback((reference) => dispatch(setSelectedOrder(reference)), [dispatch]);

  const onDeselectOrder = useCallback(() => {
    if (!isOrderSelected) return;
    dispatch(resetSelectedOrder());
  }, [isOrderSelected, dispatch]);

  const onChangePage = useCallback(
    (_, page) => pagination.active_page !== page && dispatch(fetchOrdersRequest(page)),
    [pagination.active_page, dispatch]
  );

  const onChangeFilters = useCallback((filters) => dispatch(fetchFilteredOrdersRequest(filters)), []);

  return [
    { orders, ordersByMonth, selectedOrder, pagination, filters: orderFilters },
    { onSelectOrder, onDeselectOrder, onChangePage, onChangeFilters },
    { isIdle: idle, isListLoading, isOrderListEmpty, isOrderSelected, isFirstLoading: ordersLoading, isFiltersApplied },
  ];
};

export default useOrders;
