import { getAuth } from "@firebase/auth";
import { addMinutes, getHours, isAfter, setHours, setMinutes } from "date-fns";
import { set } from "lodash";
import { PEAK_HOURS_CONFIG, WORKING_HOURS_CONFIG } from "../../constants";
import { format } from "../date-fns";

export const generateTimePickerData = (date, config = {}) => {
  const { startTime, endTime, withoutPastLimit = false } = config;
  const TIME_RESTRICTIONS = {
    startTime: startTime || WORKING_HOURS_CONFIG.start,
    endTime: endTime || WORKING_HOURS_CONFIG.end,
  };

  const hours = Array(TIME_RESTRICTIONS.endTime - TIME_RESTRICTIONS.startTime)
    .fill()
    .map((_, index) => TIME_RESTRICTIONS.startTime + index);

  const positions = [0, 15, 30, 45];

  const lastValue = setHours(setMinutes(new Date(date), 0), TIME_RESTRICTIONS.endTime);

  const options = hours.reduce((prevValues, hour) => {
    const dateWithHours = setHours(new Date(date), hour);
    const dateWithHoursAndMinutes = positions.map((position) => {
      const value = setMinutes(dateWithHours, position);
      return { value: new Date(value).toISOString(), label: format(value, "HH:mm") };
    });

    return [...prevValues, ...dateWithHoursAndMinutes];
  }, []);

  const data = [...options, { value: new Date(lastValue).toISOString(), label: format(lastValue, "HH:mm") }];

  if (withoutPastLimit) return data;
  return data.filter(({ value }) => isAfter(new Date(value), new Date(addMinutes(new Date(), 15))));
};

export const isPeakTime = (date) => {
  const startHour = getHours(new Date(date));
  return startHour >= PEAK_HOURS_CONFIG.start && startHour < PEAK_HOURS_CONFIG.end;
};

export const getUserUID = () => {
  const auth = getAuth();
  const user = JSON.parse(localStorage.getItem("authUser"));

  return auth?.currentUser?.uid || user.uid;
};

export const checkIsAdmin = async () => {
  const auth = getAuth();
  const user = JSON.parse(localStorage.getItem("authUser"));

  if (!auth) return Boolean(user?.claims?.pedivanAdmin);
  const res = await auth.currentUser?.getIdTokenResult();
  return Boolean(res?.claims?.pedivanAdmin);
};

export const prettifyStatusLabel = (status) => status.charAt(0).toUpperCase() + status.slice(1);

export const convertTimestampToDate = (date) => new Date(date?._seconds * 1000);

export const validateSchema = (schema, data) => {
  return new Promise((resolve) => {
    if (!schema) resolve({});

    schema
      .validate(data, { abortEarly: false })
      .then(() => resolve({}))
      .catch((error) => {
        resolve(
          error.inner.reduce((prev, { message, path }) => {
            return set(prev, path, message);
          }, {})
        );
      });
  });
};

export const formatBytes = (kiloBytes, decimals) => {
  if (kiloBytes === 0) return ["0", "KB"];

  const k = 1024;
  const dm = decimals + 1 || 1;
  const sizes = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(kiloBytes) / Math.log(k));

  return [parseFloat((kiloBytes / k ** i).toFixed(dm)).toLocaleString(), sizes[i]].join(" ");
};
