import { arrayOf, number, shape, string } from "prop-types";

const LocationDataType = shape({
  geolocation: arrayOf(number),
  secondLineAddress: string,
  location: string,
  buildingOrFlatNumber: string,
  postcode: string,
  city: string,
  country: string,
  recipient: shape({
    name: string,
    email: string,
    phone: string,
  }),
  notes: string,
});

export default LocationDataType;
