import { AuthContext } from "contexts/AuthContext";
import { isNull } from "lodash";
import { useContext } from "react";

const useAuthState = () => {
  const auth = useContext(AuthContext);

  return { ...auth, failed: !isNull(auth.error), isAuthenticated: auth.user != null };
};

export default useAuthState;
