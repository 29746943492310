import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = css`
  position: relative;
  border-bottom: 1px solid ${palette.grey200};

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    border-bottom: 2px solid ${palette.primary.main};
  }
`;

export default styles;
