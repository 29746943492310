import { PropTypes } from "prop-types";

const EmptyPageText = ({ children, ...restProps }) => (
  <div className="col-12 px-0 text-center text-fs-14 text-color-grey400" {...restProps}>
    {children}
  </div>
);

EmptyPageText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyPageText;
