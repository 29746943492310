import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  collapse: css`
    border-bottom: 1px solid ${palette.grey200};
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &.Mui-expanded {
      border-bottom-color: transparent;
    }
  `,
  map: css`
    height: 289px;
  `,
};

export default styles;
