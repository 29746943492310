import PropTypes from "prop-types";

const ListFiltersLayout = ({ statusFilterSection, dateFilterSection, searchFilterSection, skeletonSection }) => {
  if (skeletonSection) return skeletonSection;
  return (
    <div className="d-flex flex-wrap justify-content-between gap-2 py-md-2 my-4 pl-1 pr-3">
      <div className="d-flex col-lg-8 col-12 gap-2 px-0">
        {statusFilterSection}

        {dateFilterSection}
      </div>

      <div className="col-lg-3 col-12 px-0">{searchFilterSection}</div>
    </div>
  );
};

ListFiltersLayout.defaultProps = {
  skeletonSection: null,
};

ListFiltersLayout.propTypes = {
  skeletonSection: PropTypes.node,
  statusFilterSection: PropTypes.node.isRequired,
  dateFilterSection: PropTypes.node.isRequired,
  searchFilterSection: PropTypes.node.isRequired,
};

export default ListFiltersLayout;
