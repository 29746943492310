import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  container: css`
    font-family: Inter !important;
    font-size: 12px !important;
  `,
  input: css`
    min-height: 42.25px;

    border-color: ${palette.grey200} !important;
    border-radius: 8px !important;

    font-size: 12px !important;

    transition: none !important;
    box-shadow: none !important;

    &:hover {
      border-color: ${palette.primary.main} !important;
      cursor: pointer;
    }

    &:disabled {
      cursor: pointer !important;
    }
  `,
  error: css`
    border-color: ${palette.error.main} !important;
  `,
  disabled: css`
    background-color: ${palette.grey50} !important;

    border-color: rgba(0, 0, 0, 0.26) !important;

    color: rgba(0, 0, 0, 0.38) !important;
  `,
  arrow: css`
    .arrow {
      border-left-width: 5px !important;
      border-right-width: 5px !important;
      border-top-color: ${palette.primary.main} !important;
      border-top-width: 5px !important;
      border-bottom-color: ${palette.primary.main} !important;
    }
  `,
};

export default styles;
