import useDeviceType from "hooks/useDeviceType";
import DetailsDrawer from "library/Organisms/DetailsDrawer";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import { PropTypes } from "prop-types";
import styles from "./InvoiceDetailsDrawer.styles";

const InvoiceDetailsDrawer = ({ children, controlsSection }) => {
  const { isMobile } = useDeviceType();

  const [{ selectedInvoice }, { onDeselectInvoice }, { isInvoiceSelected }] = useInvoices();

  return (
    <DetailsDrawer
      id={`invoice-details-drawer-${selectedInvoice?.id || ""}`}
      className={!isMobile && "position-sticky"}
      classNameInner={styles.drawer(isMobile)}
      onClose={onDeselectInvoice}
      contentInnerSection={children}
      contentControlsSection={isMobile && controlsSection}
      open={isInvoiceSelected}
      focus={isInvoiceSelected}
      openInner
      sticky
    />
  );
};

InvoiceDetailsDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  controlsSection: PropTypes.node.isRequired,
};

export default InvoiceDetailsDrawer;
