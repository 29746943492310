import { css } from "@emotion/css";

const styles = {
  statusBarSection: css`
    min-height: 55px;
    max-height: 55px;
    height: 100%;
    margin-top: -27.5px;

    z-index: 3;
  `,
  controlsSection: css`
    box-shadow: 0px -3px 6px #00000029;
    z-index: 1;
  `,
};

export default styles;
