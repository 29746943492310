import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import { PropTypes } from "prop-types";
import LibraryDetailsLayout from "../LibraryDetailsLayout/LibraryDetailsLayout";

const AddressDetailsLayout = ({ formattedAddressSection, addressDetailsSection, ...restProps }) => (
  <LibraryDetailsLayout
    listSection={
      <BoxLayout className="mb-4 py-4">
        <div className="d-flex flex-nowrap text-fs-14 text-fw-semibold text-truncate px-4">
          {formattedAddressSection}
        </div>

        <div className="d-flex flex-wrap col-12 mt-2 px-0">{addressDetailsSection}</div>
      </BoxLayout>
    }
    {...restProps}
  />
);

AddressDetailsLayout.propTypes = {
  formattedAddressSection: PropTypes.node.isRequired,
  addressDetailsSection: PropTypes.node.isRequired,
};

export default AddressDetailsLayout;
