import { css } from "@emotion/css";

const styles = {
  layout: css`
    width: fit-content;
  `,

  collapse: (open) =>
    open
      ? css`
          &::after {
            content: none;
          }
        `
      : css`
          &::after {
            position: absolute;
            top: 80%;
            bottom: 10%;
            right: 0;
            width: 100%;
            content: "";
            background: -moz-linear-gradient(0deg, rgba(244, 245, 246, 1) 0%, rgba(255, 255, 255, 0) 100%);
            background: rgb(244, 245, 246);
            background: -o-linear-gradient(0deg, rgba(244, 245, 246, 1) 0%, rgba(255, 255, 255, 0) 100%);
            background: -ms-linear-gradient(0deg, rgba(244, 245, 246, 1) 0%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(244, 245, 246, 1) 0%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(0deg, rgba(244, 245, 246, 1) 0%, rgba(255, 255, 255, 0) 100%);
          }
        `,
};

export default styles;
