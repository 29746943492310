import { cx } from "@emotion/css";
import Skeleton from "library/Atoms/Skeleton";
import styles from "./styles";

const ListFiltersSkeleton = () => (
  <div className="d-flex flex-sm-nowrap flex-wrap align-items-stretch justify-content-between py-md-2 my-4">
    <Skeleton className={cx(styles.filters, "col-7 px-0 my-3")} />
    <Skeleton className={cx(styles.filters, "col-sm-3 col-12 px-0 my-3")} />
  </div>
);

export default ListFiltersSkeleton;
