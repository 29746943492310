import { css } from "@emotion/css";

const styles = {
  select: css`
    max-height: 40px;
  `,
  helperText: css`
    top: 100%;
    margin-bottom: -20px !important;
  `,
};

export default styles;
