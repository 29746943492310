import { cx } from "@emotion/css";
import { values } from "lodash";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import styles from "./styles";

const STATUS_ICON_TYPES = { component: "component", rounded: "component-rounded" };

const StatusIcon = ({ className, status, renderColor, renderIcon, variant, ...restProps }) => {
  const color = useMemo(() => renderColor(status), [renderColor, status]);
  const IconComponent = useMemo(() => renderIcon(status), [status, renderIcon]);

  if (variant === STATUS_ICON_TYPES.component) {
    return <IconComponent className={cx(styles.icon, className)} variant="filled" {...restProps} />;
  }
  return (
    <div className={cx(styles.iconWrapper, "d-flex align-items-center justify-content-center", `bg-color-${color}`)}>
      <IconComponent className={cx(styles.icon, className)} {...restProps} />
    </div>
  );
};

StatusIcon.defaultProps = {
  className: "",
  variant: "component",
};

StatusIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  renderColor: PropTypes.func.isRequired,
  renderIcon: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(values(STATUS_ICON_TYPES)),
};

export default StatusIcon;
