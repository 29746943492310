import { css } from "@emotion/css";

const styles = {
  hiddenInput: css`
    height: 0;
    color: transparent;
  `,
  btn: css`
    max-width: 100% !important;
  `,
};

export default styles;
