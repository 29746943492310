import { useFormikContext } from "formik";
import TextInput from "library/Atoms/Input/TextInput";
import Select from "library/Atoms/Select";
import Subtitle from "library/Atoms/Subtitle";
import { keys } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { INVOICE_PERIODS } from "../../../../../constants";

const InvoiceForm = () => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  const handleChangePeriod = useCallback(
    (name, value) => {
      setFieldValue(name, value);
      setFieldValue("invoice.frequency", INVOICE_PERIODS[value]);
    },
    [setFieldValue]
  );

  const handleChangeInvoiceEmail = useCallback((name, value) => setFieldValue(name, value), [setFieldValue]);

  return (
    <div className="d-flex flex-wrap">
      <Subtitle className="col-12 mt-4 px-0">{t("profile.forms.invoiceForm.formTitle")}</Subtitle>

      <TextInput
        id="invoiceEmail"
        name="invoiceEmail"
        className="col-12 col-md-6 my-3 px-0 pr-md-2"
        label={t("common.forms.invoiceEmailLabel")}
        placeholder={t("common.forms.invoiceEmailPlaceholder")}
        onChange={handleChangeInvoiceEmail}
        disabled
      />

      <Select
        id="period"
        name="invoice.period"
        className="col-12 col-md-6 my-3 px-0 pl-md-2"
        label={t("profile.forms.invoiceForm.invoicePeriodLabel")}
        placeholder={t("profile.forms.invoiceForm.invoicePeriodPlaceholder")}
        onChange={handleChangePeriod}
        options={keys(INVOICE_PERIODS).map((period) => ({ label: t(`utils.invoicePeriods.${period}`), value: period }))}
        required
      />
    </div>
  );
};

export default InvoiceForm;
