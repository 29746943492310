import { Translate as TranslateIcon } from "@mui/icons-material";
import { Badge } from "@mui/material";
import LanguageSwitcher from "features/LanguageSwitcher";
import useToggleMenu from "hooks/useToggleMenu";
import IconButton from "library/Atoms/Button/IconButton";
import Flag from "library/Atoms/Flag";
import { PedivanDarkLogo } from "library/Atoms/SVGIcons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AuthFormHeader = () => {
  const { i18n } = useTranslation();

  const [{ anchorEl: anchorLanguageMenuEl, isOpen: isLanguageMenuOpen }, onOpenLanguageMenu, onCloseLanguageMenu] =
    useToggleMenu(false);

  return (
    <>
      <Link>
        <PedivanDarkLogo />
      </Link>

      <IconButton id="language-switcher-btn" onClick={onOpenLanguageMenu} color="secondary">
        <Badge badgeContent={<Flag locale={i18n.language} />} color="primary">
          <TranslateIcon />
        </Badge>
      </IconButton>

      {isLanguageMenuOpen && <LanguageSwitcher anchorEl={anchorLanguageMenuEl} onClose={onCloseLanguageMenu} />}
    </>
  );
};

export default AuthFormHeader;
