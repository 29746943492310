import { css } from "@emotion/css";

const styles = {
  image: css`
    max-width: 100%;
    height: 200px;
  `,
};

export default styles;
