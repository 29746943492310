import {
  addDays,
  addHours,
  addMinutes,
  isAfter,
  isEqual,
  isSameDay,
  isWeekend,
  isWithinInterval,
  startOfDay,
  subDays,
  subMinutes,
} from "date-fns";
import { useFormikContext } from "formik";
import useToast from "hooks/useToast";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generateTimePickerData, isPeakTime } from "utility/helpers/general";

const useTimeForm = (withoutPastLimit = false) => {
  const { t } = useTranslation();
  const onShowToast = useToast();

  const {
    values: { startDate, endDate, isExpress },
    setFieldValue,
  } = useFormikContext();

  const timePickUpOptions = useMemo(() => {
    if (!startDate) return [];

    const options = generateTimePickerData(startDate, { withoutPastLimit }).map((option) => {
      const isPeakTimeOption = withoutPastLimit
        ? isPeakTime(new Date(option.value))
        : !isWeekend(startDate) && isPeakTime(new Date(option.value));
      return isPeakTimeOption ? { ...option, className: "text-color-warning" } : option;
    });
    return isExpress ? options.slice(0, -1) : options;
  }, [startDate, isExpress]);

  const timeToDropOffOptions = useMemo(() => {
    if (!startDate && !endDate) return [];

    if (isExpress) {
      return generateTimePickerData(startDate, { withoutPastLimit }).filter(({ value }) =>
        isAfter(new Date(value), addMinutes(startDate, 15))
      );
    }
    return generateTimePickerData(startOfDay(endDate), { withoutPastLimit }).filter(({ value }) =>
      isAfter(new Date(value), addHours(addMinutes(subMinutes(startDate, 1), 15), 2))
    );
  }, [startDate, endDate, isExpress]);

  const onToggleIsExpress = useCallback(
    (newValue) => {
      setFieldValue("isExpress", newValue);

      if (newValue || !startDate || !endDate) return;

      if (startDate.getHours() >= 19) {
        isSameDay(startDate, endDate) && setFieldValue("endDate", new Date(addDays(endDate, 1)));
      } else {
        !isSameDay(startDate, endDate) && setFieldValue("endDate", new Date(subDays(endDate, 1)));
      }
    },
    [startDate, endDate, setFieldValue]
  );

  const onChangePickUpAt = useCallback(
    (_, newStartDate) => {
      newStartDate = new Date(newStartDate);

      setFieldValue("startDate", newStartDate);

      let newEndDate = endDate;
      const lastEndTimeOption = new Date(timeToDropOffOptions[timeToDropOffOptions.length - 1].value);

      if (!isExpress) {
        if (newStartDate.getHours() >= 19) {
          isSameDay(newStartDate, newEndDate) && (newEndDate = new Date(addDays(lastEndTimeOption, 1)));
        } else {
          !isSameDay(newStartDate, newEndDate) && (newEndDate = new Date(subDays(lastEndTimeOption, 1)));
        }
      }

      if (isEqual(newEndDate, endDate)) return;
      setFieldValue("endDate", new Date(newEndDate));
    },
    [endDate, timeToDropOffOptions, isExpress, setFieldValue]
  );

  const onChangeDropOffAt = useCallback((_, newValue) => setFieldValue("endDate", new Date(newValue)), [setFieldValue]);

  useEffect(() => {
    if (!endDate || !timeToDropOffOptions.length) return;

    const firstDropOffOption = new Date(timeToDropOffOptions[0].value);
    const lastDropOffOption = new Date(timeToDropOffOptions[timeToDropOffOptions.length - 1].value);

    if (isWithinInterval(endDate, { start: firstDropOffOption, end: lastDropOffOption })) return;

    onShowToast(t("common.forms.dropOffTimeToast"), { type: "warning", timeout: 2000 });
    setFieldValue("endDate", new Date(timeToDropOffOptions[timeToDropOffOptions.length - 1].value));
  }, [endDate, timeToDropOffOptions, setFieldValue, onShowToast]);

  useEffect(() => {
    if (!startDate || !timePickUpOptions.length) return;

    const firstPickUpOption = new Date(timePickUpOptions[0].value);
    const lastPickUpOption = new Date(timePickUpOptions[timePickUpOptions.length - 1].value);

    if (isWithinInterval(startDate, { start: firstPickUpOption, end: lastPickUpOption })) return;

    onShowToast(t("common.forms.pickUpTimeToast"), { type: "warning", timeout: 2000 });
    setFieldValue("startDate", new Date(timePickUpOptions[0].value));
  }, [startDate, timePickUpOptions, onShowToast, setFieldValue]);

  return [
    { timePickUpOptions, timeToDropOffOptions },
    { onToggleIsExpress, onChangePickUpAt, onChangeDropOffAt },
  ];
};

export default useTimeForm;
