import { cx } from "@emotion/css";
import { MenuItem, Select as MuiSelect } from "@mui/material";
import { useField } from "formik";
import useInputErrorHandler from "hooks/useInputErrorHandler";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectOptionType from "types/helperTypes/Select";
import styles from "./styles";

const Select = ({
  className,
  name,
  selectClassName,
  label,
  placeholder,
  value,
  onChange,
  options,
  helper,
  required,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [{ value: formikValue = null, ...field } = {}, { error } = {}] = useField({ name });
  const inputRef = useInputErrorHandler(error);

  const selectedValue = useMemo(() => {
    const option = options.find((opt) => opt.value === (value || formikValue));
    if (!option) return placeholder;
    return option.label;
  }, [formikValue, value, options, placeholder]);

  const onSelectValue = useCallback((e) => onChange(name, e.target.value), [name, onChange]);

  return (
    <InputLayout
      className={cx(className, "position-relative")}
      label={label}
      error={t(error)}
      helper={helper}
      required={required}
    >
      <MuiSelect
        {...field}
        ref={inputRef}
        name={name}
        className={cx(styles.select, selectClassName)}
        value={value ?? formikValue}
        onChange={onSelectValue}
        renderValue={(v) => (v ? selectedValue : placeholder)}
        displayEmpty
        {...restProps}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled ?? false}>
            <span className={option.className}>{option.label}</span>
          </MenuItem>
        ))}
      </MuiSelect>
    </InputLayout>
  );
};

Select.defaultProps = {
  value: null,
  className: "",
  selectClassName: "",
  label: null,
  placeholder: null,
  helper: null,
  required: false,
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(SelectOptionType).isRequired,
  helper: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool,
};

export default Select;
