import { cx } from "@emotion/css";
import { DesktopDatePicker, LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isAfter, isSameDay, isWithinInterval } from "date-fns";
import useOpenClose from "hooks/useOpenClose";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "utility/date-fns";
import { getDateFnsLocale } from "utility/helpers/locales";
import styles from "./styles";

const DateRangePickerDay = ({ day, startDate, endDate, hoveredStartDate, hoveredEndDate, ...restProps }) => {
  const isStartDate = useMemo(() => startDate && isSameDay(day, startDate), [day, startDate]);
  const isEndDate = useMemo(() => endDate && isSameDay(day, endDate), [day, endDate]);
  const isBetween = useMemo(() => {
    return (
      startDate && endDate && !isAfter(startDate, endDate) && isWithinInterval(day, { start: startDate, end: endDate })
    );
  }, [startDate, endDate, day]);
  const isStartAndEndDatesDifferent = useMemo(() => {
    return startDate && endDate && !isSameDay(startDate, endDate);
  }, [startDate, endDate]);

  const isStartHoveredDate = useMemo(() => {
    return hoveredStartDate && isSameDay(day, hoveredStartDate);
  }, [day, hoveredStartDate]);
  const isEndHoveredDate = useMemo(() => hoveredEndDate && isSameDay(day, hoveredEndDate), [day, hoveredEndDate]);
  const isBetweenHovered = useMemo(() => {
    return (
      hoveredStartDate &&
      hoveredEndDate &&
      !isAfter(hoveredStartDate, hoveredEndDate) &&
      isWithinInterval(day, { start: hoveredStartDate, end: hoveredEndDate })
    );
  }, [hoveredStartDate, hoveredEndDate, day]);
  const isStartAndEndHoveredDatesDifferent = useMemo(() => {
    return hoveredStartDate && hoveredEndDate && !isSameDay(hoveredStartDate, hoveredEndDate);
  }, [hoveredStartDate, hoveredEndDate]);

  const isToday = useMemo(() => isSameDay(day, new Date()), [day]);

  const pickerDayStyles = useMemo(() => {
    if (isStartDate || isStartHoveredDate) {
      let currStyles = [];

      if (isStartDate) currStyles = [...currStyles, styles.edgeDay, styles.nonRoundedRightBorder];

      if (isStartHoveredDate) currStyles = [...currStyles, styles.hoveredDay, styles.roundedLeftBorder];

      if (isStartHoveredDate && isStartDate) currStyles = [...currStyles, styles.roundedRightBorder];

      if (isStartAndEndHoveredDatesDifferent || isStartAndEndDatesDifferent) {
        currStyles = [...currStyles, styles.nonRoundedRightBorder];
      }

      if (isStartDate && isEndHoveredDate) {
        currStyles = [...currStyles, styles.hoveredDay, styles.roundedRightBorder, styles.nonRoundedLeftBorder];
      }

      return currStyles;
    }
    if (isEndDate || isEndHoveredDate) {
      let currStyles = [];

      if (isEndDate) currStyles = [...currStyles, styles.edgeDay, styles.nonRoundedLeftBorder];

      if (isEndHoveredDate) currStyles = [...currStyles, styles.hoveredDay, styles.roundedRightBorder];

      if (isEndHoveredDate && isEndDate) currStyles = [...currStyles, styles.roundedLeftBorder];

      return currStyles;
    }
    if (isBetween) return styles.betweenDay;
    if (isBetweenHovered) return styles.hoveredDay;

    return null;
  }, [
    isStartDate,
    isEndDate,
    isBetween,
    isStartAndEndHoveredDatesDifferent,
    isStartAndEndDatesDifferent,
    isBetweenHovered,
    isStartHoveredDate,
    isEndHoveredDate,
  ]);

  return (
    <PickersDay
      day={day}
      className={cx(styles.day, pickerDayStyles, isToday && styles.today)}
      selected={false}
      disableHighlightToday
      {...restProps}
    />
  );
};

DateRangePickerDay.defaultProps = {
  startDate: new Date(),
  endDate: new Date(),
  hoveredStartDate: new Date(),
  hoveredEndDate: new Date(),
};

DateRangePickerDay.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  hoveredStartDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  hoveredEndDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

const DateRangePicker = ({ name, placeholder, onSelect, renderInput, renderToolbar, ...restProps }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [hoveredStartDate, setHoveredStartDate] = useState("");
  const [hoveredEndDate, setHoveredEndDate] = useState("");

  const [history, setHistory] = useState([]);

  const [isPickerOpen, openPicker, closePicker] = useOpenClose(false);
  const [isValueApplied, applyValue, denyValue] = useOpenClose(false);

  const [inputValue, setInputValue] = useState(placeholder);

  const onHover = useCallback(
    (date) => {
      if (!hoveredStartDate) return;
      if (isAfter(new Date(startDate), date)) {
        setHoveredEndDate(startDate);
        setHoveredStartDate(date);
      } else {
        setHoveredEndDate(date);
        setHoveredStartDate(startDate);
      }
    },
    [hoveredStartDate, startDate]
  );

  const handleClearHoveredDates = useCallback(() => {
    setHoveredStartDate("");
    setHoveredEndDate("");
  }, []);

  const handleChange = useCallback(
    (date) => {
      denyValue();

      if (startDate && endDate) {
        setStartDate(date);
        setHoveredStartDate(date);
        setEndDate("");
        return;
      }

      if (startDate) {
        handleClearHoveredDates();

        if (isAfter(new Date(startDate), date)) {
          setEndDate(startDate);
          setStartDate(date);
          return;
        }
        setEndDate(date);
        return;
      }

      setStartDate(date);
      setHoveredStartDate(date);
    },
    [startDate, endDate, denyValue, handleClearHoveredDates]
  );

  const handleClear = useCallback(() => {
    denyValue();
    setStartDate("");
    setEndDate("");
    handleClearHoveredDates();
  }, [denyValue, handleClearHoveredDates]);

  const handleCancel = useCallback(() => {
    onSelect(name, [null, null]);
    handleClear();
    closePicker();
    setInputValue(placeholder);
    setHistory([]);
  }, [name, onSelect, placeholder, handleClear, closePicker]);

  const handleApply = useCallback(() => {
    if (!startDate) {
      handleCancel();
      return;
    }

    closePicker();
    applyValue();

    handleClearHoveredDates();

    let selectedInterval = [];
    if (endDate) selectedInterval = [startDate, endDate];
    else {
      setEndDate(startDate);
      selectedInterval = [startDate, startDate];
    }

    onSelect(name, selectedInterval);
    setHistory(selectedInterval);
  }, [startDate, endDate, onSelect, name, handleClearHoveredDates, handleCancel, closePicker, applyValue]);

  const handleClose = useCallback(() => {
    if (isValueApplied) {
      closePicker();
      return;
    }
    if (history.length) {
      closePicker();
      setStartDate(history[0]);
      setEndDate(history[1]);
      handleClearHoveredDates();
      applyValue();
      return;
    }

    closePicker();
    onSelect(name, [null, null]);
    handleClear();
  }, [isValueApplied, onSelect, history, name, handleClearHoveredDates, handleClear, closePicker, applyValue]);

  useEffect(() => {
    if (!isValueApplied) return;
    if (!startDate) {
      setInputValue(placeholder);
      return;
    }

    let value = `${format(new Date(startDate), "MM/dd/yy")} - `;
    endDate && (value += format(new Date(endDate), "MM/dd/yy"));

    setInputValue(value);
  }, [isValueApplied, startDate, endDate, placeholder]);

  useEffect(() => {
    if (startDate || endDate) return;
    if (inputValue === placeholder) return;
    setInputValue(placeholder);
  }, [startDate, endDate, inputValue, placeholder]);

  return (
    <LocalizationProvider adapterLocale={getDateFnsLocale()} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        open={isPickerOpen}
        value={startDate ? new Date(startDate) : new Date()}
        onChange={handleChange}
        onClose={handleClose}
        PaperProps={{
          onMouseLeave: handleClearHoveredDates,
          onMouseEnter: () => startDate && setHoveredStartDate(startDate),
        }}
        renderDay={(date, _, params) => (
          <div key={date} onMouseEnter={() => onHover(date)}>
            <DateRangePickerDay
              day={date}
              startDate={startDate}
              endDate={endDate}
              hoveredStartDate={startDate && !endDate ? hoveredStartDate : ""}
              hoveredEndDate={startDate && !endDate ? hoveredEndDate : ""}
              aria-label={format(date, "d MMM yyyy")}
              {...params}
            />
          </div>
        )}
        renderInput={({ inputProps, ...restParams }) =>
          renderInput({
            isOpen: isPickerOpen,
            open: openPicker,
            close: handleCancel,
            value: inputValue,
            readOnly: true,
            ...restParams,
            ...restProps,
          })
        }
        ToolbarComponent={() => (
          <div className="d-flex flex-column align-items-center gap-3 mx-4 mb-2 order-1">
            {renderToolbar({
              apply: handleApply,
              clear: handleClear,
              isSingleDay: !(endDate && !isSameDay(endDate, startDate)),
              isDirty: startDate || endDate,
              isEmpty: !startDate && !endDate,
              buttonProps: { className: cx(styles.toolbarBtn, "col-12 px-0") },
            })}
          </div>
        )}
        views={["day"]}
        closeOnSelect={false}
        leftArrowButtonText=""
        rightArrowButtonText=""
        showToolbar
      />
    </LocalizationProvider>
  );
};

DateRangePicker.defaultProps = {
  name: "",
  placeholder: "",
  renderToolbar: () => {},
};

DateRangePicker.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  renderToolbar: PropTypes.func,
};

export default DateRangePicker;
