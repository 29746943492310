import useDeviceType from "hooks/useDeviceType";
import PropTypes from "prop-types";

const SideMenuLayout = ({ topPartSection, drawerSection, pageSection, modals }) => {
  const { isDesktop } = useDeviceType();

  return (
    <>
      {!isDesktop && topPartSection}

      <div className="d-flex flex-nowrap">
        {drawerSection}

        {pageSection}
      </div>

      {modals}
    </>
  );
};

SideMenuLayout.propTypes = {
  topPartSection: PropTypes.node.isRequired,
  drawerSection: PropTypes.node.isRequired,
  pageSection: PropTypes.node.isRequired,
  modals: PropTypes.node.isRequired,
};

export default SideMenuLayout;
