import useAutocomplete from "features/ChooseAddressForm/hooks/useAutocomplete";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import useDebounce from "hooks/useDebounce";
import useOpenClose from "hooks/useOpenClose";
import Accordion from "library/Atoms/Accordion";
import Autocomplete from "library/Atoms/Autocomplete";
import PhoneInput from "library/Atoms/Input/PhoneInput";
import TextInput from "library/Atoms/Input/TextInput";
import Tooltip from "library/Atoms/Tooltip";
import LoadGoogleMap from "library/GoogleMapComponents/LoadGoogleMap";
import Map from "library/GoogleMapComponents/Map";
import MapMarker from "library/GoogleMapComponents/MapMarker";
import { get, isEmpty, keys, omit, pick, size } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveContactCity } from "store/features/contact.store";
import { retrieveSavedAddresses, retrieveSavedAddressesStatus } from "store/features/library.store";
import { fetchAddressesRequest } from "store/requests/library.requests";
import LocationDataType from "types/models/LocationData";
import { DEFAULT_LOCATION_DATA } from "../../constants";
import ChooseAddressFormLayout from "./layouts/ChooseAddressFormLayout";
import styles from "./styles";

const ChooseAddressForm = ({
  name,
  label,
  className,
  address,
  onAddressChanged,
  minimal,
  required,
  withSavedAddresses,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errors, setFieldTouched } = useFormikContext();

  const { idle: savedAddressesIdleStatus } = useSelector(retrieveSavedAddressesStatus);
  const savedAddressesList = useSelector(retrieveSavedAddresses);

  const city = useSelector(retrieveContactCity);

  const { onSearchAddressByTerm, onGetPlaceDetails, onSearchAddressByCoordinates } = useAutocomplete();

  const chooseAddressForm = useFormik({
    initialValues: {
      ...(isEmpty(address) ? omit(DEFAULT_LOCATION_DATA, "recipient") : address),
      recipient: address?.recipient ? address.recipient : DEFAULT_LOCATION_DATA.recipient,
      notes: address?.notes ? address.notes : DEFAULT_LOCATION_DATA.notes,
    },
    onSubmit: (values, { setSubmitting }) => {
      onAddressChanged(values);
      setSubmitting(false);
      setFieldTouched(`${name}.location`);
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
  });
  const {
    values,
    submitForm,
    setFieldValue,
    setValues: setAllValues,
    setSubmitting,
    isSubmitting,
    setFieldError,
    setErrors,
  } = chooseAddressForm;

  const [suggestions, setSuggestions] = useState([]);

  const [isSuggestionsLoading, startSuggestionsLoading, stopSuggestionsLoading] = useOpenClose(false);

  const defaultAddressesToImport = useMemo(() => {
    if (!withSavedAddresses) return [];
    return savedAddressesList.map(({ id: addressId, label: addressLabel, location, notes, recipient }) => {
      return {
        id: addressId,
        label: addressLabel,
        data: {
          ...location,
          notes: notes ?? DEFAULT_LOCATION_DATA.notes,
          recipient: recipient ?? DEFAULT_LOCATION_DATA.recipient,
        },
        isDefault: true,
      };
    });
  }, [savedAddressesList, withSavedAddresses]);

  const setValues = useCallback((newValues) => setAllValues({ ...values, ...newValues }), [setAllValues, values]);

  // LOCATION
  const onSearchAddress = useCallback(
    (searchTerm) => {
      if (searchTerm.length < 5) return;

      setSuggestions(
        defaultAddressesToImport.filter(({ label: optionLabel }) =>
          optionLabel.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      startSuggestionsLoading();

      onSearchAddressByTerm(searchTerm).then((res) => {
        setSuggestions(res);
        stopSuggestionsLoading();
      });
    },
    [defaultAddressesToImport, onSearchAddressByTerm, startSuggestionsLoading, stopSuggestionsLoading]
  );
  const debouncedSearchAddress = useDebounce(onSearchAddress, 750);

  const onSelectAddress = useCallback(
    (addressId) => {
      const option = defaultAddressesToImport.find(({ id }) => addressId === id);

      if (option) setValues(pick(option.data, keys(DEFAULT_LOCATION_DATA))).then(() => setSubmitting(true));
      else {
        onGetPlaceDetails(addressId).then((res) => {
          if (!isEmpty(res)) setValues(pick(res, keys(DEFAULT_LOCATION_DATA))).then(() => setSubmitting(true));
          else setFieldError("location", "library.chooseAddressForm.locationBrokenDataError");
        });
      }
    },
    [defaultAddressesToImport, onGetPlaceDetails, setFieldError, setSubmitting, setValues]
  );

  const onChangeCoordinates = useCallback(
    (coordinates) => {
      onSearchAddressByCoordinates(coordinates).then((res) => {
        setSuggestions(defaultAddressesToImport);
        setFieldValue("location", "", false);
        setValues(pick(values, keys(omit(DEFAULT_LOCATION_DATA, ["recipient", "notes"]))), false).then(() => {
          setSubmitting(true);
        });
      });
    },
    [values, onSearchAddressByCoordinates, defaultAddressesToImport, setFieldValue, setValues, setSubmitting]
  );

  const onResetAddress = useCallback(() => {
    setSuggestions(defaultAddressesToImport);
    setValues(DEFAULT_LOCATION_DATA).then(() => setSubmitting(true));
  }, [defaultAddressesToImport, setValues, setSubmitting]);

  // LOCATION DETAILS
  const onChangeAddressDetails = useCallback(
    (fieldName, value) => setFieldValue(fieldName, value).then(() => setSubmitting(true)),
    [setFieldValue, setSubmitting]
  );
  const debouncedChangingAddressDetails = useDebounce(onChangeAddressDetails);

  useEffect(() => {
    if (!isEmpty(address)) return;
    onResetAddress();
  }, [address, onResetAddress]);

  useEffect(() => {
    if (!isSubmitting) return;
    submitForm();
  }, [isSubmitting, submitForm]);

  useEffect(() => {
    if (!savedAddressesIdleStatus) return;
    dispatch(fetchAddressesRequest());
  }, [savedAddressesIdleStatus, dispatch]);

  useEffect(() => {
    if (size(defaultAddressesToImport) === 0) return;
    setSuggestions(defaultAddressesToImport);
  }, [defaultAddressesToImport]);

  useEffect(() => {
    const error = get(errors, name);
    isEmpty(error) ? setErrors({}) : setErrors(error);
  }, [errors, name, setErrors, values]);

  return (
    <FormikProvider value={chooseAddressForm}>
      <ChooseAddressFormLayout
        className={className}
        autocompleteSection={
          <Autocomplete
            id={`autocomplete-${name}`}
            name="location"
            label={t("features.chooseAddressForm.locationLabel", label && { context: "prefix", prefix: label })}
            placeholder={t("features.chooseAddressForm.locationPlaceholder", { context: label })}
            options={suggestions}
            onChange={debouncedSearchAddress}
            onSelect={onSelectAddress}
            onReset={onResetAddress}
            loading={isSuggestionsLoading}
            required={required}
          />
        }
        buildingOrFlatNumberSection={
          <TextInput
            id={`buildingNumber-${name}`}
            name="buildingOrFlatNumber"
            label={t("features.chooseAddressForm.numberLabel")}
            placeholder={t("features.chooseAddressForm.numberPlaceholder")}
            disabled
          />
        }
        secondLineAddressSection={
          <TextInput
            id={`secondLineAddress-${name}`}
            name="secondLineAddress"
            label={t("features.chooseAddressForm.secondLineAddressLabel")}
            placeholder={t("features.chooseAddressForm.secondLineAddressPlaceholder")}
            disabled
          />
        }
        postcodeSection={
          <TextInput
            id={`postcode-${name}`}
            name="postcode"
            label={t("features.chooseAddressForm.postcodeLabel")}
            placeholder={t("features.chooseAddressForm.postcodePlaceholder")}
            disabled
          />
        }
        mapSection={
          size(values.geolocation) !== 0 && (
            <Accordion label={t("features.chooseAddressForm.showOnMapBtn")} className={styles.accordion} asyncMount>
              <LoadGoogleMap>
                <Map
                  id={`choose-address-form-map-${name}`}
                  className={styles.map}
                  bounds={[{ lat: values.geolocation[0], lng: values.geolocation[1] }]}
                  mapBoundsDependencies={[values.geolocation]}
                  data-cy="choose-address-form-map"
                >
                  {() => (
                    <MapMarker
                      position={{ lat: values.geolocation[0], lng: values.geolocation[1] }}
                      onDrag={onChangeCoordinates}
                      draggable
                    />
                  )}
                </Map>
              </LoadGoogleMap>
            </Accordion>
          )
        }
        nameSection={
          <TextInput
            id={`recipientName-${name}`}
            name="recipient.name"
            label={t("features.chooseAddressForm.recipientNameLabel")}
            placeholder={t("features.chooseAddressForm.recipientNamePlaceholder")}
            onChange={debouncedChangingAddressDetails}
          />
        }
        emailSection={
          <TextInput
            id={`recipientEmail-${name}`}
            name="recipient.email"
            label={t("features.chooseAddressForm.recipientEmailLabel")}
            placeholder={t("features.chooseAddressForm.recipientEmailPlaceholder")}
            onChange={debouncedChangingAddressDetails}
          />
        }
        phoneSection={
          <PhoneInput
            id={`recipientPhone-${name}`}
            name="recipient.phone"
            label={
              <>
                <span>{t("features.chooseAddressForm.recipientPhoneLabel")}</span>
                <Tooltip>{t("features.chooseAddressForm.recipientPhoneTooltip", { context: city })}</Tooltip>
              </>
            }
            placeholder={t("common.forms.phonePlaceholder")}
            onChange={debouncedChangingAddressDetails}
          />
        }
        notesSection={
          <TextInput
            id={`additionalNotes-${name}`}
            name="notes"
            label={t("features.chooseAddressForm.additionalNotesLabel")}
            placeholder={t("features.chooseAddressForm.additionalNotesPlaceholder")}
            onChange={debouncedChangingAddressDetails}
            multiline
          />
        }
        minimal={minimal}
        data-cy={`choose-address-form-${name}`}
      />
    </FormikProvider>
  );
};

ChooseAddressForm.defaultProps = {
  name: "",
  label: "",
  className: null,
  address: DEFAULT_LOCATION_DATA,
  minimal: false,
  required: false,
  withSavedAddresses: true,
};

ChooseAddressForm.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  address: LocationDataType,
  onAddressChanged: PropTypes.func.isRequired,
  minimal: PropTypes.bool,
  required: PropTypes.bool,
  withSavedAddresses: PropTypes.bool,
};

export default ChooseAddressForm;
