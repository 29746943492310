import { generateNewDrop } from "utility/helpers/orders";
import { DEFAULT_LOCATION_DATA, DEFAULT_REPEAT_ORDER_SETTINGS } from "../../../constants";

// eslint-disable-next-line import/prefer-default-export
export const generateNewOrder = ({ firstName, lastName, phone, email }) => ({
  startDate: null,
  endDate: null,
  isExpress: false,
  pick_up: {
    ...DEFAULT_LOCATION_DATA,
    notes: "",
    recipient: {
      name: firstName && lastName ? `${firstName} ${lastName}` : DEFAULT_LOCATION_DATA.recipient.name,
      phone: phone ?? DEFAULT_LOCATION_DATA.recipient.phone,
      email: email ?? DEFAULT_LOCATION_DATA.recipient.email,
    },
  },
  drops: [generateNewDrop()],
  repeat_settings: DEFAULT_REPEAT_ORDER_SETTINGS,
});
