import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion } from "@mui/material";
import useOpenClose from "hooks/useOpenClose";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useRef } from "react";

const Accordion = ({ id, open, className, innerClassName, label, onClick, children, asyncMount }) => {
  const ref = useRef(null);

  const [isCollapseOpen, openCollapse, closeCollapse] = useOpenClose(false);

  const isElementInViewport = useMemo(() => {
    if (!ref?.current) return true;
    const rect = ref.current.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }, [ref, isCollapseOpen]);

  const handleClick = useCallback(() => {
    if (isCollapseOpen) {
      closeCollapse();
      onClick(null);
    } else {
      openCollapse();
      onClick(id);
    }
  }, [isCollapseOpen, onClick, id, closeCollapse, openCollapse]);

  useEffect(() => {
    if (!ref?.current) return;
    if (isCollapseOpen && !isElementInViewport) ref.current.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [ref, isCollapseOpen, isElementInViewport]);

  useEffect(() => {
    if (open) openCollapse();
    else closeCollapse();
  }, [open, openCollapse, closeCollapse]);

  return (
    <MuiAccordion
      id={id}
      ref={ref}
      expanded={isCollapseOpen}
      className={className}
      disableGutters
      elevation={0}
      TransitionProps={{ mountOnEnter: asyncMount }}
    >
      <AccordionSummary id={`${id}-expand-element`} expandIcon={<ExpandMoreIcon />} onClick={handleClick}>
        <div className="text-fw-semibold text-color-primary">{label}</div>
      </AccordionSummary>

      <AccordionDetails id={`${id}-details`} className={innerClassName}>
        <div className="text-color-secondary">{children}</div>
      </AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.defaultProps = {
  id: null,
  open: false,
  className: null,
  innerClassName: null,
  label: "",
  onClick: () => {},
  asyncMount: false,
};

Accordion.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  asyncMount: PropTypes.bool,
};

export default Accordion;
