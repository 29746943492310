import { cx } from "@emotion/css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ButtonBase } from "@mui/material";
import Chip from "library/Atoms/Chip";
import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import StatusIndicator from "library/Molecules/StatusViews/StatusIndicator";
import OrderBriefInfo from "pages/Dashboard/components/OrdersList/OrderItem/OrderBriefInfo/OrderBriefInfo";
import useOrders from "pages/Dashboard/hooks/useOrders";
import { getOrderStatusColor, getOrderStatusIcon } from "pages/Dashboard/utils/helper";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import OrderStatusType from "types/models/OrderStatus";
import { prettifyStatusLabel } from "utility/helpers/general";
import commonStyles from "utility/ui/common";

const OrderItem = ({ reference, startDate, endDate, packages, status, express }) => {
  const { t } = useTranslation();

  const [{ selectedOrder }, { onSelectOrder }] = useOrders();

  const itemRef = useRef(null);

  const isSelected = useMemo(() => selectedOrder?.reference === reference, [selectedOrder?.reference, reference]);

  const handleClick = useCallback(() => onSelectOrder(reference), [reference, onSelectOrder]);

  useEffect(() => {
    if (!itemRef.current || !isSelected) return;
    itemRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [itemRef, isSelected]);

  return (
    <BoxLayout
      className="my-3"
      componentClassName={cx(
        commonStyles.listItemContainer,
        "d-flex flex-sm-nowrap flex-wrap align-items-center justify-content-between gap-3 col-12 py-4 px-3 overflow-hidden"
      )}
      Component={(props) => <ButtonBase {...props} ref={itemRef} onClick={handleClick} />}
      selected={isSelected}
      data-cy={`order-item-${reference}`}
    >
      <OrderBriefInfo reference={reference} startDate={startDate} endDate={endDate} packages={packages} />

      <div className="d-flex flex-column align-items-end gap-2">
        {express && <Chip label={t("common.entities.order.express")} icon={<AccessTimeIcon />} />}

        <StatusIndicator
          status={status}
          label={t([`utils.deliveryStatuses.${status}`, prettifyStatusLabel(status)])}
          renderColor={getOrderStatusColor}
          renderIcon={getOrderStatusIcon}
          data-cy={`order-item-chip-${reference}-${status}`}
        />
      </div>
    </BoxLayout>
  );
};

OrderItem.defaultProps = {
  status: "pending",
  express: false,
};

OrderItem.propTypes = {
  reference: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  packages: PropTypes.number.isRequired,
  status: OrderStatusType,
  express: PropTypes.bool,
};

export default OrderItem;
