import { PropTypes } from "prop-types";

const PageTitle = ({ children, ...restProps }) => (
  <div className="pb-3 text-fs-18 text-fw-bold text-color-grey900" {...restProps}>
    {children}
  </div>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitle;
