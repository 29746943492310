import { css } from "@emotion/css";
import palette from "utility/ui/palette";
// eslint-disable-next-line import/no-extraneous-dependencies
import { rgba } from "emotion-rgba";

const styles = {
  dropzone: css`
    border: 2px ${palette.grey200} dashed;

    &:hover {
      border: 2px ${palette.primary.main} dashed;
    }

    &:active {
      background-color: ${rgba(palette.primary.main, 0.1)};

      border: 2px ${palette.primary.main} dashed;
    }
  `,
  activeDropzone: css`
    background-color: ${rgba(palette.primary.main, 0.1)};

    border: 2px ${palette.primary.main} dashed;
  `,

  error: css`
    border: 2px ${rgba(palette.error.main, 0.7)} dashed;

    &:hover {
      border: 2px ${palette.error.main} dashed;
    }

    &:active {
      background-color: ${rgba(palette.error.main, 0.1)};

      border: 2px ${palette.error.main} dashed;
    }
  `,

  fileIcon: css`
    font-size: 32px !important;
  `,
};

export default styles;
