import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  white: css`
    color: ${palette.white} !important;
  `,
};

export default styles;
