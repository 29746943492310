import { createAsyncThunk } from "@reduxjs/toolkit";
import { isEmpty, keys } from "lodash";
import PartnerApi from "utility/api";

export const fetchInvoicesRequest = createAsyncThunk(
  "invoice/fetchInvoicesRequest",
  async (page = 1, { getState, rejectWithValue }) => {
    const { invoice } = getState();

    try {
      const result = await PartnerApi.invoices({ page, filters: invoice.filters });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchFilteredInvoicesRequest = createAsyncThunk(
  "invoice/fetchFilteredInvoicesRequest",
  async (filters, { getState, rejectWithValue }) => {
    const { invoice } = getState();

    let currFilters = { ...filters };

    if (currFilters.status === "all") currFilters.status = null;
    currFilters = keys(currFilters).reduce((prev, field) => {
      if (isEmpty(currFilters[field])) return prev;
      return { ...prev, [field]: currFilters[field] };
    }, {});

    try {
      const result = await PartnerApi.invoices({ page: invoice.pagination.active_page, filters: currFilters });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchInvoiceDownloadLinkRequest = createAsyncThunk(
  "invoice/fetchInvoiceDownloadLinkRequest",
  async ({ invoiceId }, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.getDownloadLink(invoiceId);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
