import useDeviceType from "hooks/useDeviceType";
import Skeleton from "library/Atoms/Skeleton";
import LoadGoogleMap from "library/GoogleMapComponents/LoadGoogleMap";
import OrderMapComponent from "pages/NewOrder/components/CalculatePriceForm/NewOrderDirectionMap/NewOrderDirectionMap";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { retrieveSelectedSavedOrder } from "store/features/library.store";
import { createMarkerPosition } from "utility/helpers/markers";

const OrderMap = () => {
  const { isDesktop } = useDeviceType();

  const selectedOrder = useSelector(retrieveSelectedSavedOrder);

  const pickUpMarker = useMemo(() => {
    const { reference, pick_up: { geolocation, location } = {} } = selectedOrder;
    const position = createMarkerPosition(geolocation);
    return position ? { id: reference, position: createMarkerPosition(geolocation), location } : {};
  }, [selectedOrder]);

  const dropsMarkers = useMemo(() => {
    return selectedOrder.drops?.reduce((prev, { reference, geolocation, location, status }) => {
      const position = createMarkerPosition(geolocation);
      if (!position) return prev;
      return [...prev, { id: reference, position, status: status || selectedOrder.status, location }];
    }, []);
  }, [selectedOrder]);

  const mapBounds = useMemo(() => {
    return [pickUpMarker, ...dropsMarkers].map(({ position }) => position);
  }, [pickUpMarker, dropsMarkers]);

  return (
    <LoadGoogleMap loadingComponent={<Skeleton className="h-100" rounded={false} />}>
      <OrderMapComponent
        className="h-100"
        bounds={mapBounds}
        pickUpMarker={pickUpMarker}
        dropMarkers={dropsMarkers}
        showZoomControls={isDesktop}
      />
    </LoadGoogleMap>
  );
};

export default OrderMap;
