import { cx } from "@emotion/css";
import { KeyboardArrowDownRounded as DownIcon, KeyboardArrowUpRounded as UpIcon } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import useOpenClose from "hooks/useOpenClose";
import TextButton from "library/Atoms/Button/TextButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./styles";

const FlatBoxLayout = ({ className, children, color, collapse, ...restProps }) => {
  const { t } = useTranslation();

  const [isDetailsOpen, openDetails, closeDetails] = useOpenClose(false);

  return (
    <div
      className={cx(
        "position-relative",
        ...(!collapse ? [styles.layout, className, "d-flex p-3 text-fs-10"] : []),
        `border-rounded bg-color-${color}`
      )}
      {...restProps}
    >
      {collapse ? (
        <Collapse
          classes={{
            root: styles.collapse(isDetailsOpen),
            wrapperInner: cx(className, "d-flex p-3 text-fs-10"),
          }}
          in={isDetailsOpen}
          collapsedSize={300}
          {...restProps}
        >
          {children}
        </Collapse>
      ) : (
        children
      )}

      {collapse && (
        <div className={cx(styles.shadow, "d-flex justify-content-center my-2")}>
          <TextButton
            contentClassName="text-nowrap text-fs-12 text-fw-semibold"
            label={isDetailsOpen ? t("common.buttons.showLessBtn") : t("common.buttons.showMoreBtn")}
            onClick={isDetailsOpen ? closeDetails : openDetails}
            endIcon={isDetailsOpen ? <UpIcon className="pt-1" /> : <DownIcon className="pt-1" />}
            color="primary"
          />
        </div>
      )}
    </div>
  );
};

FlatBoxLayout.defaultProps = {
  className: null,
  color: "grey100",
  collapse: false,
};

FlatBoxLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["primary", "grey100", "warning"]),
  collapse: PropTypes.bool,
};

export default FlatBoxLayout;
