import { cx } from "@emotion/css";
import { Button } from "@mui/material";
import Spinner from "library/Atoms/Spinner";
import { PropTypes } from "prop-types";

const PrimaryButton = ({ contentClassName, label, loading, disabled, ...restProps }) => (
  <Button variant="contained" disabled={disabled || loading} {...restProps}>
    <div className={cx(contentClassName, "d-flex align-items-center justify-content-center w-100")}>
      {loading ? <Spinner color="white" /> : <span className="text-capitalized text-truncate">{label}</span>}
    </div>
  </Button>
);

PrimaryButton.defaultProps = {
  contentClassName: "",
  label: "",
  loading: false,
  disabled: false,
};

PrimaryButton.propTypes = {
  contentClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PrimaryButton;
