/* eslint-disable react/forbid-prop-types */
import { any, arrayOf, number, shape, string } from "prop-types";

export const InvoicePriceType = shape({ currency: string, tax: number, total: number });

export const InvoiceLineType = shape({
  description: string,
  price: number,
  currency: string,
  service_date: any,
  taxCodeRef: string,
});

const InvoiceType = shape({
  id: string,
  created_at: any,
  updated_at: any,
  due_at: any,
  lines: arrayOf(InvoiceLineType),
  price: InvoicePriceType,
  status: string,
});

export default InvoiceType;
