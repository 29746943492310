import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "@firebase/auth";
import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { omit } from "lodash";
import PartnerApi from "utility/api";
import { getUserUID } from "utility/helpers/general";
import i18n from "utility/i18next";
import { INVOICE_PERIODS } from "../../constants";

export const fetchContactRequest = createAsyncThunk(
  "contact/fetchContactRequest",
  async ({ contactId }, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.contact(contactId);

      const { locale } = result.data;
      if (locale) i18n.language !== locale && i18n.changeLanguage(locale);

      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const logInRequest = createAsyncThunk(
  "contact/logInRequest",
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      await dispatch(fetchContactRequest({ contactId: auth.currentUser.uid })).then(unwrapResult);
    } catch (error) {
      auth && signOut(auth);
      throw rejectWithValue(error);
    }
  }
);

export const signOutRequest = createAsyncThunk("contact/signOutRequest", async (_, { rejectWithValue, dispatch }) => {
  try {
    await signOut(getAuth());
    dispatch({ type: "resetStore" });
  } catch (error) {
    throw rejectWithValue();
  }
});

export const resetPasswordRequest = createAsyncThunk(
  "contact/resetPasswordRequest",
  async ({ email }, { rejectWithValue }) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const createContactRequest = createAsyncThunk(
  "contact/createContactRequest",
  async (contact, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.register(contact);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateContactRequest = createAsyncThunk(
  "contact/updateContactRequest",
  async (contact, { rejectWithValue }) => {
    let profile = { ...contact };

    if (profile.invoice.frequency === INVOICE_PERIODS.biweekly) {
      profile = { ...profile, invoice: { ...profile.invoice, period: "weekly" } };
    }

    try {
      const result = await PartnerApi.updateContact({
        contact: omit(profile, ["activeInvoices", "library"]),
        userId: getUserUID(),
      });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateContactLocaleRequest = createAsyncThunk(
  "contact/updateContactLocaleRequest",
  async (locale, { getState, rejectWithValue }) => {
    const {
      contact: { profile },
    } = getState();

    try {
      const result = await PartnerApi.changeLocale({ locale, id: profile?.contact_id });
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const reacceptContactTemrsRequest = createAsyncThunk(
  "contact/reacceptContactTemrsRequest",
  async (_, { getState, rejectWithValue }) => {
    const {
      contact: { profile },
    } = getState();

    try {
      const result = await PartnerApi.reacceptTerms(profile?.contact_id);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
