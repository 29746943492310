import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { LIBRARY_ITEM_TYPES } from "../../constants";
import savedAddressesReducer from "./savedAddress.store";
import savedOrderReducer from "./savedOrder.store";

export * from "./savedAddress.store";
export * from "./savedOrder.store";

// LIBRARY DEFAULTS

const librarySlice = createSlice({
  name: "filters",
  initialState: {
    type: LIBRARY_ITEM_TYPES.addresses,
  },
  reducers: {
    setAddressListType: (state) => {
      state.type = LIBRARY_ITEM_TYPES.addresses;
    },
    setOrderListType: (state) => {
      state.type = LIBRARY_ITEM_TYPES.orders;
    },
  },
});

export const { setAddressListType, setOrderListType } = librarySlice.actions;

export const retrieveLibraryListType = (state) => state.library.filters.type;

// ADDRESSES

export const retrieveSavedAddresses = (state) => state.library.address.savedAddresses;
export const retrieveSelectedAddress = (state) => {
  return (
    state.library.address.savedAddresses?.find((address) => address.id === state.library.address.selectedAddress) || {}
  );
};

export const retrieveSavedAddressesStatus = (state) => {
  const { status } = state.library.address;
  return { idle: status === "idle", loading: status === "loading" };
};
export const retrieveSelectedAddressesStatus = (state) => {
  const { addressStatus } = state.library.address;
  return { idle: addressStatus === "idle", loading: addressStatus === "loading", editing: addressStatus === "editing" };
};

// ORDERS

export const retrieveSavedOrders = (state) => state.library.order.savedOrders;
export const retrieveSavedOrder = (id) => (state) => {
  return state.library.order.savedOrders?.find((address) => address.id === id) || {};
};
export const retrieveSelectedSavedOrder = (state) => {
  return state.library.order.savedOrders?.find((order) => order.id === state.library.order.selectedSavedOrder) || {};
};

export const retrieveSavedOrdersStatus = (state) => {
  const { status } = state.library.order;
  return { idle: status === "idle", loading: status === "loading" };
};
export const retrieveSelectedSavedOrderStatus = (state) => {
  const { orderStatus } = state.library.order;
  return { idle: orderStatus === "idle", loading: orderStatus === "loading", editing: orderStatus === "editing" };
};

// OUTPUT

export const libraryCombinedSlice = combineReducers({
  filters: librarySlice.reducer,
  order: savedOrderReducer,
  address: savedAddressesReducer,
});

export default libraryCombinedSlice.reducer;
