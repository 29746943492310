import { cx } from "@emotion/css";
import ScrollLayout from "library/Layouts/ScrollLayout";
import { PropTypes } from "prop-types";
import styles from "./styles";

const ModalLayout = ({ contentClassName, contentSection, descriptionSection, modalControls, modals, ...restProps }) => (
  <>
    <ScrollLayout className={cx(contentClassName, styles.modalContent)} {...restProps}>
      {descriptionSection && <p className="mb-4 text-justify">{descriptionSection}</p>}

      {contentSection}
    </ScrollLayout>

    {modalControls && <div className="d-flex flex-column align-items-center mt-4 px-0 w-100">{modalControls}</div>}

    {modals}
  </>
);

ModalLayout.defaultProps = {
  contentClassName: null,
  descriptionSection: null,
  modals: null,
};

ModalLayout.propTypes = {
  contentClassName: PropTypes.string,
  descriptionSection: PropTypes.node,
  contentSection: PropTypes.node.isRequired,
  modalControls: PropTypes.node.isRequired,
  modals: PropTypes.node,
};

export default ModalLayout;
