import { css } from "@emotion/css";

const styles = {
  btn: css`
    width: 100%;
    max-width: 124px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  `,
};

export default styles;
