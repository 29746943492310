import { addMonths, isBefore } from "date-fns";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { convertTimestampToDate } from "utility/helpers/general";
import useSystemState from "./useSystemState";

const useContactTerms = () => {
  const { isSystemLoading, isSystemLoadingFailed } = useSystemState();

  const contact = useSelector(retrieveContact);

  const shouldReacceptTerms = useMemo(() => {
    if (isSystemLoading || isSystemLoadingFailed) return false;
    if (isEmpty(contact)) return false;
    if (contact.roles?.includes("pedivanAdmin")) return false;
    if (!contact.terms_updated_at) return true;
    return isBefore(addMonths(new Date(convertTimestampToDate(contact.terms_updated_at)), 1), new Date());
  }, [isSystemLoading, isSystemLoadingFailed, contact]);

  return shouldReacceptTerms;
};

export default useContactTerms;
