import BriefItemDetailsLayout from "library/Layouts/DetailsLayout/BriefItemDetailsLayout";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { format } from "utility/date-fns";

const OrderBriefInfo = ({ reference, startDate, endDate, packages }) => {
  const { t } = useTranslation();

  return (
    <BriefItemDetailsLayout
      titleSection={t("dashboard.order.reference", { reference })}
      descriptionSection={
        <>
          <span className="text-truncate text-center">{format(new Date(startDate), "MMM dd, yyyy")}</span>

          <span className="px-1">|</span>

          <span className="text-truncate text-center">
            {format(new Date(startDate), "HH:mm")} - {format(new Date(endDate), "HH:mm")}
          </span>

          <span className="px-1">|</span>

          <span className="text-truncate text-center">
            {t("common.entities.order.packagesCount", { count: packages })}
          </span>
        </>
      }
    />
  );
};

OrderBriefInfo.defaultProps = {
  packages: 0,
};

OrderBriefInfo.propTypes = {
  reference: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  packages: PropTypes.number,
};

export default OrderBriefInfo;
