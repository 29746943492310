import { cx } from "@emotion/css";
import Skeleton from "library/Atoms/Skeleton";
import styles from "./ProfileFormSkeleton.styles";

const ProfileFormSkeleton = () => (
  <div className="d-flex flex-wrap">
    <div className="col-12 px-0">
      <Skeleton className={cx(styles.title, "col-4 my-4 px-0 w-100")} />
    </div>

    <div className="d-flex flex-wrap col-12 col-md-6 my-3 px-0 pr-md-2">
      <Skeleton className={cx(styles.inputLabel, "col-6 mb-3 px-0")} />

      <Skeleton className={cx(styles.input, "w-100")} />
    </div>

    <div className="d-flex flex-wrap col-12 col-md-6 my-3 px-0 pl-md-2">
      <Skeleton className={cx(styles.inputLabel, "col-6 mb-3 px-0")} />

      <Skeleton className={cx(styles.input, "w-100")} />
    </div>

    <div className="d-flex flex-wrap col-12 col-md-6 my-3 px-0 pr-md-2">
      <Skeleton className={cx(styles.inputLabel, "col-6 mb-3 px-0")} />

      <Skeleton className={cx(styles.input, "w-100")} />
    </div>

    <div className="d-flex flex-wrap col-12 col-md-6 my-3 px-0 pl-md-2">
      <Skeleton className={cx(styles.inputLabel, "col-6 mb-3 px-0")} />

      <Skeleton className={cx(styles.input, "w-100")} />
    </div>

    <div className="col-12 px-0">
      <Skeleton className={cx(styles.title, "col-4 my-4 px-0 w-100")} />
    </div>

    <div className="d-flex flex-wrap col-12 col-md-6 my-3 px-0 pr-md-2">
      <Skeleton className={cx(styles.inputLabel, "col-6 mb-3 px-0")} />

      <Skeleton className={cx(styles.input, "w-100")} />
    </div>

    <div className="d-flex flex-wrap col-12 col-md-6 my-3 px-0 pl-md-2">
      <Skeleton className={cx(styles.inputLabel, "col-6 mb-3 px-0")} />

      <Skeleton className={cx(styles.input, "w-100")} />
    </div>
  </div>
);

export default ProfileFormSkeleton;
