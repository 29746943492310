import LibraryItemLayout from "pages/Library/layouts/LibraryItemLayout/LibraryItemLayout";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSavedOrder, retrieveSelectedSavedOrder, setSelectedSavedOrder } from "store/features/library.store";
import { calculatePackagesAmount } from "utility/helpers/orders";
import OrderBriefInfo from "./OrderBriefInfo/OrderBriefInfo";

const OrderItem = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentOrder = useSelector(retrieveSavedOrder(id));
  const selectedOrder = useSelector(retrieveSelectedSavedOrder);

  const isSelected = useMemo(() => selectedOrder?.id === id, [selectedOrder?.id, id]);

  const handleOpenOrder = useCallback(() => dispatch(setSelectedSavedOrder(id)), [dispatch, id]);

  return (
    <LibraryItemLayout
      titleSection={currentOrder.label}
      descriptionSection={
        <OrderBriefInfo
          startDate={currentOrder.startDate}
          endDate={currentOrder.endDate}
          packages={calculatePackagesAmount(currentOrder.drops)}
        />
      }
      onClick={handleOpenOrder}
      selected={isSelected}
      data-cy={`saved-order-item-${id}`}
    />
  );
};

OrderItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrderItem;
