export default {
  primary: { main: "#5BD88F" },
  secondary: { main: "#1F2937" },
  success: { main: "#5BD88F" },
  error: { main: "#F04444" },
  warning: { main: "#FC913C" },
  info: { main: "#9CA3AF" },
  yellow: { main: "#FBCC14" },
  white: "#fff",
  grey50: "#F9FAFB",
  grey100: "#f4f5f6",
  grey200: "#E5E7EB",
  grey300: "#D1D5DB",
  grey400: "#9CA3AF",
  grey900: "#111827",
};
