import PageHeaderLayout from "library/Layouts/PageHeaderLayout";
import SEO from "library/Molecules/SEO";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContactStatus } from "store/features/contact.store";
import ProfileForm from "./components/ProfileForm/ProfileForm";
import ProfilePageLayout from "./layouts/ProfilePageLayout/ProfilePageLayout";
import ProfileFormSkeleton from "./skeletons/ProfileFormSkeleton/ProfileFormSkeleton";

const Profile = () => {
  const { t } = useTranslation();

  const { loading: isProfileLoading } = useSelector(retrieveContactStatus);

  return (
    <ProfilePageLayout
      pageHeaderSection={
        <PageHeaderLayout
          title={t("profile.pageTitle")}
          subtitle={
            <Trans
              i18nKey="profile.pageDescription"
              tOptions={{ email: "info@pedivan.co.uk" }}
              components={{
                lnk1: (
                  <a
                    href={`mailto: info@pedivan.co.uk?subject=${t("profile.changeMyAccountHref")}`}
                    className="pl-1 text-color-primary"
                  />
                ),
                lnk2: (
                  <a
                    href={`mailto: info@pedivan.co.uk?subject=${t("profile.deleteMyAccountHref")}`}
                    className="pl-1 text-color-primary"
                  />
                ),
              }}
            />
          }
        />
      }
      formSection={!isProfileLoading && <ProfileForm />}
      formSkeletonSection={isProfileLoading && <ProfileFormSkeleton />}
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.profile") })} />
    </ProfilePageLayout>
  );
};

export default Profile;
