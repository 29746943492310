import { cx } from "@emotion/css";
import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextInput from "library/Atoms/Input/TextInput";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { resetPasswordRequest } from "store/requests/contact.requests";
import styles from "../LoginForm/LoginForm.styles";
import ForgotPasswordFormValidation from "./ForgotPasswordForm.validation";

const ForgotPasswordForm = ({ onOpenLogin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const forgotPasswordForm = useFormik({
    initialValues: { email: "" },
    onSubmit: ({ email }, { setSubmitting }) => {
      dispatch(resetPasswordRequest({ email }))
        .then(unwrapResult)
        .then(() => {
          onOpenLogin();
          setSubmitting(false);
        })
        .catch(() => setSubmitting(false));
    },
    validationSchema: ForgotPasswordFormValidation,
    validateOnBlur: true,
  });
  const { submitForm, isValid, isSubmitting, dirty: isFormDirty, setFieldValue } = forgotPasswordForm;

  const debouncedSetFieldValue = useDebounce(setFieldValue);

  return (
    <FormikProvider value={forgotPasswordForm}>
      <TextInput id="email" name="email" label={t("common.forms.emailLabel")} onChange={debouncedSetFieldValue} />

      <Link
        id="login-link"
        className="my-3 text-fs-12 text-right text-color-primary text-fw-semibold"
        onClick={onOpenLogin}
      >
        {t("login.forms.forgotPasswordForm.backToLoginBtn")}
      </Link>

      <PrimaryButton
        id="reset-password-btn"
        className={cx(styles.btn, "col-12 mt-3 px-0 w-100")}
        label={t("login.forms.forgotPasswordForm.resetPasswordBtn")}
        onClick={submitForm}
        loading={isSubmitting}
        disabled={!isValid || isSubmitting || !isFormDirty}
      />
    </FormikProvider>
  );
};

ForgotPasswordForm.propTypes = {
  onOpenLogin: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
