import Subtitle from "library/Atoms/Subtitle";
import { PropTypes } from "prop-types";

const TaskFormLayout = ({
  titleSection,
  addControlSection,
  addressFormSection,
  additionalFormSection,
  removeControlSection,
  warningSection,
  ...restProps
}) => (
  <div className="d-flex flex-column align-items-stretch mb-4 pb-2" {...restProps}>
    <Subtitle>{titleSection}</Subtitle>

    {removeControlSection && <div className="d-flex justify-content-end">{removeControlSection}</div>}

    <div className="d-flex flex-wrap justify-content-stretch">
      {addressFormSection}

      {additionalFormSection}
    </div>

    {addControlSection && (
      <div className="d-flex flex-column align-items-start mt-4 pt-2 col-12 px-0">
        {addControlSection}

        {warningSection && <p className="mt-2 mb-5">{warningSection}</p>}
      </div>
    )}
  </div>
);

TaskFormLayout.defaultProps = {
  addControlSection: null,
  additionalFormSection: null,
  removeControlSection: null,
  warningSection: null,
};

TaskFormLayout.propTypes = {
  titleSection: PropTypes.node.isRequired,
  addControlSection: PropTypes.node,
  addressFormSection: PropTypes.node.isRequired,
  additionalFormSection: PropTypes.node,
  removeControlSection: PropTypes.node,
  warningSection: PropTypes.node,
};

export default TaskFormLayout;
