import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  input: css`
    max-height: 40px;
  `,
  weekend: css`
    color: ${palette.warning.main} !important;
  `,
};

export default styles;
