import LibraryItemLayout from "pages/Library/layouts/LibraryItemLayout/LibraryItemLayout";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSelectedAddress, setSelectedAddress } from "store/features/library.store";
import SavedAddressType from "types/models/SavedAddress";

const AddressItem = ({ address: { id, location, label, notes } }) => {
  const dispatch = useDispatch();

  const selectedAddress = useSelector(retrieveSelectedAddress);

  const isSelected = useMemo(() => selectedAddress?.id === id, [selectedAddress?.id, id]);

  const handleOpenAddress = useCallback(() => dispatch(setSelectedAddress(id)), [dispatch, id]);

  return (
    <LibraryItemLayout
      titleSection={label}
      descriptionSection={
        <>
          <span className="w-100 text-truncate">
            <span className="text-truncate text-center">{location.location}</span>
            <span className="px-1">|</span>
            <span className="text-truncate text-center">{location.postcode}</span>
            <span className="px-1">|</span>
            <span className="text-truncate text-center">{location.city}</span>
          </span>
          <span className="text-fs-10 text-color-grey400 text-truncate mt-1">{notes}</span>
        </>
      }
      onClick={handleOpenAddress}
      selected={isSelected}
      data-cy={`saved-address-item-${id}`}
    />
  );
};

AddressItem.defaultProps = {
  address: {},
};

AddressItem.propTypes = {
  address: SavedAddressType,
};

export default AddressItem;
