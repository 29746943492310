import useDeviceType from "hooks/useDeviceType";
import DetailsDrawer from "library/Organisms/DetailsDrawer";
import { PropTypes } from "prop-types";
import styles from "./LibraryItemDetailsDrawer.styles";

const LibraryItemDetailsDrawer = ({ mapSection, controlsSection, children, open, ...restProps }) => {
  const { isMobile, isTablet } = useDeviceType();

  return (
    <DetailsDrawer
      className={!isMobile && "position-sticky"}
      classNameInner={styles.drawer(isMobile)}
      contentSection={open && mapSection}
      contentInnerSection={children}
      contentControlsSection={(isMobile || isTablet) && open && controlsSection}
      open={open}
      focus={open}
      openInner
      sticky
      {...restProps}
    />
  );
};

LibraryItemDetailsDrawer.propTypes = {
  mapSection: PropTypes.node.isRequired,
  controlsSection: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};

export default LibraryItemDetailsDrawer;
