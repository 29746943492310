import { cx } from "@emotion/css";
import { ButtonBase } from "@mui/material";
import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import BriefItemDetailsLayout from "library/Layouts/DetailsLayout/BriefItemDetailsLayout";
import PropTypes from "prop-types";
import commonStyles from "utility/ui/common";

const LibraryItemLayout = ({ titleSection, descriptionSection, onClick, selected, ...props }) => (
  <BoxLayout
    className="my-3"
    componentClassName={cx(
      commonStyles.listItemContainer,
      "d-flex flex-wrap align-items-center justify-content-between gap-3 col-12 py-4 px-3 overflow-hidden"
    )}
    Component={(componentProps) => <ButtonBase {...componentProps} onClick={onClick} />}
    selected={selected}
    {...props}
  >
    <BriefItemDetailsLayout
      descriptionClassName="flex-column"
      titleSection={titleSection}
      descriptionSection={descriptionSection}
    />
  </BoxLayout>
);

LibraryItemLayout.propTypes = {
  titleSection: PropTypes.node.isRequired,
  descriptionSection: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default LibraryItemLayout;
