import { cx } from "@emotion/css";
import Skeleton from "library/Atoms/Skeleton";
import styles from "./InvoiceListSkeleton.styles";

const AddressListSkeleton = () => (
  <>
    <Skeleton className={cx(styles.invoiceItem, "mt-2 mb-4")} />
    <Skeleton className={cx(styles.invoiceItem, "mt-2 mb-4")} />
    <Skeleton className={cx(styles.invoiceItem, "mt-2 mb-4")} />
  </>
);

export default AddressListSkeleton;
