import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import useOrders from "pages/Dashboard/hooks/useOrders";
import { useTranslation } from "react-i18next";
import { ACCEPTED_STATUS, PENDING_STATUS } from "../../../../../constants";
import styles from "./CancelOrderBanner.styles";

const CancelOrderBanner = () => {
  const { t } = useTranslation();

  const [{ selectedOrder }] = useOrders();

  if (![PENDING_STATUS, ACCEPTED_STATUS].includes(selectedOrder?.status)) return null;
  return (
    <div className="d-flex flex-lg-column flex-row justify-content-between align-items-lg-start align-items-center gap-3 col px-0">
      <div>
        <div className="text-fs-12 text-color-secondary text-fw-semibold">{t("dashboard.order.cancelOrder.title")}</div>
        <div className="text-fs-10 text-color-secondary">{t("dashboard.order.cancelOrder.description")}</div>
      </div>

      <SecondaryButton
        className={styles.btn}
        contentClassName="text-fs-12"
        href={`mailto: info@pedivan.co.uk?subject=${t("dashboard.order.cancelOrder.cancelOrderBtnHref")}: #${
          selectedOrder?.reference
        }`}
        label={t("dashboard.order.cancelOrder.cancelOrderBtn")}
      />
    </div>
  );
};

export default CancelOrderBanner;
