import { PropTypes } from "prop-types";

const PageLayout = ({ sideMenu, children }) => (
  <div className="d-flex flex-column col px-0 h-100 text-fs-12 text-color-secondary">{sideMenu(children)}</div>
);

PageLayout.propTypes = {
  sideMenu: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageLayout;
