import { cx } from "@emotion/css";
import Subtitle from "library/Atoms/Subtitle";
import { PropTypes } from "prop-types";

const DateFormLayout = ({
  className,
  titleSection,
  expressTogglerSection,
  datePickerSection,
  startTimeInputSection,
  endTimeInputSection,
  repeatFormSection,
}) => (
  <div className={cx(className, "d-flex flex-column mb-4 pb-2")}>
    <Subtitle>{titleSection}</Subtitle>

    {expressTogglerSection}

    <div className="d-flex flex-row flex-wrap mt-4">
      {datePickerSection && <div className="col-12 col-md-4 px-0 pr-md-2">{datePickerSection}</div>}

      <div className={cx("col-6 col-md-3 mt-4 mt-md-0 pl-0 pr-2 pr-md-2", datePickerSection ? "pl-md-2" : "pl-md-0")}>
        {startTimeInputSection}
      </div>

      <div className="col-6 col-md-3 mt-4 mt-md-0 pl-2 pr-0">{endTimeInputSection}</div>
    </div>

    {repeatFormSection}
  </div>
);

DateFormLayout.defaultProps = {
  className: null,
  datePickerSection: null,
  repeatFormSection: null,
};

DateFormLayout.propTypes = {
  className: PropTypes.string,
  titleSection: PropTypes.node.isRequired,
  expressTogglerSection: PropTypes.node.isRequired,
  datePickerSection: PropTypes.node,
  startTimeInputSection: PropTypes.node.isRequired,
  endTimeInputSection: PropTypes.node.isRequired,
  repeatFormSection: PropTypes.node,
};

export default DateFormLayout;
