import { cx } from "@emotion/css";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { retrieveContactCity } from "store/features/contact.store";
import MessageLayout from "../../../layouts/MessageLayout/MessageLayout";

const EstimatedDetails = ({ className, price, starting_price, currency, distance }) => {
  const { t } = useTranslation();

  const city = useSelector(retrieveContactCity);

  const discount = useMemo(() => (Number(starting_price) - Number(price)).toFixed(2), [starting_price, price]);

  return (
    <div className={cx("d-flex flex-column col-md-6 col-12 pl-1 pr-0 pl-md-0 justify-content-start", className)}>
      <div className="d-flex flex-wrap justify-content-start col-12 px-0">
        {discount > 0 ? (
          <>
            <div className="col-5 mb-3 pl-0">{t("newOrder.estimation.price")}</div>
            <div className="col-5 mb-3 px-0 text-fw-bold" data-cy="new-order-estimated-price">
              <Trans
                i18nKey="utils.pricing.excludingVAT"
                components={{
                  price: <NumberFormat value={starting_price} displayType="text" thousandSeparator prefix={currency} />,
                }}
              />
            </div>

            <div className="col-5 mb-3 pl-0">{t("newOrder.estimation.discount")}</div>
            <div className="d-flex col-5 mb-3 px-0 gap-2" data-cy="new-order-estimated-discount">
              <span className="text-fw-bold">
                <NumberFormat
                  value={((discount * 100) / Number(price)).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                  suffix="%"
                />
              </span>

              <span>
                (<NumberFormat value={discount} displayType="text" thousandSeparator prefix={currency} />)
              </span>
            </div>
          </>
        ) : null}

        <div className="col-5 mb-3 pl-0">{t("newOrder.estimation.total")}</div>
        <div className="d-flex col-5 mb-3 px-0 gap-2" data-cy="new-order-estimated-total">
          <Trans
            i18nKey="utils.pricing.excludingVAT"
            components={{
              price: <NumberFormat value={price} displayType="text" thousandSeparator prefix={currency} />,
            }}
          />
        </div>

        {distance ? (
          <>
            <div className="col-5 mb-3 pl-0">{t("newOrder.estimation.distance")}</div>
            <div className="col-5 mb-3 px-0 text-fw-bold" data-cy="new-order-estimated-distance">
              {t("newOrder.estimation.distanceValue", { context: city, distance: distance.toFixed(1) })}
            </div>
          </>
        ) : null}
      </div>

      <MessageLayout labelSection={<>{t("newOrder.estimation.notFinalPriceMessage")}</>} />
    </div>
  );
};

EstimatedDetails.defaultProps = {
  className: null,
  price: 0,
  starting_price: 0,
  currency: null,
  distance: null,
};

EstimatedDetails.propTypes = {
  className: PropTypes.string,
  price: PropTypes.number,
  starting_price: PropTypes.number,
  currency: PropTypes.string,
  distance: PropTypes.number,
};

export default EstimatedDetails;
