import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";

const BriefItemDetailsLayout = ({ descriptionClassName, titleSection, descriptionSection }) => (
  <div className="d-flex flex-column align-items-start text-left col px-0">
    <div className="w-100 text-fs-14 text-color-grey900 text-fw-semibold text-truncate">{titleSection}</div>

    <div className={cx(descriptionClassName, "d-flex flex-nowrap w-100 overflow-hidden")}>{descriptionSection}</div>
  </div>
);

BriefItemDetailsLayout.defaultProps = {
  descriptionClassName: "",
};

BriefItemDetailsLayout.propTypes = {
  descriptionClassName: PropTypes.string,
  titleSection: PropTypes.node.isRequired,
  descriptionSection: PropTypes.node.isRequired,
};

export default BriefItemDetailsLayout;
