import { FormikProvider, useFormik } from "formik";
import PhoneInput from "library/Atoms/Input/PhoneInput";
import TextInput from "library/Atoms/Input/TextInput";
import PageTitle from "library/Atoms/Text/PageTitle";
import { isEmpty, size } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_USER_DETAILS } from "../../../../constants";
import NewOrderDetails from "../NewOrderDetails/NewOrderDetails";
import NewOrderSubmitButtons from "../NewOrderSubmitButtons/NewOrderSubmitButtons";
import BookingFormSchema from "./BookingForm.validation";

const BookingForm = ({ onReset }) => {
  const { t } = useTranslation();

  const [{ customer }, { submitForm: submitNewOrderForm, submitAndSaveForm: submitAndSaveNewOrderForm }] =
    useNewOrderForm();

  const bookingForm = useFormik({
    initialValues: { ...(isEmpty(customer) ? DEFAULT_USER_DETAILS : customer), shouldSave: false },
    onSubmit: async ({ shouldSave, ...values }, { setSubmitting }) => {
      if (shouldSave) submitAndSaveNewOrderForm(values);
      else await submitNewOrderForm(values);

      setSubmitting(false);
    },
    validationSchema: BookingFormSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });
  const { setFieldValue, errors, submitForm: submitBookingForm, isSubmitting } = bookingForm;

  const submitAndSaveBookingForm = useCallback(() => {
    setFieldValue("shouldSave", true).then(() => {
      submitBookingForm();
    });
  }, [setFieldValue, submitBookingForm]);

  return (
    <FormikProvider value={bookingForm}>
      <div className="d-flex flex-wrap justify-content-between align-items-start col-12 px-0">
        <div className="d-flex flex-column col-12 col-md-6 mt-2 px-0 pl-md-0 pr-md-4">
          <PageTitle>{t("newOrder.forms.bookingForm.formTitle")}</PageTitle>

          <TextInput
            className="mb-4 pb-2"
            label={t("common.forms.firstNameLabel")}
            placeholder={t("common.forms.firstNamePlaceholder")}
            onChange={setFieldValue}
            name="firstName"
            disabled={!!customer.firstName}
          />

          <TextInput
            className="mb-4 pb-2"
            label={t("common.forms.lastNameLabel")}
            placeholder={t("common.forms.lastNamePlaceholder")}
            onChange={setFieldValue}
            name="lastName"
            disabled={!!customer.lastName}
          />

          <TextInput
            className="mb-4 pb-2"
            label={t("common.forms.emailLabel")}
            placeholder={t("common.forms.emailPlaceholder")}
            onChange={setFieldValue}
            name="email"
            disabled={!!customer.email}
          />

          <PhoneInput
            className="mb-4 pb-2"
            label={t("common.forms.phoneLabel")}
            placeholder={t("common.forms.phonePlaceholder")}
            onChange={setFieldValue}
            name="phone"
            disabled={!!customer.phone}
          />
        </div>

        <NewOrderDetails />
      </div>

      <NewOrderSubmitButtons
        onSubmit={submitBookingForm}
        onSubmitAndSave={submitAndSaveBookingForm}
        onReset={onReset}
        submitBtnOptions={{ disabled: !!size(errors), loading: isSubmitting }}
      />
    </FormikProvider>
  );
};

BookingForm.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default BookingForm;
