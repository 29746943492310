import EmptyPageText from "library/Atoms/Text/EmptyPageText";
import { isEmpty } from "lodash";
import LibraryListLayout from "pages/Library/layouts/LibraryListLayout/LibraryListLayout";
import LibraryListSkeleton from "pages/Library/skeletons/LibraryListSkeleton/LibraryListSkeleton";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSelectedAddress,
  resetSelectedSavedOrder,
  retrieveLibraryListType,
  retrieveSavedAddresses,
  retrieveSavedAddressesStatus,
  retrieveSavedOrders,
  retrieveSavedOrdersStatus,
} from "store/features/library.store";
import { fetchAddressesRequest, fetchSavedOrdersRequest } from "store/requests/library.requests";
import { LIBRARY_ITEM_TYPES } from "../../../../constants";
import AddressItem from "./AddressItem/AddressItem";
import LibraryFilters from "./LibraryFilters/LibraryFilters";
import OrderItem from "./OrderItem/OrderItem";

const LibraryList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listType = useSelector(retrieveLibraryListType);

  const { idle: ordersIdleStatus, loading: ordersLoading } = useSelector(retrieveSavedOrdersStatus);
  const { idle: addressesIdleStatus, loading: addressesLoading } = useSelector(retrieveSavedAddressesStatus);

  const orders = useSelector(retrieveSavedOrders);
  const addresses = useSelector(retrieveSavedAddresses);

  const isListLoading = useMemo(() => addressesLoading || ordersLoading, [addressesLoading, ordersLoading]);
  const isListEmpty = useMemo(() => {
    if (listType === LIBRARY_ITEM_TYPES.orders) return !isListLoading && isEmpty(orders);
    return !isListLoading && isEmpty(addresses);
  }, [addresses, isListLoading, listType, orders]);

  const handleDeselectOrder = useCallback(() => dispatch(resetSelectedSavedOrder()), [dispatch]);
  const handleDeselectAddress = useCallback(() => dispatch(resetSelectedAddress()), [dispatch]);

  useEffect(() => {
    if (listType !== LIBRARY_ITEM_TYPES.orders) return;
    if (!ordersIdleStatus) return;
    dispatch(fetchSavedOrdersRequest());
  }, [dispatch, ordersIdleStatus, listType]);

  useEffect(() => {
    if (listType !== LIBRARY_ITEM_TYPES.addresses) return;
    if (!addressesIdleStatus) return;
    dispatch(fetchAddressesRequest());
  }, [addressesIdleStatus, dispatch, listType]);

  useEffect(() => {
    return () => {
      handleDeselectAddress();
      handleDeselectOrder();
    };
  }, [handleDeselectAddress, handleDeselectOrder]);

  return (
    <LibraryListLayout
      filtersSection={<LibraryFilters />}
      listSection={
        !isListLoading &&
        (listType === LIBRARY_ITEM_TYPES.addresses
          ? addresses.map((address) => <AddressItem key={address.id} address={address} />)
          : orders.map(({ id }) => <OrderItem key={id} id={id} />))
      }
      listSkeletonSection={isListLoading && <LibraryListSkeleton />}
      emptyListSection={
        isListEmpty && <EmptyPageText>{t("library.emptyListMessage", { context: listType })}</EmptyPageText>
      }
    />
  );
};

export default LibraryList;
