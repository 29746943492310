import "utility/validation";
import * as yup from "yup";

export default yup.object().shape({
  label: yup.string().required("common.forms.nameRequiredError"),
  location: yup.object().shape({ location: yup.string().required("common.forms.locationRequiredError") }),
  recipient: yup.object().shape({
    email: yup.string().email("common.forms.emailFormatError"),
    phone: yup.string().phone("common.forms.phoneInvalidError"),
  }),
  notes: yup.string(),
});
