import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  container: css`
    width: 100%;
  `,
  input: css`
    &::before {
      border-bottom-color: ${palette.grey400} !important;
    }
  `,
  icon: css`
    font-size: 16px !important;
    color: ${palette.grey400};
  `,
};

export default styles;
