import { cx } from "@emotion/css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isSameDay } from "date-fns";
import { useField } from "formik";
import useOpenClose from "hooks/useOpenClose";
import { size } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { format } from "utility/date-fns";
import { getDateFnsLocale } from "utility/helpers/locales";
import { DatePickerDay, DatePickerInput } from "../DatePicker";
import styles from "./styles";

const DateMultiplePicker = ({ className, name, placeholder, onSelect, minDate, limit }) => {
  const { t } = useTranslation();

  const [{ value: dates = [] } = {}] = useField({ name });

  const [isPickerOpen, openPicker, closePicker] = useOpenClose(false);

  const inputValue = useMemo(() => {
    const value = dates
      .slice(0, 2)
      .map((date) => format(new Date(date), "MMM dd yyyy"))
      .join(", ");

    if (size(dates) > 2) return t("utils.ui.multiDatesPlaceholder", { dates: value });

    return value;
  }, [dates]);

  const handleChange = useCallback(
    (newDate) => {
      if (size(dates) >= limit) return;

      const selectedDayIndex = dates.findIndex((date) => isSameDay(newDate, date));
      if (selectedDayIndex !== -1) onSelect([...dates.filter((_, index) => index !== selectedDayIndex)]);
      else onSelect([...dates, newDate]);
    },
    [dates, onSelect, limit]
  );
  const handleClear = useCallback(() => onSelect([]), [onSelect]);

  return (
    <LocalizationProvider adapterLocale={getDateFnsLocale()} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        open={isPickerOpen}
        value={minDate}
        onChange={() => {}}
        onClose={closePicker}
        renderDay={(date, _, params) => {
          const isToday = isSameDay(date, new Date());
          const isSelectedDay = dates.some((selectedDay) => isSameDay(date, selectedDay));

          return (
            <div key={date}>
              <DatePickerDay
                {...params}
                date={date}
                className={cx(isToday && styles.today, isSelectedDay && "Mui-selected")}
                onClick={handleChange}
                selected={false}
                disableHighlightToday
              />
            </div>
          );
        }}
        renderInput={({ inputProps, ...restParams }) => (
          <DatePickerInput
            {...restParams}
            className={className}
            value={inputValue}
            onClick={openPicker}
            onClear={handleClear}
            placeholder={placeholder}
            inputProps={inputProps}
            clearable={size(dates) > 0}
          />
        )}
        views={["day"]}
        minDate={minDate}
        closeOnSelect={false}
        leftArrowButtonText=""
        rightArrowButtonText=""
      />
    </LocalizationProvider>
  );
};

DateMultiplePicker.defaultProps = {
  className: null,
  placeholder: "",
  minDate: new Date(),
  limit: 10,
};

DateMultiplePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  limit: PropTypes.number,
};

export default DateMultiplePicker;
