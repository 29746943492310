import { cx } from "@emotion/css";
import { useField } from "formik";
import useInputErrorHandler from "hooks/useInputErrorHandler";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import DefaultInput from "../DefaultInput";
import styles from "./styles";

const TextInput = ({ name, label, className, value, onChange, helper, multiline, required, ...restProps }) => {
  const { t } = useTranslation();

  const [{ value: formikValue = "", ...field } = {}, { error } = {}] = useField({ name });
  const inputRef = useInputErrorHandler(error);

  return (
    <InputLayout
      className={cx(className, "mb-3 pb-1")}
      label={label}
      error={t(error)}
      helper={helper}
      required={required}
    >
      <DefaultInput
        {...field}
        inputRef={inputRef}
        name={name}
        className={cx(!multiline && styles.input)}
        value={value ?? formikValue}
        onChange={onChange}
        multiline={multiline}
        minRows={multiline ? 3 : 1}
        error={!!error}
        {...restProps}
      />
    </InputLayout>
  );
};

TextInput.defaultProps = {
  label: "",
  className: "",
  value: undefined,
  onChange: () => {},
  helper: "",
  multiline: false,
  required: false,
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helper: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
};

export default TextInput;
