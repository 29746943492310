import useDeviceType from "hooks/useDeviceType";
import DetailsDrawer from "library/Organisms/DetailsDrawer";
import useOrders from "pages/Dashboard/hooks/useOrders";
import { PropTypes } from "prop-types";

const OrdersMapViewDrawer = ({ open, children, orderDetailsSection, orderControlsSection }) => {
  const { isMobile } = useDeviceType();

  const [{ selectedOrder }, { onDeselectOrder }, { isOrderSelected }] = useOrders();

  return (
    <DetailsDrawer
      id={`order-details-drawer-${selectedOrder.reference || ""}`}
      className="col-md-12"
      open={open || isOrderSelected}
      focus={isOrderSelected}
      openInner={isOrderSelected}
      onClose={onDeselectOrder}
      contentSection={children}
      contentInnerSection={orderDetailsSection}
      contentControlsSection={isMobile && orderControlsSection}
    />
  );
};

OrdersMapViewDrawer.defaultProps = {
  orderControlsSection: null,
};

OrdersMapViewDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  orderDetailsSection: PropTypes.node.isRequired,
  orderControlsSection: PropTypes.node,
};

export default OrdersMapViewDrawer;
