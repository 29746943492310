import { useCallback } from "react";
import Cookies from "universal-cookie";

const useCookie = () => {
  const cookies = new Cookies();

  const getCookie = useCallback(
    (key) => {
      const value = cookies.get(key);
      return value ? JSON.parse(value) : null;
    },
    [cookies]
  );
  const setCookie = useCallback((key, value) => cookies.set(key, JSON.stringify(value)), []);
  const removeCookie = useCallback((key) => cookies.remove(key), []);

  return [getCookie, setCookie, removeCookie];
};

export default useCookie;
