import OverdueIcon from "@mui/icons-material/Block";
import PaidIcon from "@mui/icons-material/CheckRounded";
import DeletedIcon from "@mui/icons-material/CloseRounded";
import EmailedIcon from "@mui/icons-material/MoreHorizRounded";
import InactiveIcon from "@mui/icons-material/PriorityHighRounded";
import { DELETED_STATUS, EMAILED_STATUS, OVERDUE_STATUS, PAID_STATUS } from "../../../constants";

export const getInvoiceStatusIcon = (status) => {
  switch (status) {
    case OVERDUE_STATUS:
      return OverdueIcon;
    case EMAILED_STATUS:
      return EmailedIcon;
    case PAID_STATUS:
      return PaidIcon;
    case DELETED_STATUS:
      return DeletedIcon;
    default:
      return InactiveIcon;
  }
};

export const getInvoiceStatusColor = (status) => {
  switch (status) {
    case EMAILED_STATUS:
      return "warning";
    case PAID_STATUS:
      return "primary";
    case DELETED_STATUS:
    case OVERDUE_STATUS:
      return "error";
    default:
      return "info";
  }
};
