import ItemDetailsLayout from "library/Layouts/DetailsLayout/ItemDetailsLayout";
import { PropTypes } from "prop-types";

const InvoiceDetailsLayout = ({
  statusBarSection,
  briefInfoSection,
  extraInfoSection,
  linesSection,
  priceSection,
  controlsSection,
}) => (
  <ItemDetailsLayout
    statusBarSection={statusBarSection}
    briefInfoSection={briefInfoSection}
    extraInfoSection={extraInfoSection}
    listSection={linesSection}
    controlsSection={
      <div className="d-flex flex-column col px-0">
        <div className="d-flex flex-column align-items-end col px-0">{priceSection}</div>

        <div className="d-flex justify-content-between">{controlsSection}</div>
      </div>
    }
  />
);

InvoiceDetailsLayout.propTypes = {
  statusBarSection: PropTypes.node.isRequired,
  briefInfoSection: PropTypes.node.isRequired,
  extraInfoSection: PropTypes.node.isRequired,
  linesSection: PropTypes.node.isRequired,
  priceSection: PropTypes.node.isRequired,
  controlsSection: PropTypes.node.isRequired,
};

export default InvoiceDetailsLayout;
