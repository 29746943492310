import { cx } from "@emotion/css";
import PageTitle from "library/Atoms/Text/PageTitle";
import { PropTypes } from "prop-types";

const PageHeaderLayout = ({ className, contentClassName, title, subtitle, headerControls, ...restProps }) => (
  <div className={cx(className, "d-flex flex-column")} {...restProps}>
    <PageTitle>{title}</PageTitle>

    <div className={cx(contentClassName, "d-flex justify-content-between gap-3")}>
      <p className="mb-0">{subtitle}</p>

      {headerControls}
    </div>
  </div>
);

PageHeaderLayout.defaultProps = {
  className: "",
  contentClassName: "",
  title: "",
  subtitle: "",
  headerControls: null,
};

PageHeaderLayout.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerControls: PropTypes.node,
};

export default PageHeaderLayout;
