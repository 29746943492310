import "utility/validation";
import * as yup from "yup";

export default yup.object().shape({
  firstName: yup.string().min(4, "common.forms.firstNameLengthError").required("common.forms.firstNameRequiredError"),
  lastName: yup.string().required("common.forms.lastNameRequiredError"),
  password: yup
    .string()
    .min(8, "register.forms.registerForm.passwordLengthError")
    .matches(/.*[A-Z]/, "register.forms.registerForm.passwordUppercaseError")
    .matches(/.*[a-z]/, "register.forms.registerForm.passwordLowercaseError")
    .matches(/.*[0-9]/, "register.forms.registerForm.passwordNumberError")
    .matches(/.*[$&+,:;=?@#|'<>.^*()%!-]/, "register.forms.registerForm.passwordSpecialCharacterError")
    .required("register.forms.registerForm.passwordRequiredError"),
  email: yup.string().email("common.forms.emailFormatError").required("common.forms.emailRequiredError"),
  invoiceEmail: yup.string().email("common.forms.emailFormatError").required("common.forms.invoiceEmailRequiredError"),
  phone: yup.string().phone("common.forms.phoneInvalidError").required("common.forms.phoneRequiredError"),
  city: yup.string().required("common.forms.cityRequiredError"),
  company: yup.string().required("common.forms.companyNameRequiredError"),
  companyVat: yup.string(),
  companyAddress: yup.string().required("common.forms.companyAddressRequiredError"),
  companyBillingAddress: yup.string(),
  acceptTerms: yup.boolean().oneOf([true], "register.forms.registerForm.acceptPolicyError"),
});
