import PropTypes from "prop-types";
import { useCallback } from "react";
import { useBlocker, useLocation } from "react-router-dom";

const Prompt = ({ when, children }) => {
  const location = useLocation();

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (window.location.pathname !== location.pathname) return false;
    if (!when) return false;
    if (currentLocation.pathname === nextLocation.pathname) return false;
    return true;
  });

  const onLeave = useCallback(() => blocker.proceed(), [blocker]);
  const onStay = useCallback(() => blocker.reset(), [blocker]);

  return blocker.state === "blocked" && children({ onLeave, onStay });
};

Prompt.propTypes = {
  when: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
};

export default Prompt;
