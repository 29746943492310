import ChooseAddressForm from "features/ChooseAddressForm";
import { useFormikContext } from "formik";
import { keys, pick } from "lodash";
import TaskFormLayout from "pages/NewOrder/layouts/FormLayout/TaskFormLayout/TaskFormLayout";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_LOCATION_DATA } from "../../../../../constants";

const PickUpForm = () => {
  const { t } = useTranslation();

  const {
    values: { pick_up },
    setFieldValue,
  } = useFormikContext();

  const onChangePickUpAddress = useCallback((address) => setFieldValue("pick_up", address), [setFieldValue]);

  return (
    <TaskFormLayout
      titleSection={t("newOrder.forms.pickUpForm.formTitle")}
      addressFormSection={
        <ChooseAddressForm
          name="pick_up"
          className="col-12 px-0"
          label={t("newOrder.forms.pickUpForm.formTitle").toLocaleLowerCase()}
          address={pick(pick_up, keys(DEFAULT_LOCATION_DATA))}
          onAddressChanged={onChangePickUpAddress}
          required
        />
      }
      data-cy="pick-up-form"
    />
  );
};

export default PickUpForm;
