import { cx } from "@emotion/css";
import { InfoWindow, Marker } from "@react-google-maps/api";
import MarkerIcon from "assets/svg/markers/default-marker.svg";
import DropMarkerIcon from "assets/svg/markers/drop-marker.svg";
import PickUpMarkerIcon from "assets/svg/markers/pick-up-marker.svg";
import useOpenClose from "hooks/useOpenClose";
import { values } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PositionType } from "types/helperTypes/MapMarker";
import styles from "./styles";

const MARKER_TYPES = { default: "default", pickUp: "pick-up", drop: "drop", custom: "custom" };

const MapMarker = ({
  id,
  position,
  label,
  tooltip,
  onClick,
  onDrag,
  clusterer,
  variant,
  icon,
  draggable,
  ...restProps
}) => {
  const [currPosition, setCurrPosition] = useState(position);

  const [isTooltipOpen, openTooltip, closeTooltip] = useOpenClose(false);

  const iconUrl = useMemo(() => {
    switch (variant) {
      case MARKER_TYPES.custom:
        return icon || MarkerIcon;
      case MARKER_TYPES.pickUp:
        return PickUpMarkerIcon;
      case MARKER_TYPES.drop:
        return DropMarkerIcon;
      default:
        return MarkerIcon;
    }
  }, [icon, variant]);

  const handleClick = useCallback(() => {
    onClick?.(id);
    tooltip && openTooltip();
  }, [id, openTooltip, onClick, tooltip]);

  const handleDragEnd = useCallback(
    ({ latLng }) => {
      const newPosition = { lat: latLng.lat(), lng: latLng.lng() };
      setCurrPosition(newPosition);
      onDrag?.(newPosition);
    },
    [onDrag]
  );

  useEffect(() => {
    setCurrPosition(position);
  }, [position]);

  return (
    <Marker
      icon={{ scaledSize: { height: 35.5, width: 31 }, url: iconUrl }}
      position={currPosition}
      label={
        label
          ? {
              className: cx(
                "position-absolute d-flex justify-content-center align-items-center bg-color-primary",
                styles.badge
              ),
              text: label,
            }
          : { text: " " }
      }
      onClick={handleClick}
      onDragEnd={handleDragEnd}
      clusterer={clusterer}
      draggable={draggable}
      {...restProps}
    >
      {isTooltipOpen && tooltip && (
        <InfoWindow position={currPosition} onCloseClick={closeTooltip}>
          <div className="d-flex flex-row text-color-secondary text-fw-semibold text-fs-10">{tooltip}</div>
        </InfoWindow>
      )}
    </Marker>
  );
};

MapMarker.defaultProps = {
  id: null,
  label: null,
  tooltip: null,
  onClick: () => {},
  onDrag: () => {},
  clusterer: null,
  variant: MARKER_TYPES.default,
  icon: null,
  draggable: false,
};

MapMarker.propTypes = {
  id: PropTypes.string,
  position: PositionType.isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  onDrag: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  clusterer: PropTypes.any,
  variant: PropTypes.oneOf(values(MARKER_TYPES)),
  icon: PropTypes.string,
  draggable: PropTypes.bool,
};

export default MapMarker;
