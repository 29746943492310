import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import TextButton from "library/Atoms/Button/TextButton";
import { PropTypes } from "prop-types";

const Breadcrumbs = ({ data, step }) => (
  <MuiBreadcrumbs separator="›">
    {data.map(({ id, label, onClick }, index) => (
      <TextButton
        key={id}
        id={id}
        className="px-2"
        label={label}
        onClick={onClick}
        disabled={step < index}
        size="small"
      />
    ))}
  </MuiBreadcrumbs>
);

Breadcrumbs.defaultProps = {
  data: [],
  step: 0,
};

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, onClick: PropTypes.func })),
  step: PropTypes.number,
};

export default Breadcrumbs;
