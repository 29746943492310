import useOpenClose from "hooks/useOpenClose";
import DefaultModal from "library/Molecules/Modal/DefaultModal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSelectedAddress, retrieveSelectedAddressesStatus } from "store/features/library.store";
import LibraryConfirmResetModal from "../../LibraryConfirmResetModal/LibraryConfirmResetModal";

const AddressSettingsModal = ({ onClose, children, isEdit }) => {
  const { t } = useTranslation();

  const { id } = useSelector(retrieveSelectedAddress);
  const { editing } = useSelector(retrieveSelectedAddressesStatus);

  const [isResetModalOpen, openResetModal, closeResetModal] = useOpenClose(false);

  return (
    <DefaultModal
      id={isEdit ? `edit-address-${id}-modal` : "add-address-modal"}
      title={t("library.modals.addressSettingsModal.title", { context: isEdit ? "edit" : "add" })}
      modalClassName="d-flex flex-column"
      onClose={editing ? openResetModal : onClose}
      size="big"
    >
      {children}

      {isResetModalOpen && <LibraryConfirmResetModal onConfirm={onClose} onClose={closeResetModal} isAddress />}
    </DefaultModal>
  );
};

AddressSettingsModal.defaultProps = {
  isEdit: false,
};

AddressSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isEdit: PropTypes.bool,
};

export default AddressSettingsModal;
