import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import PageHeaderLayout from "library/Layouts/PageHeaderLayout";
import { size } from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveLibraryListType, retrieveSavedAddresses } from "store/features/library.store";
import { retrieveContactLocalization } from "store/features/localization.store";
import { LIBRARY_ITEM_TYPES, LIBRARY_STORAGE_LIMIT } from "../../../../constants";

const LibraryHeader = ({ onAddAddress }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const listType = useSelector(retrieveLibraryListType);

  const city = useSelector(retrieveContactLocalization);
  const addressesList = useSelector(retrieveSavedAddresses);

  const shouldShowAddressType = useMemo(() => listType === LIBRARY_ITEM_TYPES.addresses, [listType]);

  const onMoveToNewOrder = useCallback(() => navigate("/new-order"), [navigate]);

  return (
    <PageHeaderLayout
      contentClassName="flex-column"
      title={t("library.pageTitle")}
      subtitle={t("library.pageDescription")}
      headerControls={
        <PrimaryButton
          id="page-header-btn"
          className="w-100"
          label={shouldShowAddressType ? t("library.addAddressBtn") : t("common.buttons.newOrderBtn")}
          onClick={shouldShowAddressType ? onAddAddress : onMoveToNewOrder}
          disabled={shouldShowAddressType && (size(addressesList) >= LIBRARY_STORAGE_LIMIT || city?.disabled)}
        />
      }
      data-cy="library-header"
    />
  );
};

LibraryHeader.propTypes = {
  onAddAddress: PropTypes.func.isRequired,
};

export default LibraryHeader;
