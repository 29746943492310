import { createSlice } from "@reduxjs/toolkit";
import {
  deleteSavedOrderRequest,
  editSavedOrderRequest,
  fetchSavedOrdersRequest,
  saveOrderRequest,
} from "store/requests/library.requests";

export const savedOrderSlice = createSlice({
  name: "savedOrder",
  initialState: {
    selectedSavedOrder: null,
    savedOrders: [],
    status: "idle",
    orderStatus: "idle",
  },
  reducers: {
    setSelectedSavedOrder: (state, { payload }) => {
      state.selectedSavedOrder = payload;
    },
    resetSelectedSavedOrder: (state) => {
      state.selectedSavedOrder = null;
    },
    setEditSavedOrderStatus: (state) => {
      state.orderStatus = "editing";
    },
    resetSavedOrderStatus: (state) => {
      state.orderStatus = "idle";
    },
  },
  extraReducers: {
    [saveOrderRequest.fulfilled]: (state) => {
      state.status = "idle";
    },
    [fetchSavedOrdersRequest.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSavedOrdersRequest.fulfilled]: (state, { payload: { data } }) => {
      state.status = "succeeded";
      state.savedOrders = data;
    },
    [fetchSavedOrdersRequest.rejected]: (state) => {
      state.status = "failed";
    },
    [deleteSavedOrderRequest.pending]: (state) => {
      state.orderStatus = "loading";
    },
    [deleteSavedOrderRequest.fulfilled]: (state) => {
      const id = state.selectedSavedOrder;

      state.selectedSavedOrder = null;
      state.savedOrders = state.savedOrders.filter(({ id: orderId }) => orderId !== id);
      state.orderStatus = "idle";
    },
    [deleteSavedOrderRequest.rejected]: (state) => {
      state.orderStatus = "failed";
    },
    [editSavedOrderRequest.fulfilled]: (state, { payload: { data } }) => {
      state.orderStatus = "idle";
      state.savedOrders = state.savedOrders.map((order) => (data.id === order.id ? data : order));
    },
    [editSavedOrderRequest.rejected]: (state) => {
      state.orderStatus = "failed";
    },
  },
});

export const { setSelectedSavedOrder, resetSelectedSavedOrder, setEditSavedOrderStatus, resetSavedOrderStatus } =
  savedOrderSlice.actions;

export default savedOrderSlice.reducer;
