import { useFormikContext } from "formik";
import useDebounce from "hooks/useDebounce";
import Autocomplete from "library/Atoms/Autocomplete";
import { size } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSavedOrders } from "store/features/library.store";

const ApplySavedOrderForm = () => {
  const { t } = useTranslation();
  const savedOrders = useSelector(retrieveSavedOrders);

  const { setValues, handleReset } = useFormikContext();

  const [suggestions, setSuggestions] = useState([]);

  const autocompleteRef = useRef();

  const defaultOrdersToImport = useMemo(() => {
    return savedOrders.map(({ id, label: orderLabel, ...restProps }) => {
      return { id, label: orderLabel, data: { id, label: orderLabel, ...restProps }, isDefault: true };
    });
  }, [savedOrders]);

  const onSelect = useCallback(
    (id) => {
      const { label, ...order } = savedOrders.find(({ id: orderId }) => orderId === id);
      setValues({ label, order, file: null });
    },
    [savedOrders, setValues]
  );

  const onSearch = useCallback(
    (searchTerm) => {
      setSuggestions(
        defaultOrdersToImport.filter(({ label: optionLabel }) =>
          optionLabel.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    },
    [defaultOrdersToImport]
  );
  const debouncedSearch = useDebounce(onSearch, 750);

  useEffect(() => {
    if (size(defaultOrdersToImport) === 0) return;
    setSuggestions(defaultOrdersToImport);
  }, [defaultOrdersToImport]);

  useEffect(() => {
    if (!autocompleteRef.current.closeDropdown) return () => {};
    const { closeDropdown } = autocompleteRef.current;
    const scroll = document.querySelector("#import-order-form-scroll");
    scroll.addEventListener("scroll", closeDropdown);
    return () => scroll.removeEventListener("scroll", closeDropdown);
  }, [handleReset]);

  return (
    <Autocomplete
      ref={autocompleteRef}
      id="library-autocomplete"
      name="label"
      dropdownClassName="position-fixed"
      label={t("newOrder.forms.importForm.importLabel")}
      placeholder={t("newOrder.forms.importForm.importPlaceholder")}
      options={suggestions}
      onChange={debouncedSearch}
      onSelect={onSelect}
      onReset={handleReset}
      disabled={!size(savedOrders)}
    />
  );
};

export default ApplySavedOrderForm;
