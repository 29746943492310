import { cx } from "@emotion/css";
import {
  GridViewOutlined as DashboardIcon,
  CalculateOutlined as FastQuoteIcon,
  RequestPageOutlined as InvoicesIcon,
  StarBorderRounded as LibraryIcon,
  Logout as LogoutIcon,
  AddBoxOutlined as NewOrderIcon,
  PersonOutlineOutlined as ProfileIcon,
  Translate as TranslateIcon,
} from "@mui/icons-material";
import { Badge, Tab, Tabs } from "@mui/material";
import withDefaultDrawerPosition from "features/SideMenu/withDefaultDrawerPosition";
import useDeviceType from "hooks/useDeviceType";
import useOpenClose from "hooks/useOpenClose";
import useToggleMenu from "hooks/useToggleMenu";
import Drawer from "library/Atoms/Drawer";
import Flag from "library/Atoms/Flag";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { retrieveContact } from "store/features/contact.store";
import { retrieveContactLocalization } from "store/features/localization.store";
import { signOutRequest } from "store/requests/contact.requests";
import LanguageSwitcher from "../LanguageSwitcher";
import FastQuoteForm from "./components/FastQuoteForm";
import SideMenuTopPart from "./components/SideMenuTopPart";
import DrawerContentLayout from "./layouts/DrawerContentLayout";
import SideMenuLayout from "./layouts/SideMenuLayout";
import FastQuoteModal from "./modals/FastQuoteModal";
import styles from "./styles";

const SideMenu = ({ defaultPosition, children }) => {
  const { t, i18n } = useTranslation();
  const { isDesktop } = useDeviceType();
  const location = useLocation();
  const dispatch = useDispatch();

  const contact = useSelector(retrieveContact);
  const city = useSelector(retrieveContactLocalization);

  const [isDrawerOpen, openDrawer, closeDrawer] = useOpenClose(defaultPosition);

  const [isFastQuoteOpen, openFastQuote, closeFastQuote] = useOpenClose(false);

  const [{ anchorEl: anchorLanguageMenuEl, isOpen: isLanguageMenuOpen }, onOpenLanguageMenu, onCloseLanguageMenu] =
    useToggleMenu(false);

  const onSignOut = useCallback(() => dispatch(signOutRequest()), [dispatch]);

  useEffect(() => closeFastQuote(), [location.pathname]);

  return (
    <SideMenuLayout
      topPartSection={<SideMenuTopPart onToggleDrawer={isDrawerOpen ? closeDrawer : openDrawer} open={isDrawerOpen} />}
      drawerSection={
        <Drawer
          open={isDrawerOpen}
          className={styles.muiDrawer}
          variant={!isDesktop ? "temporary" : "permanent"}
          contentClassName={cx(styles.muiDrawerContainer(!isDesktop), "position-sticky")}
          hideBackdrop
        >
          <DrawerContentLayout
            topPartSection={
              <SideMenuTopPart onToggleDrawer={isDrawerOpen ? closeDrawer : openDrawer} open={isDrawerOpen} />
            }
            tabsSection={
              <Tabs className="col-12 px-0" orientation="vertical" value={location.pathname}>
                <Tab
                  className="text-left"
                  to="/"
                  value="/"
                  label={isDrawerOpen && <span className="w-100 text-truncate">{t("seo.pages.dashboard")}</span>}
                  icon={<DashboardIcon />}
                  component={Link}
                  iconPosition="start"
                />

                <Tab
                  className="text-left"
                  to="/profile"
                  value="/profile"
                  label={isDrawerOpen && <span className="w-100 text-truncate">{t("seo.pages.profile")}</span>}
                  icon={<ProfileIcon />}
                  component={Link}
                  iconPosition="start"
                />

                <Tab
                  className="text-left"
                  to="/new-order"
                  value="/new-order"
                  label={isDrawerOpen && <span className="w-100 text-truncate">{t("seo.pages.newOrder")}</span>}
                  icon={<NewOrderIcon />}
                  component={Link}
                  iconPosition="start"
                  disabled={city?.disabled}
                />

                <Tab
                  className="text-left"
                  to="/library"
                  value="/library"
                  label={isDrawerOpen && <span className="w-100 text-truncate">{t("seo.pages.library")}</span>}
                  icon={<LibraryIcon />}
                  component={Link}
                  iconPosition="start"
                />

                <Tab
                  className="text-left"
                  to="/invoices"
                  value="/invoices"
                  label={isDrawerOpen && <span className="w-100 text-truncate">{t("seo.pages.invoices")}</span>}
                  icon={
                    <Badge
                      badgeContent={
                        contact.activeInvoices ? (
                          <span className="text-color-white text-fw-semibold">{contact.activeInvoices}</span>
                        ) : null
                      }
                      color="warning"
                    >
                      <InvoicesIcon />
                    </Badge>
                  }
                  component={Link}
                  iconPosition="start"
                />
              </Tabs>
            }
            fastQuoteTabSection={
              <Tab
                id="fast-quote-toggler"
                className="text-left"
                label={
                  isDrawerOpen && (
                    <span className="w-100 text-truncate text-fw-normal">{t("features.sideMenu.tabs.fastQuote")}</span>
                  )
                }
                icon={
                  <span className="text-fs-28">
                    <FastQuoteIcon fontSize="inherit" />
                  </span>
                }
                iconPosition="start"
                onClick={openFastQuote}
                disabled={location.pathname === "/new-order" || city?.disabled}
              />
            }
            settingsSection={
              <>
                <Tab
                  id="side-menu-language-switcher"
                  className="text-left"
                  label={
                    isDrawerOpen && (
                      <span className="w-100 text-truncate text-fw-normal">{t("features.sideMenu.tabs.language")}</span>
                    )
                  }
                  icon={
                    <Badge badgeContent={<Flag locale={i18n.language} />} color="primary">
                      <TranslateIcon />
                    </Badge>
                  }
                  iconPosition="start"
                  onClick={onOpenLanguageMenu}
                />

                <Tab
                  className="text-left"
                  label={
                    isDrawerOpen && (
                      <span className="w-100 text-truncate text-color-primary text-fw-normal">
                        {t("common.buttons.logoutBtn")}
                      </span>
                    )
                  }
                  icon={<LogoutIcon color="primary" />}
                  iconPosition="start"
                  onClick={onSignOut}
                />
              </>
            }
            open={isDrawerOpen}
          />
        </Drawer>
      }
      pageSection={children}
      modals={
        <>
          {isFastQuoteOpen && (
            <FastQuoteModal onClose={closeFastQuote}>
              <FastQuoteForm />
            </FastQuoteModal>
          )}

          {isLanguageMenuOpen && <LanguageSwitcher anchorEl={anchorLanguageMenuEl} onClose={onCloseLanguageMenu} />}
        </>
      }
    />
  );
};

SideMenu.propTypes = {
  defaultPosition: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default withDefaultDrawerPosition(SideMenu);
