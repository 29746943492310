import DefaultModal from "library/Molecules/Modal/DefaultModal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSelectedSavedOrder } from "store/features/library.store";

const ImportOrderModal = ({ onClose, children }) => {
  const { t } = useTranslation();

  const { id } = useSelector(retrieveSelectedSavedOrder);

  return (
    <DefaultModal
      id={`import-order-${id}-modal`}
      title={t("newOrder.modals.importOrderModal.title")}
      modalClassName="d-flex flex-column"
      onClose={onClose}
      size="big"
    >
      {children}
    </DefaultModal>
  );
};

ImportOrderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ImportOrderModal;
