import { deDE, enUS } from "@mui/material/locale";
import { de, enGB } from "date-fns/locale";
import { DEFAULT_LOCALE } from "../../constants";

export const getMuiLocale = (lang) => {
  const dateMuiLocales = { en: enUS, de: deDE };
  return dateMuiLocales[lang];
};

export const getDateFnsLocale = () => {
  const dateFnsLocales = { en: enGB, de };
  return dateFnsLocales[localStorage.getItem("i18nextLng") || DEFAULT_LOCALE];
};
