import PageTitle from "library/Atoms/Text/PageTitle";
import { PropTypes } from "prop-types";

const AuthPageLayout = ({ headerSection, titleSection, formDescriptionSection, footerSection, children }) => (
  <div className="d-flex justify-content-center col px-0 h-100">
    <div className="d-flex flex-column align-items-center col-md-6 col-10 px-0">
      <div className="d-flex justify-content-between align-items-center align-self-stretch my-5">{headerSection}</div>

      <div className="d-flex align-items-center justify-content-center col px-0">
        <div className="d-flex flex-column col-xl-8 col-lg-10 col-md-11 col-12 px-0">
          <PageTitle>
            <span className="text-fs-20">{titleSection}</span>
          </PageTitle>

          <p className="mb-5 text-fs-12">{formDescriptionSection}</p>

          {children}
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center my-5 gap-5">
        <div className="d-flex gap-5 text-fs-12 text-fw-semibold">{footerSection}</div>

        <span className="text-fs-10 text-color-grey400 text-center">© 2020 by Pedivan Sustainable Delivery Ltd</span>
      </div>
    </div>
  </div>
);

AuthPageLayout.propTypes = {
  headerSection: PropTypes.node.isRequired,
  titleSection: PropTypes.node.isRequired,
  formDescriptionSection: PropTypes.node.isRequired,
  footerSection: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthPageLayout;
