import useOpenClose from "hooks/useOpenClose";
import SEO from "library/Molecules/SEO";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAddressListType } from "store/features/library.store";
import AddressSettingsForm from "./components/AddressSettingsForm/AddressSettingsForm";
import LibraryHeader from "./components/LibraryHeader/LibraryHeader";
import LibraryList from "./components/LibraryList/LibraryList";
import LibraryPageLayout from "./layouts/LibraryPageLayout/LibraryPageLayout";
import AddressDetailsDrawer from "./modals/LibraryItemDetailsDrawer/AddressDetailsDrawer/AddressDetailsDrawer";
import OrderDetailsDrawer from "./modals/LibraryItemDetailsDrawer/OrderDetailsDrawer/OrderDetailsDrawer";
import AddressSettingsModal from "./modals/LibraryItemSettingsModal/AddressSettingsModal/AddressSettingsModal";

const Library = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isAddAddressModalOpen, openAddAddressModal, closeAddAddressModal] = useOpenClose(false);

  useEffect(() => {
    return () => dispatch(setAddressListType());
  }, [dispatch]);

  return (
    <LibraryPageLayout
      pageHeaderSection={<LibraryHeader onAddAddress={openAddAddressModal} />}
      listSection={<LibraryList />}
      modals={
        <>
          {isAddAddressModalOpen && (
            <AddressSettingsModal onClose={closeAddAddressModal}>
              <AddressSettingsForm onClose={closeAddAddressModal} />
            </AddressSettingsModal>
          )}

          <AddressDetailsDrawer />

          <OrderDetailsDrawer />
        </>
      }
    >
      <SEO title={t("seo.helmetTitle", { name: t("seo.pages.library") })} />
    </LibraryPageLayout>
  );
};

export default Library;
