import { PropTypes } from "prop-types";

const SetDeliveryFormLayout = ({
  pageHeaderSection,
  dateFormSection,
  pickUpFormSection,
  dropOffFormsSection,
  controlsSection,
}) => (
  <>
    {pageHeaderSection}

    {dateFormSection}
    {pickUpFormSection}
    {dropOffFormsSection}

    {controlsSection}
  </>
);

SetDeliveryFormLayout.defaultProps = {
  pageHeaderSection: null,
  dropOffFormsSection: null,
  controlsSection: null,
};

SetDeliveryFormLayout.propTypes = {
  pageHeaderSection: PropTypes.node,
  dateFormSection: PropTypes.node.isRequired,
  pickUpFormSection: PropTypes.node.isRequired,
  dropOffFormsSection: PropTypes.node,
  controlsSection: PropTypes.node,
};

export default SetDeliveryFormLayout;
