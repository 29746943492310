import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import ModalLayout from "library/Layouts/ModalLayout";
import DefaultModal from "library/Molecules/Modal/DefaultModal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ProofOfDeliveryModal = ({ dropReference, onClose, children }) => {
  const { t } = useTranslation();

  return (
    <DefaultModal
      id={`proof-of-${dropReference}-delivery-modal`}
      title={t("dashboard.modals.proofOfDeliveryModal.title")}
      onClose={onClose}
    >
      <ModalLayout
        contentSection={children}
        modalControls={
          <PrimaryButton
            id="close-proof-of-delivery-modal-btn"
            className="w-100"
            label={t("common.buttons.closeBtn")}
            onClick={onClose}
          />
        }
      />
    </DefaultModal>
  );
};

ProofOfDeliveryModal.propTypes = {
  dropReference: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProofOfDeliveryModal;
