import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { DEFAULT_SEO_DATA } from "../../../constants";

const SEO = ({ title }) => {
  const { i18n } = useTranslation();

  return (
    <Helmet
      htmlAttributes={{ lang: i18n.language }}
      title={title}
      titleTemplate={`%s | ${DEFAULT_SEO_DATA.fullname}`}
      defer={false}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SEO;
