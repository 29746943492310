import * as yup from "yup";

export default yup.object().shape({
  order: yup.object().shape({
    pick_up: yup.object().shape({
      location: yup.string().required("common.forms.locationRequiredError"),
    }),
    drops: yup.array().of(
      yup.object().shape({
        location: yup.string().required("common.forms.locationRequiredError"),
      })
    ),
  }),
});
