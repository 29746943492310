import { Divider } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import ModalLayout from "library/Layouts/ModalLayout";
import { isEmpty, size } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import NewOrderDropDetailsLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderDropDetailsLayout/NewOrderDropDetailsLayout";
import NewOrderSummaryLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderSummaryLayout/NewOrderSummaryLayout";
import ConfirmOverwriteOrderModal from "pages/NewOrder/modals/ConfirmOverwriteOrderModal/ConfirmOverwriteOrderModal";
import NewOrderDetailsSkeleton from "pages/NewOrder/skeletons/NewOrderDetailsSkeleton";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { format } from "utility/date-fns";
import { prettifyPackagesAmount } from "utility/helpers/orders";
import IncreasedPriceMessage from "../NewOrderDetails/IncreasedPriceMessage/IncreasedPriceMessage";
import ApplySavedOrderForm from "./ApplySavedOrderForm/ApplySavedOrderForm";
import ImportCsvQuoteForm from "./ImportCsvQuoteForm/ImportCsvQuoteForm";

const ImportOrderForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();

  const [, { dirty: isNewOrderFormDirty }] = useNewOrderForm();

  const importOrderForm = useFormik({
    initialStatus: null,
    initialValues: { label: "", file: null, order: null },
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });
  const {
    status,
    values: { order, file },
    submitForm,
  } = importOrderForm;
  const { pick_up, startDate, endDate, drops, isExpress } = order || {};

  const [isOverwriteModalOpen, openOverwriteModal, closeOverwtireModal] = useOpenClose(false);

  return (
    <FormikProvider value={importOrderForm}>
      <ModalLayout
        id="import-order-form-scroll"
        contentClassName="pb-md-4 pb-3 importScrollContainer"
        descriptionSection={
          <Trans
            i18nKey="newOrder.forms.importForm.formDescription"
            components={{
              lnk: (
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/pedivan-logistic-admin.appspot.com/o/public%2Fsample.xlsx?alt=media"
                  className="text-color-primary"
                />
              ),
            }}
          />
        }
        contentSection={
          <div className="d-flex flex-wrap">
            <div className="d-flex flex-column col-md-6 col-12 px-0">
              <ApplySavedOrderForm />

              <Divider className="mt-4 mb-3">{t("newOrder.forms.importForm.separatorLabel")}</Divider>

              <ImportCsvQuoteForm />
            </div>

            {status === "loading" ? (
              <NewOrderDetailsSkeleton className="col-12 col-md-6 mt-4 pt-md-3 px-0 pl-md-4 pr-md-0" />
            ) : (
              !isEmpty(order) && (
                <NewOrderSummaryLayout
                  className="mt-4 pt-md-3"
                  contentClassName="mt-1"
                  dateSection={!isEmpty(file) && format(new Date(startDate), "MMM dd, yyyy")}
                  pickUpSection={`${format(new Date(startDate), "HH:mm")} - ${pick_up.location} | ${pick_up.postcode}`}
                  pickUpNotesSection={pick_up.notes}
                  dropsSection={drops.map(({ reference, location, postcode, packages, notes }, dindex) => (
                    <NewOrderDropDetailsLayout
                      key={reference}
                      titleSection={`${t("newOrder.orderDetails.dropOff")} ${size(drops) > 1 ? `#${dindex + 1}` : ""}`}
                      dropAddressSection={`${format(new Date(endDate), "HH:mm")} - ${location} | ${postcode}`}
                      packageTypeSection={prettifyPackagesAmount(packages)}
                      notesSection={notes}
                    />
                  ))}
                  messagesSection={<IncreasedPriceMessage startDate={startDate} isExpress={isExpress} />}
                  collapse={size(drops) > 4}
                />
              )
            )}
          </div>
        }
        modalControls={
          <PrimaryButton
            id="submit-order-import-btn"
            className="w-100"
            label={t("newOrder.modals.importOrderModal.submitBtn")}
            onClick={isNewOrderFormDirty ? openOverwriteModal : submitForm}
            disabled={isEmpty(order)}
          />
        }
        modals={
          isOverwriteModalOpen && <ConfirmOverwriteOrderModal onConfirm={submitForm} onClose={closeOverwtireModal} />
        }
      />
    </FormikProvider>
  );
};

ImportOrderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportOrderForm;
