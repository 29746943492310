import { isAfter } from "date-fns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isValidNumber } from "libphonenumber-js";
import * as yup from "yup";

yup.addMethod(yup.string, "phone", function phone(errorMessage) {
  return this.test(`test-phone`, errorMessage, function testPhone(value) {
    const { path, createError } = this;
    if (!value) return true;

    return (value && isValidNumber(value)) || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.date, "actual", function phone(errorMessage) {
  return this.test(`test-actual`, errorMessage, function testPhone(value) {
    const { path, createError } = this;
    if (!value) return true;
    return isAfter(value, new Date()) || createError({ path, message: errorMessage });
  });
});
