import "utility/validation";
import * as yup from "yup";

export default yup.object().shape({
  label: yup.string().required("common.forms.nameRequiredError"),
  startDate: yup.date().nullable(),
  pick_up: yup.object().shape({
    location: yup.string().required("common.forms.locationRequiredError"),
    recipient: yup.object().shape({
      email: yup.string().email("common.forms.emailFormatError"),
      phone: yup.string().phone("common.forms.phoneInvalidError"),
    }),
  }),
  drops: yup.array().of(
    yup.object().shape({
      location: yup.string().required("common.forms.locationRequiredError"),
      recipient: yup.object().shape({
        email: yup.string().email("common.forms.emailFormatError"),
        phone: yup.string().phone("common.forms.phoneInvalidError"),
      }),
      // recipient: yup.object().shape({
      //   name: yup.string().required("newOrder.forms.dropOffForm.recipientNameRequiredError"),
      //   email: yup.string().email("common.forms.emailFormatError").required("common.forms.emailRequiredError"),
      //   phone: yup.string().phone("common.forms.phoneInvalidError").required("common.forms.phoneRequiredError"),
      // }),
    })
  ),
});
