import { css } from "@emotion/css";

const styles = {
  zoomControls: css`
    right: 15px;
    top: 15px;
    gap: 7px;
  `,
};

export default styles;
