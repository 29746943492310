import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useNewOrderForm from "./hooks/useNewOrderForm";

const withFastQuoteData = (Component) => (props) => {
  const location = useLocation();

  const [, , { setOrderDetails }] = useNewOrderForm();

  useEffect(() => {
    if (!location.state) return;
    setOrderDetails(location.state);
  }, [location.state, setOrderDetails]);

  return <Component {...props} />;
};

export default withFastQuoteData;
