import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProvider, useFormik } from "formik";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { updateContactRequest } from "store/requests/contact.requests";
import ContactForm from "./ContactForm/ContactForm";
import InvoiceForm from "./InvoiceForm/InvoiceForm";
import ProfileFormSchema from "./ProfileForm.validation";

const ProfileForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contact = useSelector(retrieveContact);

  const profileForm = useFormik({
    initialValues: contact,
    onSubmit: (partner, { setSubmitting }) => {
      dispatch(updateContactRequest(partner))
        .then(unwrapResult)
        .then(() => setSubmitting(false))
        .catch(() => setSubmitting(false));
    },
    validationSchema: ProfileFormSchema,
    validateOnMount: true,
  });
  const { submitForm, isValid, isSubmitting, dirty: isFormDirty } = profileForm;

  return (
    <FormikProvider value={profileForm}>
      <ContactForm />

      <InvoiceForm />

      <div className="d-flex flex-sm-wrap flex-wrap-reverse justify-content-center justify-content-sm-end col-12 mt-5 px-0">
        <PrimaryButton
          className="col-12 col-sm-5 col-md-3"
          label={t("common.buttons.updateBtn")}
          onClick={submitForm}
          loading={isSubmitting}
          disabled={!isValid || isSubmitting || !isFormDirty}
        />
      </div>
    </FormikProvider>
  );
};

export default ProfileForm;
