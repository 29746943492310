import { unwrapResult } from "@reduxjs/toolkit";
import useOpenClose from "hooks/useOpenClose";
import ConfirmationModal from "library/Molecules/Modal/ConfirmationModal";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { reacceptContactTemrsRequest, signOutRequest } from "store/requests/contact.requests";

const ReacceptTermsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, startLoading, stopLoading] = useOpenClose(false);

  const onSignOut = useCallback(() => dispatch(signOutRequest()), [dispatch]);
  const onReacceptTerms = useCallback(() => {
    startLoading();

    dispatch(reacceptContactTemrsRequest())
      .then(unwrapResult)
      .then(() => stopLoading());
  }, [dispatch, startLoading, stopLoading]);

  return (
    <ConfirmationModal
      id="reaccept-terms-modal"
      title={t("features.reacceptTermsModal.title")}
      description={
        <Trans
          i18nKey="features.reacceptTermsModal.description"
          components={{
            lnk: <a href="https://www.pedivan.com/terms-and-conditions" className="text-color-primary" />,
          }}
        />
      }
      confirmBtnText={t("common.buttons.logoutBtn")}
      declineBtnText={t("features.reacceptTermsModal.acceptBtn")}
      onConfirm={onSignOut}
      onDecline={onReacceptTerms}
      isDeclineProcessing={loading}
      closable={false}
    />
  );
};

export default ReacceptTermsModal;
