import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import NewOrderDetailLayout from "../NewOrderDetailLayout/NewOrderDetailLayout";

const NewOrderDropDetailsLayout = ({ titleSection, dropAddressSection, packageTypeSection, notesSection }) => {
  const { t } = useTranslation();

  return (
    <>
      <NewOrderDetailLayout titleSection={titleSection} contentSection={dropAddressSection} />

      <div className="d-flex flex-row flex-wrap col-12 px-0">
        <NewOrderDetailLayout titleSection={t("newOrder.orderDetails.packages")} contentSection={packageTypeSection} />

        {notesSection && (
          <NewOrderDetailLayout titleSection={t("newOrder.orderDetails.note")} contentSection={notesSection} />
        )}
      </div>
    </>
  );
};

NewOrderDropDetailsLayout.defaultProps = {
  notesSection: null,
};

NewOrderDropDetailsLayout.propTypes = {
  titleSection: PropTypes.node.isRequired,
  dropAddressSection: PropTypes.node.isRequired,
  packageTypeSection: PropTypes.node.isRequired,
  notesSection: PropTypes.node,
};

export default NewOrderDropDetailsLayout;
