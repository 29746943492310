import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";
import styles from "./styles";

const ScrollLayout = ({ className, children, ...restProps }) => (
  <div className={cx(className, styles.layout, "pl-1 pr-3")} {...restProps}>
    {children}
  </div>
);

ScrollLayout.defaultProps = {
  className: "",
};

ScrollLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ScrollLayout;
