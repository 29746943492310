import { createSlice } from "@reduxjs/toolkit";
import {
  createNewAddressRequest,
  deleteAddressRequest,
  editAddressRequest,
  fetchAddressesRequest,
} from "../requests/library.requests";

export const addressSlice = createSlice({
  name: "address",
  initialState: {
    selectedAddress: null,
    savedAddresses: [],
    status: "idle",
    addressStatus: "idle",
  },
  reducers: {
    setSelectedAddress: (state, { payload }) => {
      state.selectedAddress = payload;
    },
    resetSelectedAddress: (state) => {
      state.selectedAddress = null;
    },
    setEditAddressStatus: (state) => {
      state.addressStatus = "editing";
    },
    resetAddressStatus: (state) => {
      state.addressStatus = "idle";
    },
  },
  extraReducers: {
    [fetchAddressesRequest.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAddressesRequest.fulfilled]: (state, { payload: { data } }) => {
      state.status = "succeeded";
      state.savedAddresses = data;
    },
    [fetchAddressesRequest.rejected]: (state) => {
      state.status = "failed";
    },
    [createNewAddressRequest.pending]: (state) => {
      state.addressStatus = "loading";
    },
    [createNewAddressRequest.fulfilled]: (state, { payload: { data } }) => {
      state.addressStatus = "idle";
      state.savedAddresses = [...state.savedAddresses, data];
    },
    [createNewAddressRequest.rejected]: (state) => {
      state.addressStatus = "failed";
    },
    [deleteAddressRequest.pending]: (state) => {
      state.addressStatus = "loading";
    },
    [deleteAddressRequest.fulfilled]: (state) => {
      const id = state.selectedAddress;

      state.selectedAddress = null;
      state.savedAddresses = state.savedAddresses.filter(({ id: addressId }) => addressId !== id);
      state.addressStatus = "idle";
    },
    [deleteAddressRequest.rejected]: (state) => {
      state.addressStatus = "failed";
    },
    [editAddressRequest.pending]: (state) => {
      state.addressStatus = "loading";
    },
    [editAddressRequest.fulfilled]: (state, { payload: { data } }) => {
      state.addressStatus = "idle";
      state.savedAddresses = state.savedAddresses.map((address) => (data.id === address.id ? data : address));
    },
    [editAddressRequest.rejected]: (state) => {
      state.addressStatus = "failed";
    },
  },
});

export const { setSelectedAddress, resetSelectedAddress, setEditAddressStatus, resetAddressStatus } =
  addressSlice.actions;

export default addressSlice.reducer;
