import useToast from "hooks/useToast";
import { memoize } from "lodash";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  removeErrorMessage,
  removeSuccessMessage,
  retrieveErrorMessages,
  retrieveSuccessMessages,
} from "store/features/toast.store";

const useApiResponseHandler = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const onShowToast = useToast();

  const successMessages = useSelector(retrieveSuccessMessages);
  const errorMessages = useSelector(retrieveErrorMessages);

  const onCloseSuccessMessage = useCallback((id) => dispatch(removeSuccessMessage(id)), [dispatch]);
  const onCloseErrorMessage = useCallback((id) => dispatch(removeErrorMessage(id)), [dispatch]);

  useEffect(() => {
    successMessages.forEach(({ id, key }) => {
      if (i18n.exists(`api:${key}`)) {
        onShowToast(t(`api:${key}`), { id, type: "success", onClose: memoize(onCloseSuccessMessage)(id) });
      } else if (i18n.exists(key)) {
        onShowToast(t(key), { id, type: "success", onClose: memoize(onCloseSuccessMessage)(id) });
      } else onCloseSuccessMessage(id);
    });
  }, [successMessages, i18n, t, onShowToast, onCloseSuccessMessage]);

  useEffect(() => {
    errorMessages.forEach(({ id, key }) => {
      if (i18n.exists(`api:${key}`)) onShowToast(t(`api:${key}`), { id, onClose: memoize(onCloseErrorMessage)(id) });
      else if (i18n.exists(key)) onShowToast(t(key), { id, onClose: memoize(onCloseErrorMessage)(id) });
      else onShowToast(t("api:500.somethingWentWrong"), { id, onClose: memoize(onCloseErrorMessage)(id) });
    });
  }, [errorMessages, i18n, t, onShowToast, onCloseErrorMessage]);
};

export default useApiResponseHandler;
