import * as Sentry from "@sentry/react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import LocizeBackend from "i18next-locize-backend";
import { isUndefined, keys } from "lodash";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LOCALE, LOCALES_ENUM } from "../constants";

const productionSources = {
  backend: {
    projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
    apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
    referenceLng: localStorage.getItem("i18nextLng") || DEFAULT_LOCALE,
    version: "latest",
  },
};
const localSources = {
  backend: { loadPath: "translations/{{lng}}/{{ns}}.json" },
};

const isProdOrStaging =
  process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging";
const isTesting = !isUndefined(window.Cypress);

const sources = (isTesting && isProdOrStaging) || isProdOrStaging ? productionSources : localSources;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(LanguageDetector)
  .use((isTesting && isProdOrStaging) || isProdOrStaging ? LocizeBackend : HttpBackend)
  .init({
    // ...other options
    // debug: !isProdOrStaging,
    fallbackLng: localStorage.getItem("i18nextLng") || DEFAULT_LOCALE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged editorSaved",
    },
    supportedLngs: keys(LOCALES_ENUM),

    defaultNS: "global",
    ns: ["global", "api"],
    ...sources,
  });

i18n.on("missingKey", (lngs, namespace, key) => {
  console.warn(`Translation key ${key} is missing: namespace ${namespace}`);
  Sentry.withScope((score) => {
    score.setExtras({ lngs, namespace, key });
    Sentry.captureException(new Error(`Translation key ${key} is missing: namespace ${namespace}`));
  });
});

export default i18n;
