import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  layout: css`
    box-shadow: 0px 3px 6px #00000029;
  `,
  selected: css`
    outline: 2px solid ${palette.primary.main};
  `,
  rounded: css`
    border-radius: 12px !important;
  `,
};

export default styles;
