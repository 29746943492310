import useDeviceType from "hooks/useDeviceType";
import Skeleton from "library/Atoms/Skeleton";
import LoadGoogleMap from "library/GoogleMapComponents/LoadGoogleMap";
import Map from "library/GoogleMapComponents/Map";
import MapMarker from "library/GoogleMapComponents/MapMarker";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { retrieveSelectedAddress } from "store/features/library.store";
import { createMarkerPosition } from "utility/helpers/markers";

const AddressMap = () => {
  const { isDesktop } = useDeviceType();

  const { location } = useSelector(retrieveSelectedAddress);

  const mapBounds = useMemo(() => [createMarkerPosition(location.geolocation)], [location]);

  return (
    <LoadGoogleMap loadingComponent={<Skeleton className="h-100" rounded={false} />}>
      <Map className="h-100" bounds={mapBounds} showZoomControls={isDesktop}>
        {() => <MapMarker position={{ lat: location.geolocation[0], lng: location.geolocation[1] }} />}
      </Map>
    </LoadGoogleMap>
  );
};

export default AddressMap;
