import { cx } from "@emotion/css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useDeviceType from "hooks/useDeviceType";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import Chip from "library/Atoms/Chip";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import OrderBriefInfo from "pages/Dashboard/components/OrdersList/OrderItem/OrderBriefInfo/OrderBriefInfo";
import useOrders from "pages/Dashboard/hooks/useOrders";
import OrderDetailsLayout from "pages/Dashboard/layouts/OrderDetailsLayout/OrderDetailsLayout";
import { PropTypes } from "prop-types";
import { useEffect, useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { fetchOrderPositionRequest } from "store/requests/order.requests";
import { format } from "utility/date-fns";
import { convertTimestampToDate } from "utility/helpers/general";
import { calculatePackagesAmount } from "utility/helpers/orders";
import { CLAIMED_STATUS } from "../../../../constants";
import CancelOrderBanner from "./CancelOrderBanner/CancelOrderBanner";
import DropDetails from "./DropDetails/DropDetails";
import styles from "./OrderDetails.styles";
import SaveOrderButton from "./SaveOrderButton/SaveOrderButton";
import StatusBanner from "./StatusBanner/StatusBanner";

const OrderDetails = ({ mapOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useDeviceType();

  const [{ selectedOrder }, , { isOrderSelected }] = useOrders();

  const intervalRef = useRef();

  const shouldShowDiscount = useMemo(() => {
    if (!isOrderSelected) return false;
    return Number(selectedOrder.price.starting_price) - Number(selectedOrder.price.total) > 0;
  }, [isOrderSelected, selectedOrder]);

  useEffect(() => {
    if (!isOrderSelected || selectedOrder.status !== CLAIMED_STATUS) return;

    intervalRef.current = setInterval(() => dispatch(fetchOrderPositionRequest(selectedOrder.id)), 60000);
    dispatch(fetchOrderPositionRequest(selectedOrder.id));
  }, [dispatch, selectedOrder.id, selectedOrder.status, isOrderSelected]);

  useEffect(() => {
    if (!isOrderSelected || selectedOrder.status !== CLAIMED_STATUS) clearInterval(intervalRef.current);
  }, [isOrderSelected, selectedOrder.status]);

  useEffect(() => {
    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  if (!isOrderSelected) return null;
  return (
    <OrderDetailsLayout
      statusBarSection={<StatusBanner id={selectedOrder.reference} status={selectedOrder.status} />}
      briefInfoSection={
        <>
          <OrderBriefInfo
            reference={selectedOrder.reference}
            startDate={selectedOrder.startDate}
            endDate={selectedOrder.endDate}
            packages={calculatePackagesAmount(selectedOrder.drops)}
          />

          {selectedOrder.isExpress && <Chip label={t("common.entities.order.express")} icon={<AccessTimeIcon />} />}
        </>
      }
      extraInfoSection={
        <>
          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.createdAt")}
            contentSection={format(convertTimestampToDate(selectedOrder.created_at), "MMM dd, yyyy HH:mm")}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.updatedAt")}
            contentSection={format(convertTimestampToDate(selectedOrder.updated_at), "MMM dd, yyyy HH:mm")}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.scheduledFor")}
            contentSection={format(convertTimestampToDate(selectedOrder.delivery_date), "MMM dd, yyyy HH:mm")}
          />
        </>
      }
      dropsListSection={selectedOrder.drops?.map(({ reference: dropRef, ...drop }) => (
        <DropDetails
          key={dropRef}
          pick_up={selectedOrder.pick_up}
          drop={{ ...drop, reference: dropRef, currency: selectedOrder.price.currency }}
          rider={selectedOrder.rider}
        />
      ))}
      priceSection={
        <>
          <span className="pr-2">{t("utils.pricing.totalPrice")}:</span>

          <Trans
            i18nKey="utils.pricing.includingVAT"
            context={shouldShowDiscount ? "discount" : ""}
            components={{
              price: (
                <NumberFormat
                  value={selectedOrder.price.total}
                  displayType="text"
                  thousandSeparator
                  prefix={selectedOrder.price.currency}
                />
              ),
              del: <del className="text-fs-12 text-color-error" />,
              discount: (
                <NumberFormat
                  value={selectedOrder.price.starting_price}
                  displayType="text"
                  thousandSeparator
                  prefix={selectedOrder.price.currency}
                />
              ),
            }}
          />
        </>
      }
      controlsSection={
        <>
          {!isMobile && <SaveOrderButton className={styles.closeBtn(isMobile)} />}

          <PrimaryButton
            id="back-to-orders-btn"
            className={cx(styles.closeBtn(isMobile), "w-100")}
            contentClassName="text-truncate text-fs-12"
            label={mapOpen && isMobile ? t("dashboard.order.backToMapBtn") : t("common.buttons.backToOrdersBtn")}
            onClick={onClose}
          />
        </>
      }
      cancelOrderSection={<CancelOrderBanner />}
    />
  );
};

OrderDetails.propTypes = {
  mapOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OrderDetails;
