import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import { PropTypes } from "prop-types";
import styles from "./DashboardLayout.styles";

const DashboardLayout = ({ pageHeaderSection, listSection, mapSection, fabSection, children }) => {
  const { isMobile, isDesktop } = useDeviceType();

  return (
    <div
      className={cx(
        styles.container(!isDesktop),
        isMobile && "container",
        "d-flex flex-1 h-fit flex-wrap align-items-stretch justify-content-end overflow-hidden"
      )}
    >
      <div
        className={cx(
          styles.container(!isDesktop),
          "d-flex flex-column col-md-6 col-12 pt-md-4 pt-2 pb-md-3 pb-2 px-2 px-md-5"
        )}
      >
        {pageHeaderSection}

        {listSection}
      </div>

      <div className="d-flex flex-column col-md-6 col-12 px-0">{mapSection}</div>

      {children}

      {fabSection}
    </div>
  );
};

DashboardLayout.propTypes = {
  pageHeaderSection: PropTypes.node.isRequired,
  listSection: PropTypes.node.isRequired,
  mapSection: PropTypes.node.isRequired,
  fabSection: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
