import AddDropIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { unwrapResult } from "@reduxjs/toolkit";
import ChooseAddressForm from "features/ChooseAddressForm";
import FastQuoteFormLayout from "features/SideMenu/layouts/FastQuoteFormLayout";
import { FormikProvider, useFormik } from "formik";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import Subtitle from "library/Atoms/Subtitle";
import { size } from "lodash";
import { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { calculateOrderPriceRequest } from "store/requests/newOrder.requests";
import { generateNewDrop } from "utility/helpers/orders";
import { DEFAULT_LOCATION_DATA, MAX_DROP_AMOUNT } from "../../../../constants";
import FastQuoteDetails from "./FastQuoteDetails";
import FastQuoteFormSchema from "./validation";

const DEFAULT_PRICE_DATA = { price: 0, currency: null, distance: null };

const FastQuoteForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fastQuoteForm = useFormik({
    initialValues: {
      order: { pick_up: DEFAULT_LOCATION_DATA, drops: [generateNewDrop()] },
      price: DEFAULT_PRICE_DATA,
    },
    validationSchema: FastQuoteFormSchema,
    onSubmit: ({ order }, { setSubmitting, setFieldValue }) => {
      dispatch(calculateOrderPriceRequest({ pick_up: order.pick_up, drops: order.drops, ignoreCommonFees: true }))
        .then(unwrapResult)
        .then((res) => {
          setFieldValue("price", res.data);
          setSubmitting(false);
        })
        .catch(() => setSubmitting(false));
    },
  });
  const {
    values: { order },
    setFieldValue,
    isValid,
    dirty: isFormDirty,
    submitForm,
    isSubmitting,
    setErrors,
  } = fastQuoteForm;
  const { pick_up, drops } = order;

  const onAddNewDrop = useCallback(() => {
    setFieldValue("order.drops", [...drops, generateNewDrop()]);
  }, [setFieldValue, drops]);
  const onRemoveDrop = useCallback(
    (dropIndex) => {
      setFieldValue(
        "order.drops",
        drops.filter((_, index) => index !== dropIndex)
      );
    },
    [setFieldValue, drops]
  );

  const handleChangePickUp = useCallback((address) => setFieldValue("order.pick_up", address), [setFieldValue]);
  const handleChaneDrop = useCallback(
    (address, index) => setFieldValue(`order.drops.${index}`, { ...drops[index], ...address }),
    [setFieldValue, drops]
  );

  const onCreateNewOrder = useCallback(() => navigate("/new-order", { state: { ...order } }), [navigate, order]);

  useEffect(() => {
    setErrors({});
    setFieldValue("price", DEFAULT_PRICE_DATA);
    submitForm();
  }, [setErrors, setFieldValue, submitForm, order]);

  return (
    <FormikProvider value={fastQuoteForm}>
      <FastQuoteFormLayout
        descriptionSection={t("features.sideMenu.modals.fastQuoteModal.description")}
        detailsSection={<FastQuoteDetails />}
        pickUpFormSection={
          <>
            <Subtitle>{t("newOrder.forms.pickUpForm.formTitle")}</Subtitle>

            <ChooseAddressForm
              name="pick_up"
              label={t("newOrder.forms.pickUpForm.formTitle")}
              address={pick_up}
              onAddressChanged={handleChangePickUp}
              minimal
            />
          </>
        }
        dropFormsSection={drops.map(({ reference, ...drop }, index) => (
          <div key={reference} className="mb-4">
            <Subtitle className="d-flex justify-content-between">
              <span>
                {t("newOrder.forms.dropOffForm.formTitle")} {size(drops) > 1 && index + 1}
              </span>

              {size(drops) > 1 && (
                <TextButton
                  id={`remove-${index}-drop-button`}
                  className="py-1"
                  label={t("common.buttons.removeDropBtn")}
                  onClick={() => onRemoveDrop(index)}
                  startIcon={<HighlightOffIcon fontSize="small" />}
                />
              )}
            </Subtitle>

            <ChooseAddressForm
              name={`drop_off_${index}`}
              label={`${t("newOrder.forms.dropOffForm.formTitle")}`}
              address={drop}
              onAddressChanged={(address) => handleChaneDrop(address, index)}
              minimal
            />
          </div>
        ))}
        addDropControlSection={
          <TextButton
            id="add-drop-button"
            className="pl-1"
            label={t("common.buttons.addDropBtn")}
            onClick={onAddNewDrop}
            startIcon={<AddDropIcon fontSize="small" />}
            disabled={!isFormDirty || size(drops) >= MAX_DROP_AMOUNT || !isValid}
          />
        }
        warningSection={
          size(drops) >= MAX_DROP_AMOUNT && (
            <Trans
              i18nKey="common.entities.order.maximumDropMessage"
              tOptions={{ email: "delivery@pedivan.co.uk", maxDrop: MAX_DROP_AMOUNT }}
              components={{ lnk: <a href="mailto:delivery@pedivan.co.uk" className="text-color-primary" /> }}
            />
          )
        }
        controlSection={
          <PrimaryButton
            id="submit-fast-quote-button"
            className="w-100"
            label={t("features.sideMenu.modals.fastQuoteModal.createOrderBtn")}
            onClick={onCreateNewOrder}
            disabled={!isFormDirty || !isValid || isSubmitting}
          />
        }
      />
    </FormikProvider>
  );
};

export default FastQuoteForm;
