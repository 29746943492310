import { css } from "@emotion/css";

const styles = {
  moreBtn: css`
    margin: -6px -8px !important;
  `,
  proofBtn: css`
    max-width: 124px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  `,
};

export default styles;
