import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import { PropTypes } from "prop-types";
import styles from "./InvocesPageLayout.styles";

const InvocesPageLayout = ({ headerSection, listSection, children, modals }) => {
  const { isMobile, isTablet } = useDeviceType();

  return (
    <div className={cx("d-flex flex-1 h-fit flex-row overflow-hidden", styles.content(isMobile || isTablet))}>
      <div className="d-flex flex-column align-items-stretch container pt-md-5 pt-4 pb-3 px-4 px-md-5 overflow-hidden">
        {headerSection}

        {listSection}

        {children}
      </div>

      {modals}
    </div>
  );
};

InvocesPageLayout.propTypes = {
  headerSection: PropTypes.node.isRequired,
  listSection: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  modals: PropTypes.node.isRequired,
};

export default InvocesPageLayout;
