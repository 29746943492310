import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";
import styles from "./styles";

const Subtitle = ({ className, children }) => (
  <div className={cx(styles, className, "mb-4 py-2 text-fs-14 text-fw-bold text-color-grey900")}>{children}</div>
);

Subtitle.defaultProps = {
  className: null,
};

Subtitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Subtitle;
