import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import ScrollLayout from "library/Layouts/ScrollLayout";
import { PropTypes } from "prop-types";
import styles from "./styles";

const ItemDetailsLayout = ({ statusBarSection, briefInfoSection, extraInfoSection, listSection, controlsSection }) => {
  const { isMobile } = useDeviceType();
  return (
    <>
      {!isMobile && (
        <BoxLayout className={cx(styles.statusBarSection, "position-sticky d-flex align-items-center mx-4")}>
          {statusBarSection}
        </BoxLayout>
      )}

      <ScrollLayout className="d-flex flex-column col px-0">
        {isMobile && <div className="px-4 pt-4">{statusBarSection}</div>}

        {briefInfoSection && (
          <div className={cx(isMobile && "order-first", "d-flex flex-row align-items-center mt-4 px-md-4 pl-4")}>
            {briefInfoSection}
          </div>
        )}

        <div className="d-flex flex-wrap my-2">{extraInfoSection}</div>

        <div className="mx-md-4 ml-4">{listSection}</div>
      </ScrollLayout>

      <div className={cx(styles.controlsSection, "d-flex justify-content-end align-items-stretch flex-nowrap p-4")}>
        {controlsSection}
      </div>
    </>
  );
};

ItemDetailsLayout.defaultProps = {
  statusBarSection: null,
  briefInfoSection: null,
  extraInfoSection: null,
  listSection: null,
  controlsSection: null,
};

ItemDetailsLayout.propTypes = {
  statusBarSection: PropTypes.node,
  briefInfoSection: PropTypes.node,
  extraInfoSection: PropTypes.node,
  listSection: PropTypes.node,
  controlsSection: PropTypes.node,
};

export default ItemDetailsLayout;
