import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveContact } from "store/features/contact.store";
import { LIBRARY_STORAGE_LIMIT, NEW_ORDER_FORM_STEPS } from "../../../../constants";
import styles from "./NewOrderSubmitButtons.styles";

const NewOrderSubmitButtons = ({ onReset, onSubmit, onSubmitAndSave, resetBtnOptions, submitBtnOptions }) => {
  const { isMobile, isDesktop } = useDeviceType();
  const { t } = useTranslation();

  const contact = useSelector(retrieveContact);

  const [, { step }] = useNewOrderForm();

  const resetBtnLabel = useMemo(() => {
    return step === NEW_ORDER_FORM_STEPS.SET_DELIVERY ? "common.buttons.resetBtn" : "common.buttons.cancelBtn";
  }, [step]);

  const submitBtnLabel = useMemo(() => {
    switch (step) {
      case NEW_ORDER_FORM_STEPS.SET_DELIVERY:
        return "newOrder.forms.setDeliveryForm.calculateBtn";
      case NEW_ORDER_FORM_STEPS.CALCULATE:
        return "newOrder.forms.calculatePriceForm.continueBtn";
      default:
        return "newOrder.forms.bookingForm.sendBookingBtn";
    }
  }, [step]);

  const isStorageLimitExceeded = useMemo(() => {
    return contact.library?.orders >= LIBRARY_STORAGE_LIMIT;
  }, [contact.library?.orders]);

  return (
    <div className="d-flex flex-lg-row flex-column-reverse justify-content-center justify-content-md-end align-items-center col-12 gap-3 mt-3 px-0">
      {isMobile ? (
        <TextButton
          id="reset-new-order-form-button"
          className="align-itself-center"
          label={t(resetBtnLabel)}
          onClick={onReset}
          {...resetBtnOptions}
        />
      ) : (
        <SecondaryButton
          id="reset-new-order-form-button"
          className={cx(!isDesktop && styles.btn, "w-100")}
          label={t(resetBtnLabel)}
          onClick={onReset}
          {...resetBtnOptions}
        />
      )}

      {step === NEW_ORDER_FORM_STEPS.BOOKING && (
        <PrimaryButton
          id="submit-and-save-new-order-step-button"
          className={cx(!isDesktop && styles.btn, "w-100")}
          label={t("newOrder.forms.bookingForm.sendAndSaveBookingBtn")}
          onClick={onSubmitAndSave}
          disabled={isStorageLimitExceeded}
        />
      )}

      <PrimaryButton
        id="submit-new-order-step-button"
        className={cx(!isDesktop && styles.btn, "w-100")}
        label={t(submitBtnLabel)}
        onClick={onSubmit}
        {...submitBtnOptions}
      />
    </div>
  );
};

NewOrderSubmitButtons.defaultProps = {
  onSubmitAndSave: () => {},
  submitBtnOptions: {},
  resetBtnOptions: {},
};

NewOrderSubmitButtons.propTypes = {
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitAndSave: PropTypes.func,
  resetBtnOptions: PropTypes.shape({
    disabled: PropTypes.bool,
    loading: PropTypes.func,
  }),
  submitBtnOptions: PropTypes.shape({
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
  }),
};

export default NewOrderSubmitButtons;
