import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";

const MiscDetailsLayout = ({ className, contentClassName, titleSection, contentSection }) => (
  <div className={cx("d-flex flex-column mb-4", className)}>
    <span className="text-fs-10 text-color-grey400 text-truncate">{titleSection}</span>
    <span className={cx("text-color-secondary text-truncate", contentClassName)}>{contentSection}</span>
  </div>
);

MiscDetailsLayout.defaultProps = {
  className: null,
  contentClassName: null,
};

MiscDetailsLayout.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  titleSection: PropTypes.node.isRequired,
  contentSection: PropTypes.node.isRequired,
};

export default MiscDetailsLayout;
