import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  layout: css`
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: ${palette.grey100};

      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${palette.primary.main};

      border: 3px ${palette.grey100} solid;

      border-radius: 12px;
    }
  `,
};

export default styles;
