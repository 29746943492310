import { Drawer as MuiDrawer } from "@mui/material";
import { PropTypes } from "prop-types";

const Drawer = ({ drawerRef, open, className, contentClassName, onClose, anchor, variant, children, ...restProps }) => (
  <MuiDrawer
    ref={drawerRef}
    open={open}
    anchor={anchor}
    className={className}
    PaperProps={{ className: contentClassName }}
    ModalProps={{ keepMounted: true, onBackdropClick: onClose }}
    variant={variant}
    transitionDuration={500}
    {...restProps}
  >
    {children}
  </MuiDrawer>
);

Drawer.defaultProps = {
  drawerRef: null,
  className: "",
  contentClassName: "",
  onClose: () => {},
  anchor: "left",
  variant: "temporary",
};

Drawer.propTypes = {
  drawerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  onClose: PropTypes.func,
  anchor: PropTypes.oneOf(["left", "right", "bottom"]),
  variant: PropTypes.oneOf(["persistent", "temporary", "permanent"]),
  children: PropTypes.node.isRequired,
};

export default Drawer;
