import { PropTypes } from "prop-types";

const LoadGoogleMap = ({ loadingComponent, children }) => {
  if (!window.google) return loadingComponent;
  return children;
};

LoadGoogleMap.defaultProps = {
  loadingComponent: null,
  children: null,
};

LoadGoogleMap.propTypes = {
  loadingComponent: PropTypes.node,
  children: PropTypes.node,
};

export default LoadGoogleMap;
