import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import Chip from "library/Atoms/Chip";
import { values } from "lodash";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import StatusIcon from "../StatusIcon";
import styles from "./styles";

const STATUS_INDICATOR_TYPES = { chip: "chip", icon: "labeled-icon" };

const StatusIndicator = ({ className, status, label, renderColor, renderIcon, variant, ...restProps }) => {
  const { isMobile } = useDeviceType();

  const color = useMemo(() => renderColor(status), [status, renderColor]);

  if (variant === STATUS_INDICATOR_TYPES.chip) {
    return (
      <Chip
        label={label}
        color={color}
        icon={<StatusIcon status={status} renderColor={renderColor} renderIcon={renderIcon} />}
        variant="filled"
        {...restProps}
      />
    );
  }

  return (
    <div className={cx(className, "d-flex align-items-center", isMobile ? "gap-3" : "flex-column w-100 gap-2")}>
      <StatusIcon
        className={styles.icon}
        status={status}
        renderColor={renderColor}
        renderIcon={renderIcon}
        variant="component-rounded"
      />
      <span className="text-fs-12 text-fw-bold text-color-secondary">{label}</span>
    </div>
  );
};

StatusIndicator.defaultProps = {
  className: "",
  variant: "chip",
};

StatusIndicator.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  renderColor: PropTypes.func.isRequired,
  renderIcon: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(values(STATUS_INDICATOR_TYPES)),
};

export default StatusIndicator;
