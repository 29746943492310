import * as Sentry from "@sentry/react";
import useAuthState from "hooks/useAuthState";
import { isUndefined } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveContactStatus } from "store/features/contact.store";
import { fetchContactRequest } from "store/requests/contact.requests";

const useContactMiddleware = ({ shouldLoadContact }) => {
  const dispatch = useDispatch();

  const { idle, loading, failed } = useSelector(retrieveContactStatus);

  const { user } = useAuthState();

  const isTesting = useMemo(() => !isUndefined(window.Cypress), []);

  const onLoadContact = useCallback(() => {
    let contactId;

    if (isTesting) {
      const currentUser = JSON.parse(localStorage.getItem("authUser")) || {};
      contactId = currentUser.uid;
    } else {
      Sentry.setUser({ id: user.uid, email: user.email });
      contactId = user.uid;
    }

    dispatch(fetchContactRequest({ contactId }));
  }, [dispatch, isTesting, user]);

  useEffect(() => {
    if (!shouldLoadContact) return;
    onLoadContact();
  }, [shouldLoadContact, onLoadContact]);

  return { isContactLoading: idle || loading, isContactLoadingFailed: failed };
};

export default useContactMiddleware;
