import { cx } from "@emotion/css";
import { Switch as MuiSwitch } from "@mui/material";
import { useField } from "formik";
import { PropTypes } from "prop-types";
import { useCallback } from "react";

const Toggler = ({ className, name, value, label, onChange }) => {
  const [{ value: switchValue, ...field } = {}] = useField({ name });

  const handleChange = useCallback((_, checked) => onChange(checked), [onChange]);

  return (
    <div className={cx(className, "d-flex flex-nowrap align-items-center")}>
      <MuiSwitch {...field} checked={value ?? switchValue} size="small" onChange={handleChange} />

      <span className="pl-3 text-fs-12 text-color-secondary">{label}</span>
    </div>
  );
};

Toggler.defaultProps = {
  className: "",
  label: null,
  value: undefined,
};

Toggler.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Toggler;
