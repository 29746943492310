import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  day: css`
    transform-style: preserve-3d;

    background-color: transparent !important;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 5.5px);
      height: calc(100% + 2.5px);

      transform: translateZ(-1px);
    }
  `,
  edgeDay: css`
    border: 1px solid ${palette.grey400} !important;

    &::before {
      background-color: ${palette.grey300};

      border-radius: 50%;
    }
  `,
  betweenDay: css`
    border: 1px solid ${palette.grey300} !important;

    &::before {
      background-color: ${palette.grey300};
    }
  `,

  hoveredDay: css`
    border: 1px solid transparent !important;

    &::before {
      background-color: ${palette.grey200} !important;
    }
  `,

  today: css`
    border: 1px solid ${palette.primary.main} !important;
  `,

  nonRoundedLeftBorder: css`
    &::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `,
  nonRoundedRightBorder: css`
    &::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `,
  roundedLeftBorder: css`
    &::before {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  `,
  roundedRightBorder: css`
    &::before {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  `,

  toolbarBtn: css`
    max-width: 100% !important;
  `,
};

export default styles;
