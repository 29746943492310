import { cx } from "@emotion/css";
import { PropTypes } from "prop-types";

const NewOrderDetailLayout = ({ contentClassName, titleSection, contentSection }) => (
  <>
    <span className="col-3 mb-2 px-0 text-color-grey400">{titleSection}</span>
    <span className={cx("mb-2 px-0 text-fw-bold", contentClassName)}>{contentSection}</span>
  </>
);

NewOrderDetailLayout.defaultProps = {
  contentClassName: "col-9",
};

NewOrderDetailLayout.propTypes = {
  contentClassName: PropTypes.string,
  titleSection: PropTypes.node.isRequired,
  contentSection: PropTypes.node.isRequired,
};

export default NewOrderDetailLayout;
