import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  btn: css`
    width: 35px;
    height: 35px;

    background-color: ${palette.white} !important;

    box-shadow: 0px 3px 6px #00000029;
  `,
};

export default styles;
