import useDeviceType from "hooks/useDeviceType";
import ItemDetailsLayout from "library/Layouts/DetailsLayout/ItemDetailsLayout";
import { PropTypes } from "prop-types";

const OrderDetailsLayout = ({
  statusBarSection,
  briefInfoSection,
  extraInfoSection,
  dropsListSection,
  cancelOrderSection,
  priceSection,
  controlsSection,
}) => {
  const { isDesktop } = useDeviceType();

  return (
    <ItemDetailsLayout
      statusBarSection={statusBarSection}
      briefInfoSection={briefInfoSection}
      extraInfoSection={extraInfoSection}
      listSection={
        <>
          {dropsListSection}

          {!isDesktop && <div className="mb-4">{cancelOrderSection}</div>}
        </>
      }
      controlsSection={
        <>
          {isDesktop && cancelOrderSection}

          <div className="d-flex flex-column align-items-end justify-content-between gap-4 col px-0">
            <div className="text-right text-fs-14 text-fw-semibold text-truncate">{priceSection}</div>

            <div className="d-flex flex-md-row flex-column-reverse justify-content-md-end justify-content-center align-items-end gap-3 col px-0">
              {controlsSection}
            </div>
          </div>
        </>
      }
    />
  );
};

OrderDetailsLayout.defaultProps = {
  statusBarSection: null,
  cancelOrderSection: null,
  priceSection: null,
};

OrderDetailsLayout.propTypes = {
  statusBarSection: PropTypes.node,
  briefInfoSection: PropTypes.node.isRequired,
  extraInfoSection: PropTypes.node.isRequired,
  dropsListSection: PropTypes.node.isRequired,
  cancelOrderSection: PropTypes.node,
  priceSection: PropTypes.node,
  controlsSection: PropTypes.node.isRequired,
};

export default OrderDetailsLayout;
