import useDeviceType from "hooks/useDeviceType";
import StatusIndicator from "library/Molecules/StatusViews/StatusIndicator";
import StatusStepper from "library/Molecules/StatusViews/StatusStepper";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import { getInvoiceStatusColor, getInvoiceStatusIcon } from "pages/Invoices/utils/helper";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { prettifyStatusLabel } from "utility/helpers/general";
import { EMAILED_STATUS, INVOICE_STATUSES_LIST, PAID_STATUS } from "../../../../../constants";

const InvoiceStatusBanner = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const [
    {
      selectedInvoice: { id, status },
    },
  ] = useInvoices();

  const steps = useMemo(() => {
    return INVOICE_STATUSES_LIST.reduce((prev, s) => {
      if (![EMAILED_STATUS, PAID_STATUS].includes(s)) return [...prev];

      const label = t([`utils.invoiceStatuses.${s}`, prettifyStatusLabel(s)]);
      return [...prev, { label, status: s }];
    }, []);
  }, [t]);

  const activeStep = useMemo(() => steps.findIndex((step) => step.status === status), [steps, status]);

  const showStepper = useMemo(() => {
    if (!INVOICE_STATUSES_LIST.includes(status) || isMobile) return false;
    return [EMAILED_STATUS, PAID_STATUS].includes(status);
  }, [isMobile, status]);

  return (
    <div id={`invoice-status-stepper-${id}`} className="w-100" data-cy={`status-stepper-${status}`}>
      {showStepper ? (
        <StatusStepper
          activeStep={activeStep}
          steps={steps}
          renderColor={getInvoiceStatusColor}
          renderIcon={getInvoiceStatusIcon}
        />
      ) : (
        <StatusIndicator
          status={status}
          label={t([`utils.invoiceStatuses.${status}`, prettifyStatusLabel(status)])}
          renderColor={getInvoiceStatusColor}
          renderIcon={getInvoiceStatusIcon}
          variant="labeled-icon"
        />
      )}
    </div>
  );
};

export default InvoiceStatusBanner;
