import { useEffect, useRef } from "react";

const useInputErrorHandler = (error) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!error) return;
    if (!inputRef.current) return;
    inputRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [error, inputRef]);

  return inputRef;
};

export default useInputErrorHandler;
