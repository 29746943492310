import { css } from "@emotion/css";

const styles = {
  container: (lastStep) => css`
    height: fit-content;

    ${lastStep ? "margin-top: calc((1rem + 19px) * -1)" : "margin-top: calc((3rem + 29px) * -1)"};
  `,
};

export default styles;
