import { FormikProvider, useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect } from "react";
import DateFilter from "./components/DateFilter";
import SearchFilter from "./components/SearchFilter";
import StatusFilter from "./components/StatusFilter";
import ListFiltersLayout from "./layouts/ListFiltersLayout";
import ListFiltersSkeleton from "./skeletons/ListFiltersSkeleton";

const ListFilters = ({ onFilterList, isLoading, isOrder }) => {
  const filtersForm = useFormik({
    initialValues: { start: undefined, end: undefined, status: undefined, search: undefined },
    onSubmit: onFilterList,
  });
  const { values: formValues, submitForm, dirty: isFormDirty } = filtersForm;

  useEffect(() => {
    if (!isFormDirty) return;
    submitForm();
  }, [isFormDirty, submitForm, formValues]);

  return (
    <FormikProvider value={filtersForm}>
      <ListFiltersLayout
        skeletonSection={isLoading && <ListFiltersSkeleton />}
        statusFilterSection={<StatusFilter isOrder={isOrder} />}
        searchFilterSection={<SearchFilter />}
        dateFilterSection={<DateFilter />}
      />
    </FormikProvider>
  );
};

ListFilters.defaultProps = {
  isOrder: false,
};

ListFilters.propTypes = {
  onFilterList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOrder: PropTypes.bool,
};

export default ListFilters;
