import { cx } from "@emotion/css";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import { useFormikContext } from "formik";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import FilterLayout from "library/Layouts/InputLayout/FilterLayout";
import DateRangePicker from "library/Molecules/DatePicker/DateRangePicker";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";

const DateFilter = () => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  const handleChangeDate = useCallback(
    (_, value) => {
      setFieldValue("start", value[0] ? new Date(value[0]).toISOString() : null);
      setFieldValue("end", value[0] ? new Date(value[1]).toISOString() : null);
    },
    [setFieldValue]
  );

  return (
    <FilterLayout label={`${t("features.filtersForm.timeLabel")}:`}>
      <DateRangePicker
        id="list-time-filter"
        name="interval"
        placeholder={t("features.filtersForm.timePlaceholder")}
        onSelect={handleChangeDate}
        renderInput={({ isOpen, open, close, value, ...restProps }) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className="d-flex flex-nowrap" onPointerUp={open}>
            <InputBase
              componentsProps={{
                input: { className: cx(styles.input, "text-truncate"), disabled: true },
                root: { className: "w-100 overflow-hidden" },
              }}
              value={value}
              endAdornment={
                isOpen ? (
                  <ArrowDropUpIcon className="cursor-pointer" color="primary" fontSize="small" />
                ) : (
                  <ArrowDropDownIcon className="cursor-pointer" color="primary" fontSize="small" />
                )
              }
              {...restProps}
            />

            {value !== t("features.filtersForm.timePlaceholder") && (
              <IconButton className="p-1" onClick={close}>
                <CloseRoundedIcon className={styles.closeIcon} color="info" />
              </IconButton>
            )}
          </div>
        )}
        renderToolbar={({ apply, clear, isSingleDay, isDirty, isEmpty, buttonProps }) => (
          <>
            <PrimaryButton
              id="apply-date-range"
              label={
                isEmpty
                  ? t("features.filtersForm.showAllDatesBtn")
                  : `${
                      isSingleDay
                        ? t("features.filtersForm.filterBySingleDayBtn")
                        : t("features.filtersForm.filterByIntervalBtn")
                    }`
              }
              onClick={apply}
              {...buttonProps}
            />

            {isDirty && (
              <SecondaryButton
                id="clear-date-range"
                label={t("features.filtersForm.clearBtn")}
                onClick={clear}
                {...buttonProps}
              />
            )}
          </>
        )}
      />
    </FilterLayout>
  );
};

export default DateFilter;
