import { PropTypes } from "prop-types";

const FilterLayout = ({ label, children }) => (
  <div className="d-flex align-items-center">
    <span className="text-fs-10 text-color-grey400 pr-2">{label}</span>
    {children}
  </div>
);

FilterLayout.defaultProps = {
  label: "",
};

FilterLayout.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FilterLayout;
