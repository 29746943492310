import useAuthState from "hooks/useAuthState";
import useCookie from "hooks/useCookie";
import useSystemState from "hooks/useSystemState";
import { isUndefined } from "lodash";
import { useEffect, useMemo } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const AuthenticatedRoute = () => {
  const navigate = useNavigate();
  const [, , removeCookie] = useCookie();

  const { isAuthenticated, user, loading } = useAuthState();
  const { isSystemLoading, isSystemLoadingFailed } = useSystemState();

  const isTesting = useMemo(() => !isUndefined(window.Cypress), []);

  useEffect(() => {
    if (isTesting) return;
    if (user || loading) return;

    navigate("/login");
  }, [isTesting, user, loading, navigate]);

  useEffect(() => {
    if (isAuthenticated) return;
    removeCookie("drawerPosition");
  }, [isAuthenticated, removeCookie]);

  if (isSystemLoading || isSystemLoadingFailed) return null;
  if (isAuthenticated || !!isTesting) return <Outlet />;
  return <Navigate to="/login" />;
};

export default AuthenticatedRoute;
