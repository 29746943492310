import { cx } from "@emotion/css";
import { Button } from "@mui/material";
import Spinner from "library/Atoms/Spinner";
import { PropTypes } from "prop-types";

const TextButton = ({ contentClassName, label, disabled, loading, ...restProps }) => (
  <Button disabled={disabled || loading} {...restProps}>
    <span className={cx(contentClassName, "d-flex align-items-center")}>
      {loading ? <Spinner className="my-1" /> : <span className="text-capitalized">{label}</span>}
    </span>
  </Button>
);

TextButton.defaultProps = {
  contentClassName: "",
  label: null,
  loading: false,
  disabled: false,
};

TextButton.propTypes = {
  contentClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TextButton;
