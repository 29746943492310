import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import PropTypes from "prop-types";
import styles from "./styles";

const DrawerContentLayout = ({ topPartSection, tabsSection, fastQuoteTabSection, settingsSection, open }) => {
  const { isDesktop } = useDeviceType();

  return (
    <>
      {!!isDesktop && topPartSection}

      <div
        className={cx(
          styles.menuPart,
          isDesktop ? "bg-color-grey900" : "bg-color-secondary",
          open ? styles.drawerOpened : styles.drawerClosed(!isDesktop),
          "position-sticky d-flex flex-column justify-content-between align-items-stretch py-3 px-0"
        )}
      >
        <div className="d-flex flex-column justify-content-between col px-0">{tabsSection}</div>

        <div className="d-flex flex-column">{fastQuoteTabSection}</div>

        <div className={cx(styles.settings, "d-flex flex-column pt-3")}>{settingsSection}</div>
      </div>
    </>
  );
};

DrawerContentLayout.propTypes = {
  topPartSection: PropTypes.node.isRequired,
  tabsSection: PropTypes.node.isRequired,
  fastQuoteTabSection: PropTypes.node.isRequired,
  settingsSection: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DrawerContentLayout;
