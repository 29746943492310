import DeleteAddressIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditAddressIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import useOpenClose from "hooks/useOpenClose";
import IconButton from "library/Atoms/Button/IconButton";
import LibraryConfirmDeleteModal from "pages/Library/modals/LibraryConfirmDeleteModal/LibraryConfirmDeleteModal";
import AddressSettingsModal from "pages/Library/modals/LibraryItemSettingsModal/AddressSettingsModal/AddressSettingsModal";
import AddressSettingsForm from "../../AddressSettingsForm/AddressSettingsForm";

const AddressControls = () => {
  const [isConfirmDeleteModalOpen, openConfirmDeleteModal, closeConfirmDeleteModal] = useOpenClose(false);
  const [isEditAddressModalOpen, openEditAddressModal, closeEditAddressModal] = useOpenClose(false);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <IconButton onClick={openEditAddressModal} color="yellow" isRounded>
          <EditAddressIcon />
        </IconButton>

        <IconButton onClick={openConfirmDeleteModal} color="error" isRounded>
          <DeleteAddressIcon />
        </IconButton>
      </div>

      {isConfirmDeleteModalOpen && <LibraryConfirmDeleteModal onClose={closeConfirmDeleteModal} isAddress />}

      {isEditAddressModalOpen && (
        <AddressSettingsModal onClose={closeEditAddressModal} isEdit>
          <AddressSettingsForm onClose={closeEditAddressModal} isEdit />
        </AddressSettingsModal>
      )}
    </>
  );
};

export default AddressControls;
