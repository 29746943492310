import { cx } from "@emotion/css";
import Pagination from "@mui/material/Pagination";
import ListFilters from "features/ListFillters";
import EmptyPageText from "library/Atoms/Text/EmptyPageText";
import { keys, size } from "lodash";
import useOrders from "pages/Dashboard/hooks/useOrders";
import OrderListLayout from "pages/Dashboard/layouts/OrderListLayout/OrderListLayout";
import OrdersListSkeleton from "pages/Dashboard/skeletons/OrdersListSkeleton/OrdersListSkeleton";
import { useTranslation } from "react-i18next";
import { format } from "utility/date-fns";
import { calculatePackagesAmount } from "utility/helpers/orders";
import OrderItem from "./OrderItem/OrderItem";

const OrdersList = () => {
  const { t } = useTranslation();

  const [
    { ordersByMonth, pagination },
    { onChangePage, onChangeFilters },
    { isOrderListEmpty, isListLoading, isFiltersApplied, isFirstLoading },
  ] = useOrders();

  return (
    <OrderListLayout
      filtersSection={<ListFilters onFilterList={onChangeFilters} isLoading={isFirstLoading} isOrder />}
      listSection={
        !isListLoading &&
        keys(ordersByMonth).map((groupKey, index) => (
          <div key={groupKey} className={cx("pb-2", size(ordersByMonth) - 1 !== index && "mb-4")}>
            <div className="mb-2 text-color-grey400 text-fs-10">{format(new Date(groupKey), "MMM dd, yyyy")}</div>

            {ordersByMonth[groupKey].map((order) => (
              <OrderItem
                key={order.reference}
                reference={order.reference}
                startDate={order.startDate}
                endDate={order.endDate}
                packages={calculatePackagesAmount(order.drops)}
                status={order.status}
                express={order.isExpress}
              />
            ))}
          </div>
        ))
      }
      emptyListSection={
        isOrderListEmpty && isFiltersApplied && <EmptyPageText>{t("dashboard.emptyDeliveryListMessage")}</EmptyPageText>
      }
      paginationSection={
        pagination.total_pages > 1 && (
          <Pagination
            className="mx-auto pt-3"
            page={pagination.active_page}
            count={pagination.total_pages}
            onChange={onChangePage}
            disabled={isListLoading}
            color="primary"
            size="small"
          />
        )
      }
      skeletonSection={isListLoading && <OrdersListSkeleton />}
    />
  );
};

export default OrdersList;
