import DefaultModal from "library/Molecules/Modal/DefaultModal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FastQuoteModal = ({ onClose, children }) => {
  const { t } = useTranslation();

  return (
    <DefaultModal
      id="fast-quote-modal"
      title={t("features.sideMenu.modals.fastQuoteModal.title")}
      modalClassName="d-flex flex-column"
      onClose={onClose}
      size="big"
    >
      {children}
    </DefaultModal>
  );
};

FastQuoteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default FastQuoteModal;
