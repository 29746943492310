import { css } from "@emotion/css";

const styles = {
  container: css`
    width: 100%;
    max-height: 100%;
  `,
};

export default styles;
