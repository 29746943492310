import { isWeekend } from "date-fns";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isPeakTime } from "utility/helpers/general";
import MessageLayout from "../../../layouts/MessageLayout/MessageLayout";

const IncreasedPriceMessage = ({ startDate, isExpress }) => {
  const { t } = useTranslation();

  const translationKey = useMemo(() => {
    const weekendFlag = isWeekend(startDate);
    const peakTimeFlag = isPeakTime(startDate);

    let key = "newOrder.estimation.increasedPriceMessage";

    if (isExpress) {
      key += ".express";
      if (weekendFlag) key += "_weekend";
      else if (peakTimeFlag) key += "_peak";
      return key;
    }

    if (weekendFlag) key += ".weekend";
    else if (peakTimeFlag) key += ".peak";

    return key;
  }, [startDate, isExpress]);

  if (!isExpress && !isWeekend(startDate) && !isPeakTime(startDate)) return null;
  return <MessageLayout className="col-12 mt-3 px-0" labelSection={t(translationKey)} color="warning" />;
};

IncreasedPriceMessage.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  isExpress: PropTypes.bool.isRequired,
};

export default IncreasedPriceMessage;
