import { isEmpty } from "lodash";
import OrderControls from "pages/Library/components/OrderDetails/OrderControls/OrderControls";
import OrderDetails from "pages/Library/components/OrderDetails/OrderDetails";
import OrderMap from "pages/Library/components/OrderDetails/OrderMap/OrderMap";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedSavedOrder, retrieveSelectedSavedOrder } from "store/features/library.store";
import LibraryItemDetailsDrawer from "../LibraryDetailsDrawer/LibraryItemDetailsDrawer";

const OrderDetailsDrawer = () => {
  const dispatch = useDispatch();

  const selectedOrder = useSelector(retrieveSelectedSavedOrder);

  const isOpen = useMemo(() => !isEmpty(selectedOrder), [selectedOrder]);

  const handleDeselectOrder = useCallback(() => dispatch(resetSelectedSavedOrder()), [dispatch]);

  return (
    <LibraryItemDetailsDrawer
      id={`order-details-drawer-${selectedOrder?.id || ""}`}
      mapSection={<OrderMap />}
      controlsSection={<OrderControls />}
      onClose={handleDeselectOrder}
      open={isOpen}
    >
      <OrderDetails onClose={handleDeselectOrder} />
    </LibraryItemDetailsDrawer>
  );
};

export default OrderDetailsDrawer;
