import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSelectedInvoice,
  retrieveInvoices,
  retrieveInvoicesFilters,
  retrieveInvoicesPagination,
  retrieveInvoicesStatus,
  retrieveSelectedInvoice,
  setSelectedInvoice,
} from "store/features/invoice.store";
import { fetchFilteredInvoicesRequest, fetchInvoicesRequest } from "store/requests/invoice.requests";

const useInvoices = () => {
  const dispatch = useDispatch();

  const invoices = useSelector(retrieveInvoices);
  const selectedInvoice = useSelector(retrieveSelectedInvoice);

  const invoiceFilters = useSelector(retrieveInvoicesFilters);
  const invoicePagination = useSelector(retrieveInvoicesPagination);

  const { idle, loading: invoicesLoading, refetching: invoicesRefetching } = useSelector(retrieveInvoicesStatus);

  const isListLoading = useMemo(() => invoicesLoading || invoicesRefetching, [invoicesLoading, invoicesRefetching]);
  const isFiltersApplied = useMemo(() => !isEmpty(invoiceFilters), [invoiceFilters]);
  const isInvoiceListEmpty = useMemo(() => !isListLoading && isEmpty(invoices), [isListLoading, invoices]);
  const isInvoiceSelected = useMemo(() => !isEmpty(selectedInvoice), [selectedInvoice]);

  const onSelectInvoice = useCallback((reference) => dispatch(setSelectedInvoice(reference)), [dispatch]);

  const onDeselectInvoice = useCallback(() => {
    if (!isInvoiceSelected) return;
    dispatch(resetSelectedInvoice());
  }, [isInvoiceSelected, dispatch]);

  const onChangePage = useCallback(
    (_, page) => invoicePagination.active_page !== page && dispatch(fetchInvoicesRequest(page)),
    [invoicePagination.active_page, dispatch]
  );

  const onChangeFilters = useCallback((filters) => dispatch(fetchFilteredInvoicesRequest(filters)), []);

  return [
    { invoices, selectedInvoice, pagination: invoicePagination },
    { onSelectInvoice, onDeselectInvoice, onChangePage, onChangeFilters },
    {
      isIdle: idle,
      isListLoading,
      isInvoiceListEmpty,
      isInvoiceSelected,
      isFirstLoading: invoicesLoading,
      isFiltersApplied,
    },
  ];
};

export default useInvoices;
