import AlertModal from "library/Molecules/Modal/AlertModal";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

const SystemLoadFailModal = () => {
  const { t } = useTranslation();

  const onReload = useCallback(() => window.location.reload(), []);

  return (
    <AlertModal
      id="load-fail-modal"
      title={t("features.failLoadModal.title")}
      description={
        <Trans
          i18nKey="features.failLoadModal.description"
          tOptions={{ email: "info@pedivan.co.uk" }}
          components={{
            lnk: <a href="mailto:delivery@pedivan.co.uk" className="text-color-primary" />,
          }}
        />
      }
      closeBtnText={t("features.failLoadModal.reloadBtn")}
      onConfirm={onReload}
      closable={false}
    />
  );
};

export default SystemLoadFailModal;
