import { css } from "@emotion/css";
import commonStyles from "utility/ui/common";

const styles = {
  accordion: commonStyles.accordion,
  map: css`
    height: 220px;
  `,
};

export default styles;
