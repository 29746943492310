import { cx } from "@emotion/css";
import { Skeleton as MuiSkeleton } from "@mui/material";
import { PropTypes } from "prop-types";

const Skeleton = ({ className, skeletonClassName, rounded }) => (
  <div className={className}>
    <MuiSkeleton
      className={cx(rounded && "border-rounded", skeletonClassName)}
      variant="rectangular"
      width="100%"
      height="100%"
    />
  </div>
);

Skeleton.defaultProps = {
  className: "",
  skeletonClassName: "",
  rounded: true,
};

Skeleton.propTypes = {
  className: PropTypes.string,
  skeletonClassName: PropTypes.string,
  rounded: PropTypes.bool,
};

export default Skeleton;
