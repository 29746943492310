import { css } from "@emotion/css";

const styles = {
  filters: css`
    width: 100%;
    height: 25px;
  `,
};

export default styles;
