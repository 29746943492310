import ConfirmationModal from "library/Molecules/Modal/ConfirmationModal";
import { PropTypes } from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const LibraryConfirmResetModal = ({ onConfirm, onClose, isAddress }) => {
  const { t } = useTranslation();

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <ConfirmationModal
      id="reset-library-item-form-modal"
      title={t("library.modals.resetItemModal.title")}
      description={t("library.modals.resetItemModal.description")}
      onConfirm={handleConfirm}
      onDecline={onClose}
      declineBtnText={t("library.modals.resetItemModal.backToBtn", { context: isAddress ? "address" : "order" })}
      confirmBtnText={t("library.modals.resetItemModal.resetChangesBtn")}
    />
  );
};

LibraryConfirmResetModal.defaultProps = {
  isAddress: false,
};

LibraryConfirmResetModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isAddress: PropTypes.bool,
};

export default LibraryConfirmResetModal;
