import { useFormikContext } from "formik";
import PhoneInput from "library/Atoms/Input/PhoneInput";
import TextInput from "library/Atoms/Input/TextInput";
import Select from "library/Atoms/Select";
import Subtitle from "library/Atoms/Subtitle";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveLocalizations } from "store/features/localization.store";

const ContactForm = () => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const localizations = useSelector(retrieveLocalizations);

  const onChangeUser = useCallback((name, value) => setFieldValue(name, value), [setFieldValue]);

  return (
    <div className="d-flex flex-wrap">
      <Subtitle className="col-12 mt-4 px-0">{t("profile.forms.contactForm.formTitle")}</Subtitle>

      <TextInput
        id="firstName"
        name="firstName"
        className="col-12 col-md-6 my-3 px-0 pr-md-2"
        label={t("common.forms.firstNameLabel")}
        placeholder={t("common.forms.firstNamePlaceholder")}
        onChange={onChangeUser}
        required
      />

      <TextInput
        id="lastName"
        name="lastName"
        className="col-12 col-md-6 my-3 px-0 pl-md-2"
        label={t("common.forms.lastNameLabel")}
        placeholder={t("common.forms.lastNamePlaceholder")}
        onChange={onChangeUser}
        required
      />

      <PhoneInput
        id="phone"
        name="phone"
        className="col-12 col-md-6 my-3 px-0 pr-md-2"
        label={t("common.forms.phoneLabel")}
        placeholder={t("common.forms.phonePlaceholder")}
        onChange={onChangeUser}
        required
      />

      <TextInput
        id="email"
        name="email"
        className="col-12 col-md-6 my-3 px-0 pl-md-2"
        label={t("common.forms.emailLabel")}
        onChange={onChangeUser}
        disabled
      />

      <TextInput
        id="company"
        name="company"
        className="col-12 col-md-6 my-3 px-0 pr-md-2"
        label={t("common.forms.companyNameLabel")}
        placeholder={t("common.forms.companyNamePlaceholder")}
        onChange={onChangeUser}
        required
      />

      <Select
        id="city"
        name="city"
        className="col-12 col-md-3 my-3 px-0 px-md-2"
        label={t("common.forms.cityLabel")}
        placeholder={t("common.forms.cityPlaceholder")}
        options={localizations.map(({ city_name, disabled }) => ({
          value: city_name,
          label: t(`utils.cities.${city_name}`),
          disabled,
        }))}
        onChange={onChangeUser}
        required
      />

      <TextInput
        id="companyVat"
        name="companyVat"
        className="col-12 col-md-3 my-3 px-0 pl-md-2"
        label={t("common.forms.companyVATLabel")}
        placeholder={t("common.forms.companyVATPlaceholder")}
        onChange={onChangeUser}
      />

      <TextInput
        id="companyAddress"
        name="companyAddress"
        className="col-12 col-md-6 my-3 px-0 pr-md-2"
        label={t("common.forms.companyAddressLabel")}
        placeholder={t("common.forms.companyAddressPlaceholder")}
        onChange={onChangeUser}
        required
      />

      <TextInput
        id="companyBillingAddress"
        name="companyBillingAddress"
        className="col-12 col-md-6 my-3 px-0 pl-md-2"
        label={t("common.forms.companyBillingAddressLabel")}
        placeholder={t("common.forms.companyBillingAddressPlaceholder")}
        onChange={onChangeUser}
      />
    </div>
  );
};

export default ContactForm;
