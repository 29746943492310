import { isSameDay } from "date-fns";
import { useFormikContext } from "formik";
import Select from "library/Atoms/Select";
import Toggler from "library/Atoms/Switch/Toggler";
import Tooltip from "library/Atoms/Tooltip";
import useTimeForm from "pages/NewOrder/hooks/useTimeForm";
import DateFormLayout from "pages/NewOrder/layouts/FormLayout/DateFormLayout/DateFormLayout";
import { useTranslation } from "react-i18next";
import { isPeakTime } from "utility/helpers/general";

const OrderTimeForm = () => {
  const { t } = useTranslation();

  const {
    values: { startDate, endDate, isExpress },
  } = useFormikContext();

  const [{ timePickUpOptions, timeToDropOffOptions }, { onToggleIsExpress, onChangePickUpAt, onChangeDropOffAt }] =
    useTimeForm(true);

  return (
    <DateFormLayout
      titleSection={t("newOrder.forms.dateForm.formTitle")}
      expressTogglerSection={
        <Toggler
          name="isExpress"
          label={
            <>
              <span>{t("common.forms.expressDeliveryLabel")}</span>
              <Tooltip>{t("common.forms.expressDeliveryTooltip")}</Tooltip>
            </>
          }
          onChange={onToggleIsExpress}
        />
      }
      startTimeInputSection={
        <Select
          id="inputTimeStart"
          name="startDate"
          label={t("common.forms.pickUpTimeLabel")}
          placeholder={t("common.forms.pickUpTimePlaceholder")}
          value={startDate ? startDate.toISOString() : ""}
          options={timePickUpOptions}
          onChange={onChangePickUpAt}
          helper={
            isPeakTime(startDate) && (
              <span className="text-color-warning">({t("common.forms.pickUpTimePeakHelper")})</span>
            )
          }
          disabled={!startDate}
        />
      }
      endTimeInputSection={
        <Select
          id="inputTimeEnd"
          name="endDate"
          label={t("common.forms.dropOffTimeLabel")}
          placeholder={t("common.forms.dropOffTimePlaceholder")}
          value={endDate ? endDate.toISOString() : ""}
          options={timeToDropOffOptions}
          onChange={onChangeDropOffAt}
          helper={
            endDate && (
              <span className="d-flex flex-wrap">
                {isExpress && (
                  <span className="text-color-warning">({t("common.forms.dropOffTimeExpressDeliveryHelper")})</span>
                )}

                {!isSameDay(startDate, endDate) && (
                  <span className="text-color-warning">({t("common.forms.dropOffTimeNextDayDeliveryHelper")})</span>
                )}
              </span>
            )
          }
          disabled={!startDate}
        />
      }
    />
  );
};

export default OrderTimeForm;
