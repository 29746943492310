import useAuthState from "hooks/useAuthState";
import useOpenClose from "hooks/useOpenClose";
import { createContext, useEffect } from "react";
import useContactMiddleware from "./hooks/useContactModdleware";
import useUtilsMiddleware from "./hooks/useUtilsMiddleware";

export const SystemContext = createContext();

export const SystemProvider = (props) => {
  const { isAuthenticated } = useAuthState();
  const { isUtilsLoading, isUtilsLoadingFailed } = useUtilsMiddleware();

  const [shouldLoadContact, startContactLoading, stopContactLoading] = useOpenClose(false);
  const [isSystemReady, setSystemReady] = useOpenClose(false);

  const { isContactLoading, isContactLoadingFailed } = useContactMiddleware({ shouldLoadContact });

  useEffect(() => {
    if (isUtilsLoading || isUtilsLoadingFailed) return;
    if (isAuthenticated) !isSystemReady && startContactLoading();
    else setSystemReady();
  }, [isUtilsLoading, isUtilsLoadingFailed, isAuthenticated, isSystemReady, startContactLoading, setSystemReady]);

  useEffect(() => {
    if (isUtilsLoading || isUtilsLoadingFailed) return;
    if (isContactLoading || isContactLoadingFailed) return;
    setSystemReady();
  }, [isUtilsLoading, isUtilsLoadingFailed, isContactLoading, isContactLoadingFailed, setSystemReady]);

  useEffect(() => {
    if (!isSystemReady) return;
    stopContactLoading();
  }, [isSystemReady, stopContactLoading]);

  return (
    <SystemContext.Provider
      value={{
        isSystemLoading: (isUtilsLoading || isContactLoading) && !isSystemReady,
        isSystemLoadingFailed: (isUtilsLoadingFailed || isContactLoadingFailed) && !isSystemReady,
      }}
      {...props}
    />
  );
};
