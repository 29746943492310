import InfoIcon from "@mui/icons-material/Info";
import { Tooltip as MuiTooltip } from "@mui/material";
import useOpenClose from "hooks/useOpenClose";
import { PropTypes } from "prop-types";

const Tooltip = ({ className, children, color }) => {
  const [tooltipShown, show, hide] = useOpenClose(false);

  return (
    <MuiTooltip
      className={`text-color-${color}`}
      title={<div className={className}>{children}</div>}
      open={tooltipShown}
      onClose={hide}
      onOpen={show}
      onClick={show}
      arrow
    >
      <InfoIcon sx={{ fontSize: 12, marginLeft: "3px", marginBottom: "3px" }} />
    </MuiTooltip>
  );
};

Tooltip.defaultProps = {
  className: "",
  color: "grey300",
};

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["grey300", "primary", undefined]),
};

export default Tooltip;
