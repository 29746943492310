import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import SecondaryButton from "library/Atoms/Button/SecondaryButton";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import { isEmpty } from "lodash";
import AddressDetailsLayout from "pages/Library/layouts/LibraryDetailsLayout/AddressDetailsLayout/AddressDetailsLayout";
import LibraryConfirmDeleteModal from "pages/Library/modals/LibraryConfirmDeleteModal/LibraryConfirmDeleteModal";
import AddressSettingsModal from "pages/Library/modals/LibraryItemSettingsModal/AddressSettingsModal/AddressSettingsModal";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSelectedAddress } from "store/features/library.store";
import { format } from "utility/date-fns";
import AddressSettingsForm from "../AddressSettingsForm/AddressSettingsForm";
import styles from "./AddressDetails.styles";

const AddressDetails = ({ onClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const selectedAddress = useSelector(retrieveSelectedAddress);
  const { id, label, location, recipient, created_at, updated_at, notes, note } = selectedAddress;

  const [isConfirmDeleteModalOpen, openConfirmDeleteModal, closeConfirmDeleteModal] = useOpenClose(false);
  const [isEditAddressModalOpen, openEditAddressModal, closeEditAddressModal] = useOpenClose(false);

  if (isEmpty(selectedAddress)) return null;
  return (
    <AddressDetailsLayout
      labelSection={label}
      timestampsSection={
        <>
          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.createdAt")}
            contentSection={format(new Date(created_at?._seconds * 1000), "MMM dd, yyyy HH:mm")}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.updatedAt")}
            contentSection={format(new Date(updated_at?._seconds * 1000), "MMM dd, yyyy HH:mm")}
          />
        </>
      }
      formattedAddressSection={location.location}
      addressDetailsSection={
        <>
          {location.city && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("library.address.city")}
              contentSection={<span className="text-wrap">{location.city}</span>}
            />
          )}

          {location.postcode && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("library.address.postcode")}
              contentSection={<span className="text-wrap">{location.postcode}</span>}
            />
          )}

          {location.buildingOrFlatNumber && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("library.address.number")}
              contentSection={<span className="text-wrap">{location.buildingOrFlatNumber}</span>}
            />
          )}

          {location.secondLineAddress && (
            <MiscDetailsLayout
              className="col-12 my-3"
              titleSection={t("library.address.secondLineAddress")}
              contentSection={<span className="text-wrap">{location.secondLineAddress}</span>}
            />
          )}

          {recipient?.name && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("common.entities.order.recipientName")}
              contentSection={recipient.name}
            />
          )}

          {recipient?.phone && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("common.entities.order.recipientPhone")}
              contentSection={recipient.phone}
            />
          )}

          {recipient?.email && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("common.entities.order.recipientEmail")}
              contentSection={recipient.email}
            />
          )}

          {(notes || note) && (
            <MiscDetailsLayout
              className="col-12 my-3"
              titleSection={t("library.address.notes")}
              contentSection={<span className="text-wrap">{notes || note}</span>}
            />
          )}
        </>
      }
      deleteControlSection={
        <SecondaryButton
          id={`delete-address-${id}-btn`}
          className={cx(styles.controlBtn(isMobile), "w-100 mr-auto")}
          contentClassName="text-truncate text-fs-12"
          label={t("library.address.deleteAddressBtn")}
          onClick={openConfirmDeleteModal}
        />
      }
      editControlSection={
        <SecondaryButton
          id={`edit-address-${id}-btn`}
          className={cx(styles.controlBtn(isMobile), "w-100")}
          contentClassName="text-truncate text-fs-12"
          label={t("library.address.editAddressBtn")}
          onClick={openEditAddressModal}
        />
      }
      backControlSection={
        <PrimaryButton
          id="back-to-addresses-btn"
          className={cx(styles.controlBtn(isMobile), "w-100")}
          contentClassName="text-truncate text-fs-12"
          label={t("library.address.backToAddressesBtn")}
          onClick={onClose}
        />
      }
      modals={
        <>
          {isConfirmDeleteModalOpen && <LibraryConfirmDeleteModal onClose={closeConfirmDeleteModal} isAddress />}

          {isEditAddressModalOpen && (
            <AddressSettingsModal onClose={closeEditAddressModal} isEdit>
              <AddressSettingsForm onClose={closeEditAddressModal} isEdit />
            </AddressSettingsModal>
          )}
        </>
      }
    />
  );
};

AddressDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddressDetails;
