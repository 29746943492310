import { cx } from "@emotion/css";
import { IconButton as MuiIconButton } from "@mui/material";
import Spinner from "library/Atoms/Spinner";
import { PropTypes } from "prop-types";
import styles from "./styles";

const IconButton = ({ className, children, color, loading, isRounded, ...restProps }) => (
  <MuiIconButton className={cx(isRounded && styles.btn, className)} color={color} {...restProps}>
    {loading ? <Spinner /> : children}
  </MuiIconButton>
);

IconButton.defaultProps = {
  className: "",
  color: "primary",
  isRounded: false,
  loading: false,
};

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  loading: PropTypes.bool,
  isRounded: PropTypes.bool,
};

export default IconButton;
