import useDeviceType from "hooks/useDeviceType";
import Skeleton from "library/Atoms/Skeleton";
import LoadGoogleMap from "library/GoogleMapComponents/LoadGoogleMap";
import useOrders from "pages/Dashboard/hooks/useOrders";
import OrdersMapViewDrawer from "pages/Dashboard/modals/OrdersMapViewDrawer/OrdersMapViewDrawer";
import { PropTypes } from "prop-types";
import { useMemo } from "react";
import OrderDetails from "../OrderDetails/OrderDetails";
import SaveOrderButton from "../OrderDetails/SaveOrderButton/SaveOrderButton";
import OrdersMap from "./OrdersMap/OrdersMap";

const OrdersMapView = ({ open }) => {
  const { isMobile } = useDeviceType();

  const [, { onDeselectOrder }, { isOrderSelected, isFirstLoading }] = useOrders();

  const isMapOpen = useMemo(() => {
    if (!isMobile) return true;
    return open;
  }, [isMobile, open]);

  if (isFirstLoading) return <Skeleton className="h-100" rounded={false} />;
  return (
    <OrdersMapViewDrawer
      open={isMapOpen}
      orderDetailsSection={<OrderDetails mapOpen={open} onClose={onDeselectOrder} />}
      orderControlsSection={<SaveOrderButton />}
    >
      <LoadGoogleMap loadingComponent={<Skeleton className="h-100" rounded={false} />}>
        <OrdersMap data-cy={isOrderSelected ? "order-route-map" : "orders-interactive-map"} />
      </LoadGoogleMap>
    </OrdersMapViewDrawer>
  );
};

OrdersMapView.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default OrdersMapView;
