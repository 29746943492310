import { bool, number, oneOfType, shape, string } from "prop-types";

const SelectOptionType = shape({
  className: string,
  label: oneOfType([string, number]),
  value: oneOfType([string, number]),
  disabled: oneOfType([bool, undefined]),
});

export default SelectOptionType;
