import { cx } from "@emotion/css";
import CircularProgress from "@mui/material/CircularProgress";
import { PropTypes } from "prop-types";
import styles from "./styles";

const Spinner = ({ className, color, size }) => (
  <CircularProgress className={cx(className, color === "white" && styles.white)} color={color} size={size} />
);

Spinner.defaultProps = {
  className: "",
  color: "primary",
  size: 20,
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default Spinner;
