import { PropTypes } from "prop-types";

const ProfilePageLayout = ({ pageHeaderSection, formSection, formSkeletonSection, children }) => (
  <div className="d-flex flex-1 h-fit flex-column align-items-stretch container py-5 px-4 px-md-5">
    {pageHeaderSection}

    {formSkeletonSection || formSection}

    {children}
  </div>
);

ProfilePageLayout.defaultProps = {
  formSection: null,
  formSkeletonSection: null,
};

ProfilePageLayout.propTypes = {
  pageHeaderSection: PropTypes.node.isRequired,
  formSection: PropTypes.node,
  formSkeletonSection: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default ProfilePageLayout;
