import { arrayOf, number, shape, string } from "prop-types";
import OrderStatusType from "./OrderStatus";
import ProofOfDeliveryType from "./ProofOfDelivery";

const PackageType = shape({
  parcelCustomerId: string,
  email: string,
  notes: string,
  type: string,
  quantity: number,
});

const DropOffType = shape({
  reference: string,
  geolocation: arrayOf(number),
  secondLineAddress: string,
  location: string,
  buildingOrFlatNumber: string,
  postcode: string,
  city: string,
  country: string,
  notes: string,
  eta: number,
  status: OrderStatusType,
  packages: arrayOf(PackageType),
  proof_of_delivery: ProofOfDeliveryType,
  recipient: shape({
    name: string,
    phone: string,
  }),
  price: shape({
    price: string,
    currency: string,
  }),
});

export default DropOffType;
