import { debounce } from "lodash";
import { useEffect, useMemo } from "react";

const useDebounce = (handler, wait = 500) => {
  const debouncedHandler = useMemo(() => debounce(handler, wait), [handler, wait]);

  useEffect(() => {
    return () => {
      debouncedHandler.cancel();
    };
  }, []);

  return debouncedHandler;
};

export default useDebounce;
