import { css } from "@emotion/css";

const styles = {
  drawer: (isMobile) =>
    css`
      height: calc(100% - ${isMobile ? "85px" : "120px"});

      margin-top: ${isMobile ? "85px" : "120px"} !important;
    `,
};

export default styles;
