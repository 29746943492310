import { cx } from "@emotion/css";
import Skeleton from "library/Atoms/Skeleton";
import styles from "./OrdersListSkeleton.styles";

const OrdersListSkeleton = () => (
  <>
    <div className="my-2">
      <Skeleton className={cx(styles.sectionTitle, "col-3 px-0")} />
    </div>

    <Skeleton className={cx(styles.orderItem, "my-3")} />
    <Skeleton className={cx(styles.orderItem, "my-3")} />
    <Skeleton className={cx(styles.orderItem, "my-3")} />
  </>
);

export default OrdersListSkeleton;
