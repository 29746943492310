import { useLoadScript } from "@react-google-maps/api";
import useAuthState from "hooks/useAuthState";
import { isUndefined } from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveLocalizationStatus } from "store/features/localization.store";
import { fetchLocalizationsRequest } from "store/requests/localization.request";
import { MAP_LIBRARIES } from "../../../constants";

const useLocalizationsLoading = () => {
  const dispatch = useDispatch();

  const { idle, loading, failed } = useSelector(retrieveLocalizationStatus);

  useEffect(() => {
    dispatch(fetchLocalizationsRequest());
  }, [dispatch]);

  return {
    isLocalizationsLoading: idle || loading,
    isLocalizationsLoadingFailed: failed,
  };
};

const useGoogleMapsLoading = () => {
  const { isLoaded: isGoogleLoaded, loadError: googleLoadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  return {
    isGoogleMapsLoading: !isGoogleLoaded && isUndefined(googleLoadError),
    isGoogleMapsLoadingFailed: !isUndefined(googleLoadError),
  };
};

const useUtilsMiddleware = () => {
  const { loading: isAuthStateLoading, failed: isAuthStateLoadingFailed } = useAuthState();
  const { isGoogleMapsLoading, isGoogleMapsLoadingFailed } = useGoogleMapsLoading();
  const { isLocalizationsLoading, isLocalizationsLoadingFailed } = useLocalizationsLoading();

  const isUtilsLoading = useMemo(() => {
    return [
      isAuthStateLoading, // check if firebase auth is loading
      isGoogleMapsLoading, // check if google maps api is loading
      isLocalizationsLoading, // check if localizations is loading
    ].some(Boolean);
  }, [isAuthStateLoading, isGoogleMapsLoading, isLocalizationsLoading]);

  const isUtilsLoadingFailed = useMemo(() => {
    return [
      isAuthStateLoadingFailed, // check if firebase auth loading failed
      isGoogleMapsLoadingFailed, // check if google maps api failed
      isLocalizationsLoadingFailed, // check if localizations loading failed
    ].some(Boolean);
  }, [isAuthStateLoadingFailed, isGoogleMapsLoadingFailed, isLocalizationsLoadingFailed]);

  return { isUtilsLoading, isUtilsLoadingFailed };
};

export default useUtilsMiddleware;
