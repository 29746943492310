import { createAsyncThunk } from "@reduxjs/toolkit";
import PartnerApi from "utility/api";

// saved addresses
export const fetchAddressesRequest = createAsyncThunk(
  "library/fetchAddressesRequest",
  async (_, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.library.addresses();
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const createNewAddressRequest = createAsyncThunk(
  "library/createNewAddressRequest",
  async (data, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.library.createAddress(data);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const deleteAddressRequest = createAsyncThunk(
  "library/deleteAddressRequest",
  async (data, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.library.removeAddress(data);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const editAddressRequest = createAsyncThunk("library/editAddressRequest", async (data, { rejectWithValue }) => {
  try {
    const result = await PartnerApi.library.updateAddress(data);
    return result;
  } catch (error) {
    throw rejectWithValue(error);
  }
});

// saved orders
export const fetchSavedOrdersRequest = createAsyncThunk(
  "library/fetchSavedOrdersRequest",
  async (_, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.library.orders();
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const saveOrderRequest = createAsyncThunk("library/saveOrderRequest", async (data, { rejectWithValue }) => {
  try {
    const result = await PartnerApi.library.saveOrder(data);
    return result;
  } catch (error) {
    throw rejectWithValue(error);
  }
});

export const deleteSavedOrderRequest = createAsyncThunk(
  "library/deleteSavedOrderRequest",
  async (data, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.library.removeOrder(data);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const editSavedOrderRequest = createAsyncThunk(
  "library/editSavedOrderRequest",
  async (data, { rejectWithValue }) => {
    try {
      const result = await PartnerApi.library.updateOrder(data);
      return result;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
