import { shape, string } from "prop-types";
import LocationDataType from "./LocationData";

const SavedAddressType = shape({
  id: string,
  label: string,
  notes: string,
  location: LocationDataType,
});

export default SavedAddressType;
