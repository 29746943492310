import { cx } from "@emotion/css";
import useDeviceType from "hooks/useDeviceType";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import { isEmpty, size } from "lodash";
import useInvoices from "pages/Invoices/hooks/useInvoices";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { format } from "utility/date-fns";
import { convertTimestampToDate } from "utility/helpers/general";
import InvoiceDetailsLayout from "../../layouts/InvoiceDetailsLayout/InvoiceDetailsLayout";
import InvoiceBriefInfo from "../InvoiceList/InvoiceItem/InvoiceBriefInfo/InvoiceBriefInfo";
import styles from "./InvoiceDetails.styles";
import InvoiceDownloadButton from "./InvoiceDownloadButton/InvoiceDownloadButton";
import InvoiceLine from "./InvoiceLine/InvoiceLine";
import InvoiceStatusBanner from "./InvoiceStatusBanner/InvoiceStatusBanner";

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const [{ selectedInvoice }, { onDeselectInvoice }] = useInvoices();

  const { id, created_at, updated_at, due_at, contact, lines, price } = selectedInvoice;

  if (isEmpty(selectedInvoice)) return null;
  return (
    <InvoiceDetailsLayout
      statusBarSection={<InvoiceStatusBanner />}
      briefInfoSection={
        <InvoiceBriefInfo id={id} createdAt={created_at} dueAt={due_at} lines={size(lines)} price={price} />
      }
      extraInfoSection={
        <>
          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.createdAt")}
            contentSection={format(convertTimestampToDate(created_at), "MMM dd, yyyy HH:mm")}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.updatedAt")}
            contentSection={format(convertTimestampToDate(updated_at), "MMM dd, yyyy HH:mm")}
          />

          <MiscDetailsLayout
            className="col-sm-4 col-6 my-3"
            titleSection={t("utils.timestamps.dueAt")}
            contentSection={format(convertTimestampToDate(due_at), "MMM dd, yyyy HH:mm")}
          />

          {contact.name && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection={t("invoices.invoice.customerName")}
              contentSection={contact.name}
            />
          )}

          {contact.email && (
            <MiscDetailsLayout className="col-sm-4 col-6 my-3" titleSection="Email" contentSection={contact.email} />
          )}

          {contact.invoiceEmail && (
            <MiscDetailsLayout
              className="col-sm-4 col-6 my-3"
              titleSection="Invoice email"
              contentSection={contact.invoiceEmail}
            />
          )}
        </>
      }
      linesSection={lines?.map((line, index) => (
        <InvoiceLine line={{ ...line, id: index + 1, currency: price.currency }} />
      ))}
      priceSection={
        <>
          <div className="text-right text-fs-14 text-truncate">
            <span>{t("utils.pricing.totalVat")}:</span>
            <NumberFormat
              className="px-2"
              value={price.tax}
              displayType="text"
              thousandSeparator
              prefix={price.currency}
            />
          </div>

          <div className="text-right text-fs-14 text-fw-semibold text-truncate">
            <span className="pr-2">{t("utils.pricing.totalPrice")}:</span>

            <Trans
              i18nKey="utils.pricing.includingVAT"
              components={{
                price: (
                  <NumberFormat value={price.total} displayType="text" thousandSeparator prefix={price.currency} />
                ),
              }}
            />
          </div>
        </>
      }
      controlsSection={
        <>
          {!isMobile && <InvoiceDownloadButton className={cx(styles.controlBtn(false), "w-100")} />}

          <PrimaryButton
            id="back-to-invoices-btn"
            className={cx(styles.controlBtn(isMobile), "w-100 mt-4")}
            contentClassName="text-truncate text-fs-12"
            label={t("invoices.invoice.backToInvoicesBtn")}
            onClick={onDeselectInvoice}
          />
        </>
      }
    />
  );
};

export default InvoiceDetails;
