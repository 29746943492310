import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import ModalLayout from "library/Layouts/ModalLayout";
import PropTypes from "prop-types";
import DefaultModal from "../DefaultModal";

const ConfirmationModal = ({
  id,
  title,
  description,
  onConfirm,
  onDecline,
  confirmBtnText,
  declineBtnText,
  isDeclineProcessing,
  isDataProcessing,
  ...restProps
}) => (
  <DefaultModal id={id} title={title} onClose={onDecline} {...restProps}>
    <ModalLayout
      contentSection={<>{description}</>}
      modalControls={
        <>
          <PrimaryButton
            id="modal-decline-btn"
            className="w-100"
            label={declineBtnText}
            onClick={onDecline}
            loading={isDeclineProcessing}
          />

          <div className="d-flex justify-content-center mt-4">
            <TextButton
              id="modal-confirm-btn"
              className="py-0"
              label={confirmBtnText}
              onClick={onConfirm}
              loading={isDataProcessing}
            />
          </div>
        </>
      }
    />
  </DefaultModal>
);

ConfirmationModal.defaultProps = {
  isDataProcessing: false,
  isDeclineProcessing: false,
};

ConfirmationModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  declineBtnText: PropTypes.string.isRequired,
  isDeclineProcessing: PropTypes.bool,
  isDataProcessing: PropTypes.bool,
};

export default ConfirmationModal;
