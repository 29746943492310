import { css } from "@emotion/css";

const styles = {
  drawer: (isMobile) =>
    css`
      height: calc(100% - ${isMobile ? "170px" : "240px"});
    `,
};

export default styles;
