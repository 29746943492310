import { cx } from "@emotion/css";
import { Step as MuiStep, Stepper as MuiStepper, StepLabel } from "@mui/material";
import { PropTypes } from "prop-types";
import StepType from "types/helperTypes/Stepper";

const Stepper = ({ activeStep, steps, StepIcon }) => (
  <MuiStepper className="col-12 px-0" alternativeLabel activeStep={activeStep}>
    {steps.map((step, index) => (
      <MuiStep key={step.label} index={index} className={cx(index === activeStep && "text-fw-semibold")}>
        <StepLabel
          StepIconComponent={(props) => <StepIcon {...props} {...step} />}
          componentsProps={{ label: { className: "d-flex justify-content-center mt-1" } }}
        >
          {step.label}
        </StepLabel>
      </MuiStep>
    ))}
  </MuiStepper>
);

Stepper.defaultProps = {
  activeStep: 0,
  steps: [],
  StepIcon: () => {},
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(StepType),
  StepIcon: PropTypes.func,
};

export default Stepper;
