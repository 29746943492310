import { css } from "@emotion/css";
import palette from "utility/ui/palette";

const styles = {
  today: css`
    border: 1px solid ${palette.primary.main} !important;
  `,
};

export default styles;
