import FastQuoteDetailsSkeleton from "features/SideMenu/skeletons/FastQuoteDetailsSkeleton";
import { useFormikContext } from "formik";
import useDeviceType from "hooks/useDeviceType";
import Tooltip from "library/Atoms/Tooltip";
import FlatBoxLayout from "library/Layouts/BoxLayout/FlatBoxLayout";
import MessageLayout from "pages/NewOrder/layouts/MessageLayout/MessageLayout";
import NewOrderDetailLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderDetailLayout/NewOrderDetailLayout";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { retrieveContactCity } from "store/features/contact.store";

const FastQuoteDetails = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const city = useSelector(retrieveContactCity);

  const {
    values: {
      price: { price, currency, distance = null },
    },
    isSubmitting,
  } = useFormikContext();

  return (
    <div className="d-flex flex-column col-md-5 col-12 mt-md-3 mb-md-0 mb-3 pl-md-3 pr-md-0 px-0">
      {isSubmitting ? (
        <FastQuoteDetailsSkeleton />
      ) : (
        <FlatBoxLayout className="flex-wrap w-100" color="grey100" data-cy="fast-quote-price-details">
          <NewOrderDetailLayout
            titleSection={t("newOrder.estimation.estimatedCosts")}
            contentSection={
              <>
                <Trans
                  i18nKey="utils.pricing.excludingVAT"
                  components={{
                    price: <NumberFormat value={price} displayType="text" thousandSeparator prefix={currency} />,
                  }}
                />

                {isMobile && <Tooltip color="primary">{t("newOrder.estimation.notFinalPriceMessage")}</Tooltip>}
              </>
            }
          />

          {distance !== null && (
            <>
              <NewOrderDetailLayout
                titleSection={t("newOrder.estimation.distance")}
                contentSection={t("newOrder.estimation.distanceValue", {
                  context: city,
                  distance: distance.toFixed(1),
                })}
              />
            </>
          )}
        </FlatBoxLayout>
      )}

      {!isMobile && (
        <MessageLayout
          className="w-100 mt-3 px-0"
          labelSection={<>{t("newOrder.estimation.notFinalPriceMessage")}</>}
        />
      )}
    </div>
  );
};

export default FastQuoteDetails;
