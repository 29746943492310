import { cx } from "@emotion/css";
import ScrollLayout from "library/Layouts/ScrollLayout";
import PropTypes from "prop-types";
import styles from "./InvoiceListLayout.styles";

const InvoiceListLayout = ({ filtersSection, skeletonSection, listSection, paginationSection, emptyListSection }) => (
  <div
    className={cx(
      styles.container,
      "flex-1 position-relative d-flex flex-column justify-content-between px-0 overflow-hidden"
    )}
  >
    {filtersSection}

    <ScrollLayout className="d-flex flex-1 flex-column">{skeletonSection || listSection}</ScrollLayout>

    {emptyListSection}

    {paginationSection}
  </div>
);

InvoiceListLayout.defaultProps = {
  skeletonSection: null,
  filtersSection: null,
  listSection: null,
  paginationSection: null,
  emptyListSection: null,
};

InvoiceListLayout.propTypes = {
  filtersSection: PropTypes.node,
  skeletonSection: PropTypes.node,
  listSection: PropTypes.node,
  paginationSection: PropTypes.node,
  emptyListSection: PropTypes.node,
};

export default InvoiceListLayout;
