import { Collapse } from "@mui/material";
import BoxLayout from "library/Layouts/BoxLayout/BoxLayout";
import { PropTypes } from "prop-types";

const DropDetailsLayout = ({
  dropReference,
  titleSection,
  toggleButtonSection,
  statusSection,
  riderSection,
  packageDetailsSection,
  proofOfDeliverySection,
  priceSection,
  modals,
  isRiderOutsideCollapse,
  isDetailsOpen,
}) => (
  <>
    <BoxLayout className="mb-4 py-4" data-cy={`drop-item-${dropReference}`}>
      <div className="d-flex flex-nowrap align-items-center justify-content-between mb-4 px-4">
        <div className="w-100 text-fs-14 text-fw-semibold text-truncate pr-3">{titleSection}</div>

        {toggleButtonSection}
      </div>

      <div className="px-4">{statusSection}</div>

      {isRiderOutsideCollapse && riderSection}

      {proofOfDeliverySection}

      {!isDetailsOpen && priceSection}

      <Collapse in={isDetailsOpen}>
        {!isRiderOutsideCollapse && riderSection}

        <div className="d-flex flex-wrap col-12 mt-4 px-0" data-cy={`drop-item-${dropReference}-details`}>
          {packageDetailsSection}
        </div>
      </Collapse>

      {isDetailsOpen && priceSection}
    </BoxLayout>

    {modals}
  </>
);

DropDetailsLayout.defaultProps = {
  statusSection: null,
  riderSection: null,
  proofOfDeliverySection: null,
  priceSection: null,
  modals: null,
  isRiderOutsideCollapse: false,
};

DropDetailsLayout.propTypes = {
  dropReference: PropTypes.string.isRequired,
  titleSection: PropTypes.node.isRequired,
  toggleButtonSection: PropTypes.node.isRequired,
  statusSection: PropTypes.node,
  riderSection: PropTypes.node,
  packageDetailsSection: PropTypes.node.isRequired,
  proofOfDeliverySection: PropTypes.node,
  priceSection: PropTypes.node,
  modals: PropTypes.node,
  isRiderOutsideCollapse: PropTypes.bool,
  isDetailsOpen: PropTypes.bool.isRequired,
};

export default DropDetailsLayout;
