import { arrayOf, number, shape, string } from "prop-types";
import OrderStatusType from "./OrderStatus";

const PickUpType = shape({
  geolocation: arrayOf(number),
  secondLineAddress: string,
  location: string,
  buildingOrFlatNumber: string,
  postcode: string,
  city: string,
  country: string,
  eta: number,
  status: OrderStatusType,
});

export default PickUpType;
