import { css } from "@emotion/css";

const styles = {
  orderItem: css`
    height: 80px;
  `,
  sectionTitle: css`
    height: 25px;
  `,
  paginationSection: css`
    height: 30px;
  `,
};

export default styles;
