import { format as dateFnsFormat, formatDistance as dateFnsFormatDistance } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { DEFAULT_LOCALE } from "../constants";

const dateFnsLocales = { enGB, de };

const format = (date, formatStr) => {
  return dateFnsFormat(date, formatStr, {
    locale: dateFnsLocales[localStorage.getItem("i18nextLng") || DEFAULT_LOCALE.replace("-", "")],
  });
};

const formatDistance = (dateFrom, dateTo) => {
  return dateFnsFormatDistance(dateFrom, dateTo, {
    locale: dateFnsLocales[localStorage.getItem("i18nextLng") || DEFAULT_LOCALE.replace("-", "")],
  });
};

export { format, formatDistance };
