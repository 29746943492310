import ConfirmationModal from "library/Molecules/Modal/ConfirmationModal";
import { PropTypes } from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveSelectedAddress,
  retrieveSelectedAddressesStatus,
  retrieveSelectedSavedOrder,
  retrieveSelectedSavedOrderStatus,
} from "store/features/library.store";
import { deleteAddressRequest, deleteSavedOrderRequest } from "store/requests/library.requests";

const LibraryConfirmDeleteModal = ({ onClose, isAddress }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: addressId, label: addressLabel } = useSelector(retrieveSelectedAddress);
  const { id: orderId, label: orderLabel } = useSelector(retrieveSelectedSavedOrder);

  const { loading: addressLoading } = useSelector(retrieveSelectedAddressesStatus);
  const { loading: orderLoading } = useSelector(retrieveSelectedSavedOrderStatus);

  const onDeleteAddress = useCallback(() => dispatch(deleteAddressRequest(addressId)), [addressId, dispatch]);
  const onDeleteOrder = useCallback(() => dispatch(deleteSavedOrderRequest(orderId)), [orderId, dispatch]);

  useEffect(() => {
    return () => onClose();
  }, [onClose]);

  return (
    <ConfirmationModal
      id={`confirm-${isAddress ? addressId : orderId}-deletion-modal`}
      title={t("library.modals.removeItemModal.title", { context: isAddress ? "address" : "order" })}
      description={t("library.modals.removeItemModal.description", { name: isAddress ? addressLabel : orderLabel })}
      onDecline={onClose}
      onConfirm={isAddress ? onDeleteAddress : onDeleteOrder}
      declineBtnText={t("common.buttons.cancelBtn")}
      confirmBtnText={t("common.buttons.removeBtn")}
      isDataProcessing={isAddress ? addressLoading : orderLoading}
    />
  );
};

LibraryConfirmDeleteModal.defaultProps = {
  isAddress: false,
};

LibraryConfirmDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isAddress: PropTypes.bool,
};

export default LibraryConfirmDeleteModal;
