import { createSlice } from "@reduxjs/toolkit";
import { groupBy, isEmpty, isEqual, sortBy } from "lodash";
import { format } from "utility/date-fns";
import { fetchFilteredOrdersRequest, fetchOrderPositionRequest, fetchOrdersRequest } from "../requests/order.requests";

const orderSliceInitialState = {
  filters: {},
  selectedOrder: null,
  orders: [],
  pagination: { total_pages: 1, active_page: 1 },
  status: "idle",
};

export const orderSlice = createSlice({
  name: "order",
  initialState: orderSliceInitialState,
  reducers: {
    setSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
    resetSelectedOrder: (state) => {
      state.selectedOrder = null;
    },
    refetchOrders: (state) => {
      state.status = "idle";
    },
    resetOrderFiltersAndPagination: (state) => {
      if (isEmpty(state.filters) && state.pagination.active_page === 1) return;
      state.status = "idle";
      state.filters = {};
      state.pagination.active_page = 1;
    },
  },
  extraReducers: {
    [fetchOrdersRequest.pending]: (state, { meta }) => {
      if (!meta.arg) state.status = "loading";
      else {
        state.status = "refetching";
        state.pagination.active_page = meta.arg;
      }
    },
    [fetchOrdersRequest.fulfilled]: (state, { payload: { data } }) => {
      state.status = "succeeded";
      state.orders = data.orders;
      state.pagination.total_pages = data.pagination.total_pages === 0 ? 1 : data.pagination.total_pages;
    },
    [fetchOrdersRequest.rejected]: (state) => {
      state.status = "failed";
    },
    [fetchFilteredOrdersRequest.pending]: (state, { meta }) => {
      if (isEmpty(meta.arg) || !isEqual(meta.arg, state.filters)) state.pagination.active_page = 1;
      state.filters = meta.arg;
      state.status = "refetching";
    },
    [fetchFilteredOrdersRequest.fulfilled]: (state, { payload: { data } }) => {
      state.status = "succeeded";
      state.orders = data.orders;
      state.pagination.total_pages = data.pagination.total_pages === 0 ? 1 : data.pagination.total_pages;
    },
    [fetchFilteredOrdersRequest.rejected]: (state) => {
      state.status = "failed";
    },
    [fetchOrderPositionRequest.fulfilled]: (state, { payload: { data }, meta }) => {
      if (!data) return;

      const { location: geolocation = null, pickupEta = null, dropsEta = {} } = data;

      state.orders = state.orders.reduce((prev, curr) => {
        if (curr.id !== meta.arg) return [...prev, curr];
        return [
          ...prev,
          {
            ...curr,
            rider: { ...curr.rider, ...(geolocation && { geolocation }) },
            pick_up: { ...curr.pick_up, ...(pickupEta && { eta: pickupEta }) },
            drops: curr.drops.map((drop) => ({
              ...drop,
              ...(dropsEta?.[drop.reference] && { eta: dropsEta[drop.reference] }),
            })),
          },
        ];
      }, []);
    },
  },
});

export const { setSelectedOrder, resetSelectedOrder, refetchOrders, resetOrderFiltersAndPagination } =
  orderSlice.actions;

export const retrieveGroppedOrders = (state) => {
  return groupBy(
    sortBy(state.order.orders, ({ startDate }) => format(new Date(startDate), "yyyy-MM-dd")).reverse(),
    ({ startDate }) => format(new Date(startDate), "yyyy-MM-dd")
  );
};
export const retrieveOrders = (state) => state.order.orders;

export const retrieveSelectedOrder = (state) => {
  return state.order?.orders?.find((order) => order.reference === state.order?.selectedOrder) || {};
};

export const retrieveOrdersPagination = (state) => state.order.pagination;
export const retrieveOrderFilters = (state) => state.order.filters;

export const retrieveOrdersStatus = (state) => {
  const { status } = state.order;
  return { idle: status === "idle", loading: status === "loading", refetching: status === "refetching" };
};

export default orderSlice.reducer;
