import { useCallback, useState } from "react";
import useOpenClose from "./useOpenClose";

const useToggleMenu = (initValue) => {
  const [isOpen, open, close] = useOpenClose(initValue);
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = useCallback(
    ({ currentTarget }) => {
      setAnchorEl(currentTarget);
      open();
    },
    [open]
  );
  const onClose = useCallback(() => {
    close();
    setAnchorEl(null);
  }, [close]);

  return [{ anchorEl, isOpen }, onOpen, onClose];
};

export default useToggleMenu;
