import { TextField } from "@mui/material";
import { PropTypes } from "prop-types";
import { useCallback, useEffect, useState } from "react";

const DefaultInput = ({ inputRef, className, name, value, onChange, ...restProps }) => {
  const [currValue, setCurrValue] = useState(value);

  const onChangeInputValue = useCallback(
    (e) => {
      setCurrValue(e.target.value);
      onChange(name, e.target.value);
    },
    [name, onChange]
  );

  useEffect(() => {
    setCurrValue(value);
  }, [value]);

  return (
    <TextField
      ref={inputRef}
      className={className}
      name={name}
      inputProps={{ className: "text-truncate cursor-pointer" }}
      value={currValue}
      onChange={onChangeInputValue}
      {...restProps}
    />
  );
};

DefaultInput.defaultProps = {
  inputRef: null,
  className: "",
  name: "",
  value: undefined,
  onChange: () => {},
};

DefaultInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DefaultInput;
