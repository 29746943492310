import { useMediaQuery, useTheme } from "@mui/material";

const useDeviceType = () => {
  const { breakpoints } = useTheme();

  return {
    isSmallMobile: useMediaQuery(breakpoints.down("sm"), { noSsr: true }),
    isMobile: useMediaQuery(breakpoints.down("md"), { noSsr: true }),
    isTablet: useMediaQuery(breakpoints.between("md", "lg"), { noSsr: true }),
    isDesktop: useMediaQuery(breakpoints.up("lg"), { noSsr: true }),
  };
};

export default useDeviceType;
